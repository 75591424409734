import React from 'react'
import { Link } from 'react-router-dom'
import { User, useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { Client } from '@ospace/schemas'
import { ConfirmationModal, KTSVG, Table, Toggle, UserStatus } from '@ospace/shared'
import { RegionTag } from '@ospace/shared/components/RegionTag'
import { ColumnDef } from '@tanstack/react-table'

import { useChangeUserStatus } from '../hooks/useChangeUserStatus'
import { useUser } from '../hooks/useUser'

import { DeleteButton } from './DeleteButton'
import { ReinviteButton } from './ReinviteButton'

const ChangeStatusButton = ({ user }: { user: User }) => {
  const { data: currentUser } = useUser()
  const mutation = useChangeUserStatus()
  const label = user.enabled ? 'Disable' : 'Enable'
  const isCurrentUser = user.id === currentUser?.id
  if (isCurrentUser) {
    return null
  }
  return (
    <ConfirmationModal
      alertMessage={
        <div>
          <p className='fs-4'>
            Are you sure you want to <b>{label.toLocaleLowerCase()}</b> the access to the platform
            for{' '}
            <b>
              {user.firstName} {user.lastName}
            </b>
            ?
          </p>
        </div>
      }
      isLoading={mutation.isLoading}
      action={() =>
        mutation.mutateAsync({ username: user.identityId || '', status: !user.enabled })
      }
      renderButton={(props) => <Toggle {...props} checked={user.enabled} onChange={() => {}} />}
    />
  )
}

const UsersTable: React.FC<any> = ({ users }: { users: User[] }) => {
  const usersForTable = users
    .sort((a: any, b: any) => {
      // sort by firstName
      if (a.firstName < b.firstName) return -1
      if (a.firstName > b.firstName) return 1
      // names must be equal
      return 0
    })
    .sort((a: any) => {
      if (a.deletedAt) return 1
      return 0
    })
    .map((user: User) => {
      const clients = new Map()
      user?.client && clients.set(user.client.id, user?.client)
      user?.clients.map((client: Client) => clients.set(client.id, client))
      return {
        ...user,
        clients: Array.from(clients.values()),
        //Insert a space before capital letters
        type: user.type?.replace(/[A-Z]/g, ' $&')?.trim(),
      } as User
    })

  const role = useUserRole()

  const styleDeletedUser = (info: { row: { original: { deletedAt: string | null } } }) => ({
    textDecoration: info.row.original.deletedAt ? 'line-through' : 'none',
    color: info.row.original.deletedAt ? 'gray' : 'none',
  })
  let columns: ColumnDef<User>[] = [
    {
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      id: 'name',
      header: 'Name',
      cell: (info) => {
        return <div style={styleDeletedUser(info)}>{info.getValue()}</div>
      },
      size: 100,
    },
    {
      accessorKey: 'email',
      header: 'Username',
      cell: (info) => {
        return <div style={styleDeletedUser(info)}>{info.getValue()}</div>
      },
    },
    {
      id: 'clients',
      header: 'Clients',
      accessorFn: (row) => row.clients.map((client: Client) => client.name).join(','),
      cell: (info: any) => {
        const clients = info.row.original.clients
        return (
          <div
            className='d-flex'
            style={{
              flexDirection: 'column',
              ...styleDeletedUser(info),
            }}
          >
            {clients.map((client: Client, index: number) => (
              <Link
                key={client.id}
                to={`/client/view/${client?.id}`}
                className={
                  info.row.original.deletedAt
                    ? 'text-muted text-decoration-none fw-bolder d-block mb-1 fs-6 me-1'
                    : 'text-black text-hover-primary fw-bolder d-block mb-1 fs-6 me-1'
                }
              >
                {client?.name}
                {index === clients.length - 1 ? `` : `,`}
              </Link>
            ))}
          </div>
        )
      },
      size: 160,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: (info) => {
        return <div style={styleDeletedUser(info)}>{info.getValue()}</div>
      },
      size: 70,
    },
    {
      accessorKey: 'userStatus',
      header: 'Status',
      size: 70,
      cell: (info) => {
        return (
          <span
            style={styleDeletedUser(info)}
            className={
              info.getValue() === UserStatus.FORCE_CHANGE_PASSWORD
                ? 'badge badge-light-warning'
                : 'badge badge-light-success'
            }
          >
            {info.getValue()}
          </span>
        )
      },
    },

    {
      accessorFn: (row) => row,
      header: 'Enabled',
      cell: (info) => <ChangeStatusButton user={info.getValue() as User} />,
      size: 70,
    },
    {
      accessorKey: 'region',
      header: 'Region',
      cell: (info) => {
        return (
          <div style={styleDeletedUser(info)}>
            <RegionTag regionCode={info.getValue() as string} />
          </div>
        )
      },
      size: 50,
    },
    {
      accessorFn: (row) => row,
      id: 'actions',
      header: 'Actions',
      cell: (info) => {
        const user: User = info.getValue() as User
        return (
          role === userTypes.Admin &&
          !user?.deletedAt && (
            <div className=''>
              <Link
                to={`/user/edit/${user.identityId}`}
                className='btn btn-secondary btn-sm px-4 me-2'
              >
                Edit
              </Link>
              {user.userStatus === UserStatus.FORCE_CHANGE_PASSWORD && (
                <ReinviteButton user={user} />
              )}
            </div>
          )
        )
      },
      size: 60,
    },
  ]
  if (role === userTypes.Admin || role === userTypes.ClientManager) {
    columns = [
      ...columns,
      {
        accessorFn: (row) => row,
        id: 'delete',
        header: 'Delete',
        cell: (info) => {
          const user: User = info.getValue() as User
          return DeleteButton({ user })
        },
      },
    ]
  }
  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>All Users</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <div className='card-toolbar'>
              <Link to='/user/create' className='btn btn-sm btn-light-primary'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                New User
              </Link>
            </div>
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <Table columns={columns} data={usersForTable} />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export { UsersTable }
