import { useQuery } from 'react-query'
import { useAuthContext, UserService } from '@ospace/auth'

export const useUser = () => {
  const { session }: { session: any } = useAuthContext()
  const identityId = session?.idToken?.payload['cognito:username']

  return useQuery(['user', identityId], () => UserService.getUser(identityId), {
    enabled: !!identityId,
  })
}
