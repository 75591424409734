import React, { useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  FileInput,
  Grid,
  Group,
  Image,
  Loader,
  MultiSelect,
  Select,
  Switch,
  TextInput,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useUserRole } from '@ospace/auth'
import { useClientsCampaigns } from '@ospace/campaign'
import { useClients } from '@ospace/client'
import { userTypes } from '@ospace/core-auth'
import { Campaign } from '@ospace/schemas'
import { isInternalUser, userTypeOptions as externalUserTypeOptions } from '@ospace/shared'
import { RegionSelect } from '@ospace/shared/components/RegionSelect'

import { useUser } from '../hooks/useUser'

type UserFormProps = {
  userId?: number // if not set, it is a create form
  isSubmitting: boolean
  initialValues: any
  onSubmit: any
  validationSchema?: any
}

export const UserForm: React.FC<any> = (props: UserFormProps) => {
  const { onSubmit, initialValues, validationSchema, isSubmitting, userId } = props
  // fetch all clients
  const clients = useClients()
  const { data: currentUser } = useUser()
  const role = useUserRole()
  const [selectedClients, setSelectedClients] = useState<any>(initialValues?.clients)
  const [userTypeOptions, setUserTypeOptions] = useState(externalUserTypeOptions)
  const isEditPage = !!userId
  const campaignResults: UseQueryResult[] = useClientsCampaigns(selectedClients)

  const isCampaignsLoading = !campaignResults.every(
    (campaign: UseQueryResult) => campaign.isSuccess
  )

  let campaigns: Campaign[] = []
  if (!isCampaignsLoading) {
    campaigns = campaignResults.reduce((campaigns: Campaign[], campaign: any) => {
      return [...campaigns, ...campaign?.data]
    }, [])
  }

  const campaignOptions = campaigns
    ?.sort((a: Campaign, b: Campaign) => a.name.localeCompare(b.name))
    .map((d: any) => {
      return {
        value: d.id,
        label: d.name,
      }
    })

  const clientOptions =
    clients?.data?.clients
      ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((d: any) => {
        return {
          value: d.id,
          label: d.name,
        }
      }) || []

  const form = useForm({
    initialValues,
    validate: yupResolver(validationSchema),
  })

  const [preview, setPreview] = useState<any>()
  const handleFileChange = (files: any) => {
    form.setFieldValue('avatarFile', files)

    // Display image preview
    if (files) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
      }
      reader.readAsDataURL(files)
    } else {
      setPreview(null)
    }
  }

  useEffect(() => {
    setSelectedClients(form.values?.clients)
  }, [form.values?.clients])

  useEffect(() => {
    if (role === userTypes.Admin && currentUser?.canCreateInternalViewer === true) {
      setUserTypeOptions([
        ...externalUserTypeOptions,
        { value: 'InternalViewer', label: 'Internal Viewer' },
      ])
    }
  }, [role, currentUser])

  // enable/disable userType field based on the user's role and permissions
  let isUserTypeDisabled = false
  if (isEditPage && initialValues?.userType) {
    if (role === userTypes.Admin && currentUser?.canCreateInternalViewer === true) {
      if (!isInternalUser(initialValues?.userType) && initialValues.userType === 'InternalViewer') {
        isUserTypeDisabled = false // Enable the field if the user is an admin and can create internal viewers
      } else if (isInternalUser(initialValues?.userType)) {
        isUserTypeDisabled = false // Disable the field if the user is an admin but can't create internal viewers
      } else {
        isUserTypeDisabled = true // Disable the field if the user is an admin but can't create internal viewers
      }
    } else if (role === userTypes.Admin) {
      isUserTypeDisabled = false // Enable the field if the user is an admin
    } else {
      isUserTypeDisabled = true // Disable the field if the user doesn't have permission
    }
  } else {
    isUserTypeDisabled = false // Default state when not on the edit page or userType is undefined
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid>
        <Col span={6} p={'xl'}>
          {isEditPage ? (
            <></>
          ) : (
            <TextInput
              withAsterisk
              label='Email'
              placeholder='Specify your email'
              {...form.getInputProps('email')}
              mb='md'
              size='md'
            />
          )}

          <Select
            mb='md'
            size='md'
            name='userType'
            withinPortal
            {...form.getInputProps('userType')}
            data={userTypeOptions}
            placeholder='User type'
            label='User type'
            onChange={(e) => {
              form.setFieldValue('userType', e)
            }}
            clearable
            withAsterisk
            disabled={isUserTypeDisabled}
          />
          <RegionSelect
            inputProps={form.getInputProps('region')}
            onChange={(e) => {
              form.setFieldValue('region', e)
            }}
          />

          {isUserTypeDisabled ? (
            <Alert variant='light' color='red'>
              Only external user types are allowed to be changed.
            </Alert>
          ) : (
            <></>
          )}

          {clients.isFetching ? (
            <Loader variant={'dots'} />
          ) : form.getInputProps('userType').value !== 'InternalViewer' ? (
            <MultiSelect
              mb='md'
              size='md'
              name='clients'
              withinPortal
              {...form.getInputProps('clients')}
              data={clientOptions}
              placeholder='Clients'
              label='List of the clients'
              onChange={(e) => {
                form.setFieldValue('clients', e)
              }}
              multiple
              searchable
            />
          ) : (
            <></>
          )}

          {form.getInputProps('userType').value === 'CampaignUser' &&
            (isCampaignsLoading ? (
              <Loader variant={'dots'}></Loader>
            ) : (
              <MultiSelect
                mb='md'
                size='md'
                name='campaigns'
                withinPortal
                {...form.getInputProps('campaigns')}
                data={campaignOptions}
                placeholder='Campaigns'
                label='List of the campaigns'
                onChange={(e) => {
                  form.setFieldValue('campaigns', e)
                }}
                multiple
                searchable
              />
            ))}

          {isEditPage ? (
            <FileInput
              placeholder='Click to select avatar'
              onChange={handleFileChange}
              label='Drop your files here or click to select avatar'
              mb='md'
              size='md'
            />
          ) : (
            <></>
          )}
          {preview && <Image src={preview} width={200} alt='Preview' mt={'md'} />}
          {form.values.signedAvatarUrl && !preview ? (
            <Image src={form.values.signedAvatarUrl} width={200} alt='Preview' mt={'md'} />
          ) : null}
        </Col>
        <Col span={6} p={'xl'}>
          <TextInput
            withAsterisk
            label='First Name'
            placeholder='Specify your first name'
            {...form.getInputProps('firstName')}
            mb='md'
            size='md'
          />
          <TextInput
            withAsterisk
            label='Last Name'
            placeholder='Specify your last name'
            {...form.getInputProps('lastName')}
            mb='md'
            size='md'
          />
          <TextInput
            label='Job Title'
            placeholder='Specify your job title'
            {...form.getInputProps('jobTitle')}
            mb='md'
            size='md'
          />

          <TextInput
            label='Contact Number'
            placeholder='Specify your contact number'
            {...form.getInputProps('contactNumber')}
            mb='md'
            size='md'
          />

          {isEditPage ? (
            <Switch
              checked={form.values.enableProgramNotifications}
              onChange={() =>
                form.setFieldValue(
                  'enableProgramNotifications',
                  !form.values.enableProgramNotifications
                )
              }
              label='Enable program updates'
              mt={'md'}
              size='md'
            />
          ) : (
            <></>
          )}

          {isEditPage ? (
            <Switch
              checked={form.values.enableDashboardNotifications}
              onChange={() =>
                form.setFieldValue(
                  'enableDashboardNotifications',
                  !form.values.enableDashboardNotifications
                )
              }
              label='Enable Lead/Pipeline/Sales updates'
              mt={'md'}
              size='md'
            />
          ) : (
            <></>
          )}
        </Col>
      </Grid>
      <Group position='right' m='md'>
        <Button type='submit' loading={isSubmitting} size='md'>
          Submit
        </Button>
        <Button component={Link} to={'/user'} size='md' color='gray'>
          Cancel
        </Button>
      </Group>
    </form>
  )
}
