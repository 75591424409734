import { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useCheckRole } from '@ospace/auth'
import { useCampaign } from '@ospace/campaign/hooks'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { ErrorBoundary, useNotificationContext } from '@ospace/shared'
import { Formik } from 'formik'

import { ContactUploadForm } from '../components/ContactForm'
import { inits, uploadContactSchema } from '../components/UploadContactHelper'
import { ContactService } from '../services'

type Props = {
  campaignId: number
}
export const ContactUploadPage = (props: Props) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()
  const payload = useRef({})
  const navigate = useNavigate()
  const { data: campaign } = useCampaign(props.campaignId)
  const mutation = useMutation(
    (contact: any) => {
      contact.campaignId = props.campaignId
      contact.lookerClientId = campaign?.lookerClientId
      return ContactService.validateContact(contact)
    },
    {
      onSuccess: (data) => {
        setNotification({
          active: true,
          message: 'Please review your contacts before uploading.',
          type: 'success',
        })
        navigate(`/campaign/${props.campaignId}/contact/review`, {
          state: {
            data,
            payload: payload.current,
          },
        })
        queryClient.invalidateQueries(['contacts', props.campaignId])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  const handleSubmit = async (values: any, actions: any) => {
    payload.current = values
    await mutation.mutateAsync(values as any)
    actions.resetForm()
  }

  return (
    <CampaignPage>
      <PageTitle>Upload Contacts</PageTitle>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Upload Contacts CSV</span>
          </h3>
        </div>
        <Formik
          initialValues={inits}
          validationSchema={uploadContactSchema}
          onSubmit={handleSubmit}
        >
          {(formProps) => (
            <ContactUploadForm
              form={formProps}
              mutation={mutation}
              cancelLink={`/campaign/view/${props.campaignId}`}
            />
          )}
        </Formik>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  const params: any = useParams()
  return <ContactUploadPage campaignId={params?.id} />
}

export const ContactUploadPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
