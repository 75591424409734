import { Button } from 'react-bootstrap'

// SpinnerButton can be used to render a button that has a spinner while the operation is proceeding
type SpinnerButtonProps = {
  children: string
  onClick: () => void
  loading: boolean
  variant: string
}
export const SpinnerButton = (props: SpinnerButtonProps) => (
  <Button onClick={props.onClick} size='sm' variant={props.variant}>
    {props.loading && <span className='spinner-border spinner-border-sm align-middle mx-2' />}
    {props.children}
  </Button>
)
