import { useQuery } from 'react-query'

import { ContactService } from '../services'

export interface ContactUpload {
  id: number
  fileName: string
}

export const useContactUpload = (importId: number) => {
  return useQuery<ContactUpload>(['contacts-upload', importId], async () => {
    // wait for the upload to be processed
    await new Promise((resolve) => setTimeout(resolve, 10 * 1000))
    return ContactService.getContactUpload(importId)
  })
}
