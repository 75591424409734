import { abbreviateNumber, salesData } from '@ospace/common'
import { SalesInvoice } from '@ospace/schemas'
import { TextLabel } from '@ospace/shared'
import { CurrencyText } from '@ospace/shared'

interface Props {
  sales: SalesInvoice[]
  currency: string
}

export const SalesWidgetsHelper = ({ sales, currency }: Props) => {
  const {
    totalSalesInvoicesCount,
    totalSalesInvoicesValue,
    averageSalesInvoicesValue,
    totalPartnersCount,
  } = salesData({ sales })

  return [
    {
      title: 'TOTAL SALES',
      tooltip: 'The sum of all sales transactions for the selected time period',
      element: (
        <CurrencyText value={abbreviateNumber(totalSalesInvoicesValue)} currency={currency} />
      ),
      text: abbreviateNumber(totalSalesInvoicesValue),
    },
    {
      title: 'TOTAL SALES Quantity',
      tooltip:
        'The total number of sales transactions for the selected time period, excluding credit notes',
      text: totalSalesInvoicesCount,
      element: <TextLabel title='' value={totalSalesInvoicesCount.toString()} />,
    },
    {
      title: 'AVERAGE SALES VALUE',
      tooltip: 'Total Sales divided by Total Sales Quantity',
      text: abbreviateNumber(averageSalesInvoicesValue),
      element: (
        <CurrencyText value={abbreviateNumber(averageSalesInvoicesValue)} currency={currency} />
      ),
    },
    {
      title: 'TOTAL PARTNERS',
      tooltip: 'Total partners count',
      text: totalPartnersCount,
      element: <TextLabel title='' value={totalPartnersCount.toString()} />,
    },
  ]
}
