import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

export const initDataDog = () => {
  if (!process.env.REACT_APP_DATA_DOG_APP_ID) {
    console.error('REACT_APP_DATA_DOG_APP_ID not found')
    return
  }
  if (!process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN) {
    console.error('REACT_APP_DATA_DOG_CLIENT_TOKEN not found')
    return
  }

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
    site: 'ap1.datadoghq.com',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  })

  datadogRum.init({
    // get it from env
    applicationId: process.env.REACT_APP_DATA_DOG_APP_ID,
    clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
    site: 'ap1.datadoghq.com',
    service: 'ospace',
    env: process.env.REACT_APP_STAGE || 'local',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
