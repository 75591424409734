import { Center, RingProgress, Text } from '@mantine/core'
import { RingProgressProps } from '@mantine/core/lib/RingProgress/RingProgress'

interface Props extends Omit<RingProgressProps, 'sections'> {
  value: number
  percentage: number
  color: string
}
export const CircularChart =
  ({ value, percentage, color, ...rest }: Props) =>
  () => {
    return (
      <Center mt={10}>
        <RingProgress
          size={100}
          roundCaps
          thickness={8}
          sections={[{ value, color }]}
          label={
            <Center>
              <Text fw={700} fz='xl' c='gray.7'>
                {percentage}%
              </Text>
            </Center>
          }
          {...rest}
        />
      </Center>
    )
  }
