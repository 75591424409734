import { useClient } from '@ospace/client'
import { Client } from '@ospace/schemas'
import { useUser } from '@ospace/user'

import { usePersistedDuration } from './useDuration'

export const useDashboard = (clientId: number) => {
  const clientResp = useClient(clientId)
  const userResp = useUser()
  const durationResp = usePersistedDuration({
    FYEndMonth: clientResp.status === 'success' ? clientResp.data.FYEndMonth : undefined,
  })

  if (
    clientResp.status === 'success' &&
    userResp.status === 'success' &&
    durationResp.status === 'ready'
  ) {
    return {
      status: 'ready',
      client: clientResp.data as Client,
      duration: durationResp.duration,
      setDuration: durationResp.setDuration,
      user: userResp.data,
    }
  }
  return {
    status: 'loading',
  }
}
