import { SimpleGrid } from '@mantine/core'
import { Duration } from '@ospace/common'
import { Campaign, Lead } from '@ospace/schemas'

import { LeadsWidget } from './LeadWidget'
import { plotConvertedLeads } from './plotConvertedLeads'
import { plotDiscovery } from './plotDiscovery'
import { plotLeads } from './plotLeads'
import { plotMeetingBooked } from './plotMeetingBooked'

interface LeadsWidgetsProps {
  leads: Lead[]
  currency: string
  campaigns: Campaign[]
  duration: Duration
}

export function LeadsWidgets({ leads, duration }: LeadsWidgetsProps) {
  const discoveryChart = plotDiscovery(leads, duration)
  const leadsChart = plotLeads(leads, discoveryChart.right.value, duration)
  const meetingBookedChart = plotMeetingBooked(leads, leadsChart.left.value, duration)
  const convertedLeadChart = plotConvertedLeads({
    leads,
    leadsCount: leadsChart.left.value,
    duration,
  })

  const cards = [discoveryChart, leadsChart, meetingBookedChart, convertedLeadChart].map((item) => (
    <LeadsWidget {...item} key={item.title} />
  ))

  return (
    <>
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {cards}
      </SimpleGrid>
    </>
  )
}
