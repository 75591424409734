import * as Yup from 'yup'

import { clientSchema } from './client'

export type Role =
  | 'Admin'
  | 'ClientManager'
  | 'CampaignManager'
  | 'ClientViewer'
  | 'ClientUser'
  | 'CampaignUser'
  | 'InternalViewer'

export const roleSchema = Yup.string<Role>()
  .oneOf([
    'Admin',
    'ClientManager',
    'CampaignManager',
    'ClientViewer',
    'ClientUser',
    'CampaignUser',
    'InternalViewer',
  ])
  .required()

export const userNavigationCategorySchema = Yup.string()
  .oneOf(['internal', 'campaign', 'external-multilpe-clients', 'external-single-client'])
  .required()
export type UserNavigationCategory = Yup.InferType<typeof userNavigationCategorySchema>

export const userSchema = Yup.object().shape({
  id: Yup.number().positive().required(),
  identityId: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  role: roleSchema.required(),
  navigationCategory: userNavigationCategorySchema.required(),
  clients: Yup.array().of(clientSchema).required(),
  region: Yup.string().required(),
})

const createUserSchema = Yup.object({
  userType: Yup.string().required().nullable().label('User type'),
  clients: Yup.array().when('userType', ([userType], schema) => {
    return userType === 'ClientUser' || userType === 'CampaignUser' || userType === 'ClientViewer'
      ? schema.min(1).required('required').label('Client')
      : schema
  }),
  campaigns: Yup.array().when('userType', ([userType], schema) => {
    return userType === 'CampaignUser'
      ? schema.min(1).required('required').label('Campaign')
      : schema
  }),
  email: Yup.string().required().label('Email'),
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  jobTitle: Yup.string().nullable(),
  region: Yup.string().required(),
})

const editUserSchema = Yup.object({
  userType: Yup.string().required().nullable().label('User type'),
  clients: Yup.array().when('userType', ([userType], schema) => {
    return userType === 'ClientUser' || userType === 'CampaignUser' || userType === 'ClientViewer'
      ? schema.min(1).required('required').label('Client')
      : schema
  }),
  campaigns: Yup.array().when('userType', ([userType], schema) => {
    return userType === 'CampaignUser'
      ? schema.min(1).required('required').label('Campaign')
      : schema
  }),
  email: Yup.string().required().label('Email'),
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  identityId: Yup.string(),
  oldUserType: Yup.string(),
  jobTitle: Yup.string().nullable(),
  avatar: Yup.string().nullable(),
  contactNumber: Yup.string().nullable(),
  signedAvatarUrl: Yup.string().nullable(),
  enableProgramNotifications: Yup.boolean().required(),
  enableDashboardNotifications: Yup.boolean().required(),
  region: Yup.string().required(),
})

const editUserProfileSchema = Yup.object({
  clients: Yup.array().when('userType', ([userType], schema) => {
    return userType === 'ClientUser' || userType === 'CampaignUser' || userType === 'ClientViewer'
      ? schema.min(1).required('required').label('Client')
      : schema
  }),
  campaigns: Yup.array().when('userType', ([userType], schema) => {
    return userType === 'CampaignUser'
      ? schema.min(1).required('required').label('Campaign')
      : schema
  }),
  email: Yup.string().required().label('Email'),
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  identityId: Yup.string(),
  jobTitle: Yup.string().nullable(),
  avatar: Yup.string().nullable(),
  contactNumber: Yup.string().nullable(),
  signedAvatarUrl: Yup.string().nullable(),
  enableProgramNotifications: Yup.boolean().required(),
  enableDashboardNotifications: Yup.boolean().required(),
  deletedAt: Yup.string().nullable(),
})

type EditUserRequest = Yup.InferType<typeof editUserSchema>
type CreateUserRequest = Yup.InferType<typeof editUserSchema>
type EditUserProfileRequest = Yup.InferType<typeof editUserProfileSchema>

export { createUserSchema, editUserProfileSchema, editUserSchema }
export type { CreateUserRequest, EditUserProfileRequest, EditUserRequest }
