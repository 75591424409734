import { Campaign, CreateCampaignRequest } from '@ospace/schemas'
import { API } from '@ospace/shared'

const getCampaigns = async () => {
  const campaigns = await API.get('client', `/campaigns`, {})
  return campaigns as Campaign[]
}

const getCampaign = async (id: number) => {
  const campaign = await API.get('client', `/campaigns/${id}`, {})
  return campaign as Campaign
}

const createCampaigns = async (campaign: CreateCampaignRequest) => {
  return API.post('client', `/campaigns`, {
    body: campaign,
  })
}

const editCampaign = async (campaign: CreateCampaignRequest, id: number) => {
  return API.put('client', `/campaigns/${id}`, {
    body: campaign,
  })
}

const deleteCampaign = async (id: number) => {
  return API.del('client', `/campaigns/${id}`, {})
}

const findCampaignsByClient = async (clientId: number): Promise<Campaign[]> => {
  const campaigns = await API.get('client', `/clients/${clientId}/campaigns`, {})
  return campaigns as Campaign[]
}

const getDashboards = async (campaignId: number) => {
  return await API.get('client', `/dashboards/campaigns/${campaignId}`, {})
}

const getDealStatus = async (campaignId: number) => {
  return await API.get('client', `/deal-status/campaigns/${campaignId}`, {})
}

const getDeals = async (campaignId: number) => {
  return await API.get('client', `/deals/campaigns/${campaignId}`, {})
}

export const CampaignService = {
  getCampaigns,
  getCampaign,
  createCampaigns,
  editCampaign,
  deleteCampaign,
  findCampaignsByClient,
  getDashboards,
  getDealStatus,
  getDeals,
}
