import { CreateObjectiveResult } from '@ospace/schemas/objective'
import { FormInit } from '@ospace/shared'
import dayjs from 'dayjs'

const inits: FormInit<CreateObjectiveResult> = {
  startDate: dayjs().startOf('week').format('YYYY-MM-DD'),
  endDate: dayjs().startOf('week').add(7, 'day').format('YYYY-MM-DD'),
  intents: 0,
  companiesDiscovered: 0,
  contactsDiscovered: 0,
  totalCompaniesDiscovered: '',
  totalIntents: '',
  totalContactsDiscovered: '',
  telesaleHours: 0,
  meetingBooked: 0,
  targetPipelineValue: 0,
  dealRegDone: 0,
  digitalProspectingMeetingBooked: 0,
  totalTelesaleHours: '',
  totalMeetingBooked: '',
  totalTargetPipelineValue: '',
  totalDealRegDone: '',
  digitalLeads: 0,
  connectionRequestsSent: 0,
  connectionRequestsAccepted: 0,
  impressions: 0,
  campaignClicks: 0,
  totalDigitalLeads: '',
  totalConnectionRequestsSent: '',
  totalDigitalProspectingMeetingBooked: '',
  totalConnectionRequestsAccepted: '',
  totalImpressions: '',
  totalCampaignClicks: '',
}

export { inits }
