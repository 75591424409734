import { FC, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import { LocalStorage } from '@ospace/shared'
import * as Auth from 'aws-amplify/auth'

import { useAuthContext } from '../contexts'
import { AuthActions } from '../reducers'

export const Logout: FC = () => {
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    async function handleLogout() {
      const useAttributes = await Auth.fetchUserAttributes()
      datadogLogs.logger.info('User logout', {
        userEmail: useAttributes.email,
        userAgent: window.navigator.userAgent,
      })
      await Auth.signOut()
      // save the user email for datadog.
      const lastUserEmail = localStorage.getItem('lastUserEmail')
      LocalStorage.delete('session')
      localStorage.clear()
      if (lastUserEmail) {
        localStorage.setItem('lastUserEmail', lastUserEmail)
      }
      dispatch({
        type: AuthActions.RESET_USER_DATA,
      })
      navigate('/auth/login')
    }
    handleLogout()
  }, [dispatch, navigate])

  return <Navigate to='/auth/login' replace />
}
