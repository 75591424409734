import * as Yup from 'yup'

const baseObjectiveResultSchema = {
  id: Yup.number().required(),
  startDate: Yup.string().required().label('Start date'),
  endDate: Yup.string().nullable().label('End date'),
  intents: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Intents'),
  companiesDiscovered: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Companies discovered'),
  contactsDiscovered: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Contacts discovered'),
  telesaleHours: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Telesales hours'),
  meetingBooked: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Meetings Booked'),
  digitalProspectingMeetingBooked: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Meetings Booked'),
  targetPipelineValue: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Target pipeline value'),
  dealRegDone: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Deal reg done'),
  digitalLeads: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Digital leads'),
  connectionRequestsSent: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Connection requests sent'),
  connectionRequestsAccepted: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Connection requests accepted'),
  impressions: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Impressions'),
  campaignClicks: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Campaign clicks'),
}

export const objectiveResultSchema = Yup.object({
  ...baseObjectiveResultSchema,
  totalIntents: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total intents'),
  totalCompaniesDiscovered: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Companies discovered'),
  totalContactsDiscovered: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Contacts discovered'),
  totalTelesaleHours: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total telesale hours'),
  totalMeetingBooked: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total meeting booked'),
  totalTargetPipelineValue: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total target pipeline'),
  totalDealRegDone: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total deal reg done'),
  totalDigitalLeads: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total digital leads'),
  totalConnectionRequestsSent: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total connection requests sent'),
  totalConnectionRequestsAccepted: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total connection requests accepted'),
  totalImpressions: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total impressions'),
  totalCampaignClicks: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total campaign clicks'),
  totalDigitalProspectingMeetingBooked: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total meeting booked'),
})

export type ObjectiveResult = Yup.InferType<typeof objectiveResultSchema>

export const createObjectiveResultSchema = objectiveResultSchema.omit(['id'])
export type CreateObjectiveResult = Yup.InferType<typeof createObjectiveResultSchema>

export const objectiveSchema = Yup.object({
  id: Yup.number().integer().required(),
  intents: Yup.number().integer().required(),
  isIntentsEnabled: Yup.boolean().required(),
  isCompaniesDiscoveredEnabled: Yup.boolean().required(),
  isContactsDiscoveredEnabled: Yup.boolean().required(),
  isTelesaleHoursEnabled: Yup.boolean().required(),
  isMeetingBookedEnabled: Yup.boolean().required(),
  isTargetPipelineValueEnabled: Yup.boolean().required(),
  isDealRegDoneEnabled: Yup.boolean().required(),
  isDigitalLeadsEnabled: Yup.boolean().required(),
  isDigitalProspectingMeetingBookedEnabled: Yup.boolean().required(),
  isConnectionRequestsSentEnabled: Yup.boolean().required(),
  isConnectionRequestsAcceptedEnabled: Yup.boolean().required(),
  isImpressionsEnabled: Yup.boolean().required(),
  isCampaignClicksEnabled: Yup.boolean().required(),
  companiesDiscovered: Yup.number().integer().required(),
  contactsDiscovered: Yup.number().integer().required(),
  telesaleHours: Yup.number().integer().required(),
  meetingBooked: Yup.number().integer().required(),
  digitalProspectingMeetingBooked: Yup.number().required(),
  targetPipelineValue: Yup.number().integer().required(),
  dealRegDone: Yup.number().integer().required(),
  digitalLeads: Yup.number().integer().required(),
  connectionRequestsSent: Yup.number().integer().required(),
  connectionRequestsAccepted: Yup.number().integer().required(),
  impressions: Yup.number().integer().required(),
  campaignClicks: Yup.number().integer().required(),
  isDataIntelligenceActive: Yup.boolean().required(),
  isTeleProspectingActive: Yup.boolean().required(),
  isDigitalProspectingActive: Yup.boolean().required(),
  results: Yup.array(objectiveResultSchema),
})
export type Objective = Yup.InferType<typeof objectiveSchema>

export const createObjectiveRequestSchema = objectiveSchema.omit(['id', 'results'])
export type CreateObjectiveRequest = Yup.InferType<typeof createObjectiveRequestSchema>
