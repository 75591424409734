import { Accordion } from 'react-bootstrap'
import { ObjectiveResult } from '@ospace/schemas'
import { Metrics, StatBox } from '@ospace/shared'
import dayjs from 'dayjs'

export const WeeklyUpdades = ({ lastUpdate }: { lastUpdate: ObjectiveResult }) => (
  <Accordion defaultActiveKey='0' flush className='card my-10 bg-default px-5'>
    <Accordion.Item eventKey='0'>
      <Accordion.Button style={{ background: '#fff' }} className='flex'>
        <div className='d-flex flex-column card-title align-items-start'>
          <span className='card-label fw-bold fs-3 mb-1'>Latest updates</span>
          <span className='text-muted fw-semibold fs-7'>
            Updates to the campaign since <b>{dayjs(lastUpdate?.startDate).format('YYYY-MM-DD')}</b>
          </span>
        </div>
      </Accordion.Button>
      <Accordion.Body>
        <div className='row'>
          {Object.entries(Metrics).map(([key, value]) => {
            const k = key as keyof typeof lastUpdate
            if (lastUpdate[k]) {
              let title
              if (key === 'meetingBooked') title = 'Meetings booked (Tele)'
              else if (key === 'digitalProspectingMeetingBooked')
                title = 'Meetings booked (Digital)'
              else title = value

              return <StatBox key={key} title={title} value={lastUpdate[k]?.toString() || ''} />
            }
            return null
          })}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
)
