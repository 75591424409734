import React from 'react'
import { ActionIcon } from '@mantine/core'
import { User, useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { ConfirmationModal } from '@ospace/shared'
import { IconRestore, IconTrash } from '@tabler/icons-react'

import { useUser } from '../hooks/useUser'
import { useUserRemoveRestoreMutation } from '../hooks/useUserMutation'

export const DeleteButton = ({ user }: { user: User }) => {
  const mutation = useUserRemoveRestoreMutation()
  const role = useUserRole()
  const { data: currentUser } = useUser()

  const actionHandler = async () => {
    await mutation.mutateAsync({
      ...user,
      remove: !user.deletedAt,
    })
  }
  if (role !== userTypes.Admin) return null
  return (
    <div className='d-flex'>
      <ConfirmationModal
        alertMessage={
          <div>
            Are you sure you want to {user.deletedAt ? 'restore' : 'delete'} {user.firstName}{' '}
            {user.lastName}?
          </div>
        }
        action={actionHandler}
        renderButton={(rp) => (
          <ActionIcon
            {...rp}
            disabled={user.id === currentUser?.id}
            color={user.deletedAt ? 'blue' : 'red'}
            loading={mutation.isLoading && mutation.variables?.identityId === user.identityId}
          >
            {user.deletedAt ? <IconRestore /> : <IconTrash />}
          </ActionIcon>
        )}
        isLoading={mutation.isLoading && mutation.variables?.identityId === user.identityId}
      />
    </div>
  )
}
