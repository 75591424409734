import dayjs from 'dayjs'

import { Duration } from './duration'

export const plotGraphData = (graphData: Record<string, any>, duration: Duration) => {
  const dates = Object.keys(graphData)
  const start = dayjs(duration.segmentsDuration.start)
  const end = dayjs(duration.segmentsDuration.end)
  const isDifferentYear = start.year() !== end.year()
  const format = isDifferentYear ? 'MMM YYYY' : 'MMM'
  const firstMonth = dayjs(duration.segmentsDuration.start).format(format)
  const lastMonth = dayjs(duration.segmentsDuration.end).format(format)
  const graph = {
    count: Object.values(graphData).reduce((sum, value) => sum + value, 0),
    chartData: {
      data: dates.map((key) => ({
        x: key,
        y: graphData[key],
      })),
      chartLabels: {
        leftLabel: firstMonth,
        rightLabel: lastMonth,
      },
    },
  }

  // find the number dates in a month in the dates array
  const datesInMonth = dates.reduce(
    (result, date) => {
      const month = dayjs(date).format(format)
      return {
        ...result,
        [month]: result[month] ? result[month] + 1 : 1,
      }
    },
    {} as { [key: string]: number }
  )

  const maxDatesInMonth = Object.values(datesInMonth).sort((a, b) => b - a)[0]

  // find out which month is missing and add it to the graph
  const availableMonths = new Set(dates.map((date) => dayjs(date).format(format)))
  const missingMonths = []
  let currentMonth = start
  while (currentMonth.isBefore(end)) {
    const month = currentMonth.format(format)
    if (!availableMonths.has(month)) {
      missingMonths.push(month)
    }
    currentMonth = currentMonth.add(1, 'month')
  }

  // for each missing month, add a 0 value
  for (const month of missingMonths) {
    // add n dates according to maxDatesInMonth
    for (let i = 0; i < maxDatesInMonth; i++) {
      graph.chartData.data.push({
        x: dayjs(month).add(i, 'days').format('YYYY-MM-DD').toString(),
        y: 0,
      })
    }
  }

  // add 0 for each date of the month
  if (duration.selection === 'Month' || graph.chartData.data.length === 0) {
    const existingDates = new Set(graph.chartData.data.map((data) => data.x))
    const start = dayjs(duration.segmentsDuration.start)
    const datesInMonth = start.daysInMonth()
    const year = start.year()
    for (let i = 1; i <= datesInMonth; i++) {
      const date = dayjs(`${year}-${start.month() + 1}-${i}`)
        .format('YYYY-MM-DD')
        .toString()
      if (!existingDates.has(date)) {
        graph.chartData.data.push({
          x: date,
          y: 0,
        })
      }
    }
  }

  graph.chartData.data.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime())
  return graph
}
