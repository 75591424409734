import { Client } from '@ospace/schemas'

import { userTypes } from './constants'

// canUserViewLeadsDashboard checks if the user role passed can view the
// distribution pipeline for the client
type Opts = {
  userRole: userTypes
  client: Client
}

export const isClientsLeadsDashboardAvailableToUserRole = (opts: Opts) => {
  // if the vendorId is set, internal viewers can see the pipeline
  if (opts.userRole === userTypes.Admin) return true
  if (opts.userRole === userTypes.ClientManager) return true
  if (opts.userRole === userTypes.InternalViewer) return true
  if (opts.userRole === userTypes.CampaignManager) return true

  // showLeadsDashboardToExternalUser must be set for external users to see the pipeline
  if (!opts.client.showLeadsDashboardToExternalUser) return false

  // only ClientUser external users can see the pipeline
  if (opts.userRole === userTypes.ClientUser || opts.userRole === userTypes.ClientViewer)
    return true
  return false
}
