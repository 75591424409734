import { useQuery } from 'react-query'

import { StatsService } from './services'

export const useStats = (enabled: boolean = false) => {
  return useQuery<
    {
      clients: number
      activeClients: number
      activeCampaigns: number
      endingCampaigns: number
      users: number
      pendingInvitations: number
      pausedCampaigns: number
    },
    Error
  >('stats', StatsService.getStats, {
    enabled: enabled,
  })
}
