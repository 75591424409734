import { Flex, Grid, Group, Text } from '@mantine/core'
import {
  abbreviateNumber,
  currencyFormatter,
  Duration,
  opportunityFilter,
  totalCreatedPipeline,
  totalCreatedPipelineFilter,
} from '@ospace/common'
import { pipelineRatioHelper } from '@ospace/distribution-pipeline'
import { Opportunity } from '@ospace/schemas'
import { MoreInfo } from '@ospace/shared'
import { CurrencyText } from '@ospace/shared/components/CurrencyText'

import { BaseCard } from '../BaseCard'
import { CircularRatioChart } from '../CircularRatioChart'
import { SideBySideFigures } from '../SideBySideFigures'
import { TwoColumnTable } from '../TwoColumnTable'

import { TopCard } from './TopCard'

const COLORS = {
  NEXTGEN_INITIATED: '#7950F2',
  PARTNER_INITIATED: '#A487F9',
  VENDOR_INITIATED: '#C8B7FB',
}
export const PipelineCard = ({
  opportunities,
  currency,
  duration,
}: {
  currency: string
  duration: Duration
  opportunities: Opportunity[]
}) => {
  const segmentData = pipelineRatioHelper({ opportunities, duration })
  const { format } = currencyFormatter(true)

  const sortedByRevenue = opportunities
    .filter(
      (opportunity) =>
        opportunityFilter(opportunity) &&
        totalCreatedPipelineFilter({ startDate: opportunity.startDate, duration, closeDate: '' })
    )
    .sort((a, b) => +(b.calculatedBuyPrice || 0) - +(a.calculatedBuyPrice || 0))
    .map(({ oppName, endUserName, vendorName, calculatedBuyPrice }) => ({
      key: oppName || endUserName || vendorName,
      value: format(+(calculatedBuyPrice || 0)),
    }))

  const ratioData = [
    {
      color: COLORS.NEXTGEN_INITIATED,
      label: 'NEXTGEN Initiated',
      value: <CurrencyText value={format(segmentData.nextGen.value)} currency={currency} />,
    },
    {
      color: COLORS.PARTNER_INITIATED,
      label: 'Partner Initiated',
      value: <CurrencyText value={format(segmentData.partner.value)} currency={currency} />,
    },
    {
      color: COLORS.VENDOR_INITIATED,
      label: 'Vendor Initiated',
      value: <CurrencyText value={format(segmentData.vendor.value)} currency={currency} />,
    },
  ]
  const total = segmentData.nextGen.value + segmentData.partner.value + segmentData.vendor.value
  const partnerInitiatedPercentage = Math.round((segmentData.partner.value / total) * 100)
  const nextgenInitiatedPercentage = Math.round((segmentData.nextGen.value / total) * 100)
  const createdPipeline = totalCreatedPipeline({ opportunities, duration })
  const topDeals = sortedByRevenue.slice(0, 5)

  return (
    <>
      <TopCard opportunities={opportunities} currency={currency} duration={duration} />
      <BaseCard>
        <Group position='left'>
          <Text c='dimmed' tt='uppercase' fw={600} fz='md'>
            CREATED PIPELINE
          </Text>
          <MoreInfo
            info={
              'This value is calculated to by summing all deals started in the selected period.'
            }
          />
        </Group>
        <SideBySideFigures
          left={{
            label: 'Total value',
            value: (
              <CurrencyText
                value={abbreviateNumber(createdPipeline.totalValue)}
                currency={currency}
              />
            ),
          }}
          right={{
            label: 'Total deals',
            value: createdPipeline.totalDeals,
          }}
          mt='md'
        />
        <TwoColumnTable
          title={{
            text: 'TOP 5 DEALS BY REVENUE',
            tooltip: 'Top 5 deals by revenue',
          }}
          data={topDeals}
          currency={currency}
        />
        <Grid gutter={15} mt='xl' c='gray.7'>
          <Grid.Col span={6}>
            <Group position='left' pt={10}>
              <Text c='dimmed' tt='uppercase' fw={600} fz='md'>
                RATIO OF PIPELINE
              </Text>
              <MoreInfo info='This chart displays the total number and total value of deals, segmented by the source of the deal.' />
            </Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex justify='center'>
              <CurrencyText value={format(segmentData.total)} currency={currency} />
            </Flex>
          </Grid.Col>
        </Grid>
        <CircularRatioChart
          ratioData={ratioData}
          partnerInitiatedPercentage={partnerInitiatedPercentage}
          nextgenInitiatedPercentage={nextgenInitiatedPercentage}
          total={`${currency} ${abbreviateNumber(segmentData.total)}`}
        />
      </BaseCard>
    </>
  )
}
