import { useNavigate } from 'react-router-dom'
import { Grid, Group, Paper, Text, ThemeIcon } from '@mantine/core'
import {
  IconCircleDashed,
  IconClockPause,
  IconTrendingDown,
  IconUsers,
  IconUserScan,
} from '@tabler/icons-react'

export const StatBox = ({ stat }: any) => {
  const navigate = useNavigate()
  const handleClick = () => {
    stat.link && navigate(stat.link)
  }

  return (
    <Grid.Col md={2} xs={6}>
      <Paper
        withBorder
        p='md'
        radius='md'
        key={stat.title}
        onClick={handleClick}
        style={{ cursor: stat.link ? 'pointer' : 'default' }}
      >
        <Group align='space-between'>
          <ThemeIcon size={24} variant='default' color='#464E5F' style={{ border: 0 }}>
            {stat.icon}
          </ThemeIcon>

          <Text
            size='sm'
            fw={'bold'}
            color={'dimmed'}
            style={{ textTransform: 'uppercase', whiteSpace: 'pre-wrap' }}
            h={50}
            mt={2}
          >
            {stat.title}
          </Text>
        </Group>

        <Group mt={'lg'} mb={'md'} position={'center'}>
          <Text size={36} weight={'bolder'} color={'#464E5F'}>
            {stat.value}
          </Text>
        </Group>
      </Paper>
    </Grid.Col>
  )
}

export const StatsList = ({ stats }: any) => {
  const statsMap = [
    {
      title: 'Clients',
      icon: <IconUsers style={{ border: 0 }} />,
      value: stats.clients,
    },
    {
      title: 'Active Clients',
      icon: <IconUserScan />,
      value: stats.activeClients,
    },
    {
      title: `In Progress \n Campaigns`,
      icon: <IconCircleDashed />,
      value: stats.activeCampaigns,
      link: '/campaigns?filter=In%20Progress',
    },
    {
      title: 'Paused  \n Campaigns',
      icon: <IconClockPause />,
      value: stats.pausedCampaigns,
      link: '/campaigns?filter=Paused',
    },
    {
      title: 'Ending soon  \n Campaigns',
      icon: <IconTrendingDown />,
      value: stats.endingCampaigns,
      link: '/campaigns?filter=Ending%20Soon',
    },
  ]
  return (
    <Grid mb='sm' grow>
      {statsMap.map((stat: any) => (
        <StatBox stat={stat} key={stat.title} />
      ))}
    </Grid>
  )
}
