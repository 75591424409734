import { FC, useState } from 'react'
import { ActionIcon, MultiSelect, Skeleton, Table, TextInput } from '@mantine/core'
import { useNotificationContext } from '@ospace/shared'
import { IconDeviceFloppy, IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'

import { useProductLines } from '../hooks'

export type Product = { name: string; productLines: string[]; id: string }

const Loader = () => (
  <>
    <td>
      <Skeleton height={18} radius='xl' />
    </td>
    <td>
      <Skeleton height={18} radius='xl' />
    </td>
    <td>
      <Skeleton height={18} radius='xl' />
    </td>
    <td>
      <Skeleton height={18} radius='xl' />
    </td>
  </>
)

const ProductsTable: FC<{
  products: Product[]
  postProduct: (product: Product) => void
  deleteProduct: (productId: string) => void
  updatingProductId?: string
}> = ({ products, postProduct, updatingProductId, deleteProduct }) => {
  const productLinesData = useProductLines()
  const { setNotification } = useNotificationContext()
  const [editProductName, setEditProductName] = useState('')
  const [editProductLines, setEditProductLines] = useState<string[]>([])
  const [editProduct, setEditProduct] = useState('')
  const [productLines, setProductLines] = useState<string[]>([])
  const [product, setProduct] = useState('')

  const updateHandler = (product: Product) => {
    if (products.some((p) => p.name === editProductName && p.id !== product.id)) {
      setNotification({
        active: true,
        message: 'Product name must be unique.',
        type: 'danger',
      })
      return
    }
    setEditProduct('')
    postProduct({
      id: product.id,
      name: editProductName,
      productLines: editProductLines,
    })
  }

  const addHandler = () => {
    if (products.some((p) => p.name === product)) {
      setNotification({
        active: true,
        message: 'Product name must be unique.',
        type: 'danger',
      })
      return
    }
    postProduct({
      name: product,
      productLines,
      id: crypto.randomUUID(),
    })
    setProduct('')
    setProductLines([])
  }
  return (
    <div className='w-xl-600px'>
      <Table verticalSpacing='xs' highlightOnHover fontSize='md'>
        <thead>
          <tr key='header'>
            <th>Product</th>
            <th>Product lines</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product: Product) => (
            <tr key={product.name}>
              {updatingProductId === product.id ? (
                <Loader />
              ) : (
                <>
                  <td>
                    {editProduct === product.id ? (
                      <TextInput
                        value={editProductName}
                        onChange={(e) => {
                          setEditProductName(e.target.value)
                        }}
                      />
                    ) : (
                      product.name
                    )}
                  </td>
                  <td>
                    <MultiSelect
                      searchable
                      readOnly={editProduct !== product.id}
                      value={editProduct !== product.id ? product.productLines : editProductLines}
                      onChange={setEditProductLines}
                      data={productLinesData.status === 'success' ? productLinesData.data : []}
                    />
                  </td>
                  <td>
                    <ActionIcon
                      color={
                        editProduct === product.id && editProductName && editProductLines.length > 0
                          ? 'green'
                          : 'blue'
                      }
                    >
                      {editProduct === product.id &&
                      editProductName &&
                      editProductLines.length > 0 ? (
                        <IconDeviceFloppy
                          size='1.125rem'
                          onClick={() => {
                            updateHandler(product)
                          }}
                        />
                      ) : (
                        <IconEdit
                          size='1.125rem'
                          onClick={() => {
                            setEditProduct(product.id)
                            setEditProductName(product.name)
                            setEditProductLines(product.productLines)
                          }}
                        />
                      )}
                    </ActionIcon>
                  </td>
                  <td>
                    <ActionIcon
                      color='red'
                      onClick={() => {
                        deleteProduct(product.id)
                      }}
                    >
                      <IconTrash size='1.125rem' />
                    </ActionIcon>
                  </td>
                </>
              )}
            </tr>
          ))}
          {updatingProductId && !products.some((p) => p.id === updatingProductId) ? (
            <tr>
              <Loader />
            </tr>
          ) : (
            <tr>
              <td>
                <TextInput
                  aria-label='new-product-name'
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                />
              </td>
              <td>
                <MultiSelect
                  searchable
                  aria-label='new-product-lines'
                  value={productLines}
                  onChange={setProductLines}
                  data={productLinesData.status === 'success' ? productLinesData.data : []}
                />
              </td>
              <td>
                <ActionIcon
                  aria-label='add-product-button'
                  color='green'
                  disabled={!product || !productLines.length}
                  onClick={addHandler}
                >
                  <IconPlus size='1.125rem' />
                </ActionIcon>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export { ProductsTable }
