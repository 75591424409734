import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { ActionIcon, Button, Group, Image, Menu, Overlay, Paper, Stack, Text } from '@mantine/core'
import { useRemoveRestoreClientMutation } from '@ospace/client/hooks'
import { userTypes } from '@ospace/core-auth'
import { Client } from '@ospace/schemas'
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react'

export const ClientBox = ({ client, role }: { client: Client; role: userTypes }) => {
  const location = useNavigate()

  const handleClick = (event: any) => {
    if (client.deletedAt) return
    event.stopPropagation()
    location(`/client/${client.id}/dashboard`)
  }

  const showMenu = role === userTypes.Admin || role === userTypes.ClientManager
  const mutation = useRemoveRestoreClientMutation()

  const [modal, toggleModal] = useState(false)

  const handleClose = () => {
    toggleModal(false)
  }

  const handleContinue = async () => {
    await mutation.mutateAsync(client)
    toggleModal(false)
  }

  return (
    <Paper withBorder p='xl' radius='md' pos='relative'>
      <Modal show={modal} onHide={handleClose} centered={true}>
        <Modal.Body>
          Are you sure you want to {client.deletedAt ? 'restore' : 'delete'} this client{' '}
          {client.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={mutation.isLoading}
            color={client.deletedAt ? 'green' : 'red'}
            onClick={handleContinue}
          >
            {client.deletedAt ? 'Restore' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Group align={'flex-end'} w={'100%'} position={'right'} style={{ height: 0 }}>
        {showMenu ? (
          <Menu
            transitionProps={{ transition: 'pop' }}
            withArrow
            position='bottom-end'
            withinPortal
            disabled={!!client.deletedAt}
          >
            <Menu.Target>
              <ActionIcon variant='subtle' color='gray'>
                <IconDotsVertical stroke={1.5} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconEdit size={14} />}
                component={Link}
                to={`/client/edit/${client.id}`}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                icon={<IconTrash size={14} />}
                onClick={() => {
                  toggleModal(true)
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : null}
      </Group>

      <Group
        onClick={handleClick}
        spacing={'md'}
        style={{ cursor: 'pointer' }}
        role='link'
        aria-label={client.name}
      >
        <Stack>
          <Image src={client.signedLogoUrl} width={90} height={90} fit={'contain'} />
        </Stack>
        <div onClick={handleClick}>
          <Text fz='lg' fw={500} mt={'sm'}>
            {client.name}
          </Text>

          <Text fz='md' c='dimmed'>
            {client.website}
          </Text>
        </div>
        {client.deletedAt && showMenu && (
          <Overlay blur={1} center>
            <Button
              color='green'
              radius='xl'
              onClick={() => {
                toggleModal(true)
              }}
            >
              Restore
            </Button>
          </Overlay>
        )}
      </Group>
    </Paper>
  )
}
