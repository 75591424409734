import { API } from '@ospace/shared'

const getNotesForCampaign = async (campaignId: number) => {
  return API.get('client', `/notes/campaigns/${campaignId}`, {})
}

const getNotesForCampaignAndPerson = async (campaignId: number, personId: number) => {
  return API.get('client', `/notes/campaigns/${campaignId}/persons/${personId}`, {})
}

const getPotentialOpForCampaign = async (campaignId: number) => {
  return API.get('client', `/notes:potential-opportunities/campaigns/${campaignId}`, {})
}

const getAccountInsightForCampaign = async (campaignId: number) => {
  return API.get('client', `/notes:account-insights/campaigns/${campaignId}`, {})
}

const getNotesForCampaignAndCompany = async (campaignId: number, companyId: number) => {
  return API.get('client', `/notes/campaigns/${campaignId}/companies/${companyId}`, {})
}

export const NoteService = {
  getNotesForCampaign,
  getNotesForCampaignAndPerson,
  getPotentialOpForCampaign,
  getNotesForCampaignAndCompany,
  getAccountInsightForCampaign,
}
