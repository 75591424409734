import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Client, CreateClientRequest, UpdateClientRequest } from '@ospace/schemas'
import { useNotificationContext } from '@ospace/shared'
import { API, Storage } from '@ospace/shared'

const getClients = async (
  nextPage: number,
  pageLimit: number,
  searchValue: string,
  withDeleted: boolean
): Promise<{ clients: Client[]; recordCount: number }> => {
  const { records, recordCount } = await API.get('client', `/clients`, {
    queryStringParameters: { limit: pageLimit, page: nextPage || '', searchValue, withDeleted },
  })
  if (!Array.isArray(records)) {
    throw new Error('Failed to get clients')
  }
  return {
    clients: records,
    recordCount,
  }
}

// Client dropdown do not need pagination.
// This is used to fetch all clients
const UNLIMITED_PAGE_LIMIT = -1 // take all clients

export const useClients = (
  {
    nextPage,
    pageLimit = UNLIMITED_PAGE_LIMIT,
    enabled = true,
    searchValue = '',
    withDeleted = false,
  }: {
    nextPage: number
    pageLimit?: number
    enabled?: boolean
    searchValue?: string
    withDeleted?: boolean
  } = {
    nextPage: UNLIMITED_PAGE_LIMIT,
    pageLimit: UNLIMITED_PAGE_LIMIT,
    enabled: true,
    searchValue: '',
    withDeleted: false,
  }
) => {
  return useQuery(
    ['clients', nextPage, pageLimit, searchValue, withDeleted],
    () => getClients(nextPage, pageLimit, searchValue, withDeleted),
    {
      enabled,
      keepPreviousData: true, // don't have 0 rows flash while changing pages/loading next page
    }
  )
}

export const useClient = (clientId: number) => {
  return useQuery<Client>({
    queryKey: ['client', clientId],
    queryFn: async () => {
      const client = await API.get('client', `/clients/${clientId}`, {})
      return client as Client
    },
    enabled: clientId !== undefined,
  })
}

export const useCreateClientMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: CreateClientRequest & { logoFile: File | null }) => {
      const body: CreateClientRequest = req

      const resp = await API.post('client', `/clients`, {
        body,
      })

      if (!req.logoFile) return resp as Client
      return updateClient({
        ...req,
        id: resp ? resp.id : '',
        logo: null,
      })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['clients'], refetchInactive: true })
        setNotification({
          active: true,
          message: 'You have successfully created the client.',
          type: 'success',
        })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.message,
          type: 'danger',
        })
      },
    }
  )
}

const updateClient = async (req: UpdateClientRequest & { logoFile: File | null }) => {
  const logo = req.logoFile ? await uploadLogo(req.id, req.logoFile) : req.logo
  const body: UpdateClientRequest = {
    ...req,
    logo,
  }
  const resp = await API.put('client', `/clients/${req.id}`, {
    body,
  })
  return resp as Client
}

export const useUpdateClientMutation = (props: { clientId: number }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(updateClient, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['client', props.clientId] })
      await queryClient.invalidateQueries({ queryKey: ['clients'], refetchInactive: true })
      await queryClient.invalidateQueries({
        queryKey: ['teamMembers', props.clientId],
        refetchInactive: true,
      })
      setNotification({
        active: true,
        message: ' You have successfully edited the client.',
        type: 'success',
      })
    },
  })
}

export const useRemoveRestoreClientMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    (client: Client) => {
      return API.post(
        'client',
        `/clients/${client.id}/${client.deletedAt ? 'restore' : 'remove'}`,
        {}
      )
    },
    {
      onSuccess: async ({ clientId, action }: { clientId: string; action: string }) => {
        await queryClient.invalidateQueries({ queryKey: ['client', clientId] })
        await queryClient.invalidateQueries({ queryKey: ['clients'], refetchInactive: true })
        await queryClient.invalidateQueries({
          queryKey: ['teamMembers', clientId],
          refetchInactive: true,
        })

        setNotification({
          active: true,
          message: `You have successfully ${action} the client.`,
          type: 'success',
        })
      },
    }
  )
}

const uploadLogo = async (clientId: number, file: File) => {
  // Had to add clients to make this backwards compatible with the old code
  await Storage().put(`clients/${clientId}/${file.name}`, file)

  return `${clientId}/${file.name}`
}
