export const currencyFormatter = (noDecimals = false) =>
  new Intl.NumberFormat('en-AU', {
    ...(noDecimals && {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  })

export const abbreviateNumber = (num: number, noDecimals = false) => {
  if (!num || num === Infinity) return currencyFormatter(noDecimals).format(0)
  const formatter = currencyFormatter(noDecimals)
  // billions
  if (num > 999999999) {
    return `${formatter.format(+(num / 1000000000).toFixed(1))}B`
  }
  if (num > 999999) {
    return `${formatter.format(+(num / 1000000).toFixed(1))}M`
  }
  return num > 999 ? `${formatter.format(+(num / 1000).toFixed(1))}K` : formatter.format(num)
}
