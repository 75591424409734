import { useMutation, useQueryClient } from 'react-query'
import { UserService } from '@ospace/auth'
import { useNotificationContext } from '@ospace/shared'

interface IProps {
  username: string
  status: boolean
}
export const useChangeUserStatus = () => {
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  return useMutation(
    async ({ username, status }: IProps) => {
      await UserService.changeUserStatus(username, status)
      return { username, status }
    },
    {
      onSuccess: ({ username, status }: IProps) => {
        setNotification({
          active: true,
          message: `The user was sent successfully ${status ? 'enabled' : 'disabled'}.`,
          type: 'success',
        })

        queryClient.setQueryData(['users'], (users: any) => {
          const user = users.find((d: any) => d.identityId === username)
          user.enabled = status
          return users
        })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )
}
