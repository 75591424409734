import { Grid, Text } from '@mantine/core'
import { Member } from '@ospace/schemas'

import { MemberBox } from './MemberBox'

export const MemberList = ({ teamMembers }: { teamMembers: Member[] }) => {
  return (
    <Grid gutter={'xl'}>
      {teamMembers.length > 0 ? (
        teamMembers.map((team: any) => (
          <Grid.Col md={4} xs={12}>
            <MemberBox user={team} />
          </Grid.Col>
        ))
      ) : (
        <Text>No team members assigned yet!</Text>
      )}
    </Grid>
  )
}
