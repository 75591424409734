import * as React from 'react'
import { ReactElement } from 'react'
import {
  AppShell,
  Box,
  Center,
  Container,
  createStyles,
  Header,
  Loader,
  Stack,
} from '@mantine/core'
import { User } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { Notification } from '@ospace/shared'

import { CustomHeader } from './CustomHeader'
import { Footer } from './Footer'

const useStyles = createStyles((theme) => ({
  main: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },
  mainContainer: {
    margin: -12,
    height: '100%',
  },
  innerPage: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },
}))

const EmptyHeader = (
  <Header height={0}>
    <></>
  </Header>
)

type Props =
  | {
      status: 'ready'
      children: ReactElement
      user: User
      role: userTypes
      title?: string
      subHeader?: ReactElement
      fluid?: boolean
    }
  | {
      status: 'loading'
      title?: string
      user?: User
      role: userTypes
      subHeader?: ReactElement
      fluid?: boolean
    }
  | {
      status: 'error'
      title?: string
      user?: User
      role: userTypes
      subHeader?: ReactElement
      fluid?: boolean
      message: string
    }

export const FullPageLayout = (props: Props) => {
  const { classes } = useStyles()
  return (
    <>
      <Notification />
      <AppShell className={classes.main} navbar={<></>} header={EmptyHeader}>
        <Stack justify='space-between' h='100%'>
          <Box className={classes.mainContainer}>
            <CustomHeader
              user={props.user}
              role={props.role}
              subHeader={props.subHeader}
              pageTitle={props.title || ''}
            />
            <Container fluid={props.fluid} size={'lg'} py={'lg'}>
              {props.status === 'ready' ? props.children : null}
              {props.status === 'loading' ? (
                <Center maw='100vh' h='100vh' mx='auto' role='alert' aria-label='loading'>
                  <Loader variant='bars' role='alert' aria-label='loading' />
                </Center>
              ) : null}
              {props.status === 'error' ? props.message : null}
            </Container>
          </Box>
          <Footer />
        </Stack>
      </AppShell>
    </>
  )
}
