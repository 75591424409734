import { FC } from 'react'
import { createStyles, Text, Title } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
}))

const Error500: FC = () => {
  const { classes } = useStyles()

  return (
    <>
      <div className={classes.label}>500</div>
      <Title className={classes.title}>System Error!</Title>
      <Text color='dimmed' size='lg' align='center' className={classes.description}>
        Something went wrong! <br /> Please try again later.
      </Text>
    </>
  )
}

export { Error500 }
