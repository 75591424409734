import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  CreateFolderReq,
  CreateFolderResp,
  DeleteFileReq,
  DeleteFileResp,
  DeleteFolderReq,
  DeleteFolderResp,
  GetFolderReq,
  GetFolderResp,
} from '@ospace/schemas/file-manager'
import { useNotificationContext } from '@ospace/shared'
import { API } from '@ospace/shared'

export const useFileManagerFolder = (req: GetFolderReq) => {
  const res = useQuery(
    ['file-manager', req.campaignId, req.folderPath],
    async () => {
      const resp = await API.get(
        'client',
        `/file-manager/folder?campaignId=${req.campaignId}&folderPath=${encodeURIComponent(
          req.folderPath
        )}`,
        {}
      )
      return resp as GetFolderResp
    },
    {
      refetchInterval: (res) => {
        // The presigned urls and presigned post form have an expiry set by the
        // server. They will need to be refetched before the expiry to
        // function.
        if (res) {
          // refresh 1 minute before expiry
          return res.expires - Date.now() - 60000
        }
        return 540000 // default to 9 minutes
      },
    }
  )

  return res
}
interface FileWithPath extends File {
  path?: string
}

export const useUploadFilesMutation = (req: GetFolderReq) => {
  const queryClient = useQueryClient()
  const folderResp = useFileManagerFolder(req)
  const { setNotification } = useNotificationContext()

  const uploadFiles = ({ files, dropFolder }: { files: FileWithPath[]; dropFolder: string }) => {
    if (folderResp.status !== 'success') {
      throw new Error('uploadFile cannot be done until folder is loaded')
    }
    return Promise.all(Array.from(files).map(uploadFile(dropFolder)))
  }
  const uploadFile =
    (dropFolder = '') =>
    async (file: FileWithPath) => {
      let uploadURL, fields
      if (file?.path) {
        // create a new upload link for each file path
        const paths = `${dropFolder || req.folderPath}${file.path}`.replace(/\/\//g, '/').split('/')
        paths.pop()
        const path = paths.join('/')
        const resp = await API.get(
          'client',
          `/file-manager/folder?campaignId=${req.campaignId}&folderPath=${encodeURIComponent(
            path
          )}/`,
          {}
        )
        uploadURL = { ...uploadFile, [path]: resp.uploadFileForm }
        uploadURL = resp.uploadFileForm.url
        fields = resp.uploadFileForm.fields
      }
      if (folderResp.status !== 'success') {
        throw new Error('uploadFile cannot be done until folder is loaded')
      }
      uploadURL = uploadURL || folderResp.data.uploadFileForm.url
      fields = fields || folderResp.data.uploadFileForm.fields
      const fd = new FormData()
      Object.entries(fields).forEach(([k, v]) => {
        fd.append(k, v as string)
      })

      fd.append('file', file)

      return fetch(uploadURL, { method: 'POST', body: fd })
    }

  const mutation = useMutation(uploadFiles, {
    onSuccess: () => {
      queryClient.invalidateQueries(['file-manager', req.campaignId])
      setNotification({
        active: true,
        type: 'success',
        message: 'You have successfully uploaded a file.',
      })
    },
    onError: () => {
      setNotification({
        active: true,
        type: 'danger',
        message: 'The file failed to upload.',
      })
    },
  })

  return mutation
}

export const useCreateFolderMutation = (props: { campaignId: number; onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: CreateFolderReq) => {
      const resp = await API.post('client', '/file-manager/folder', {
        body: req,
      })
      return resp as CreateFolderResp
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['file-manager', props.campaignId])
        if (props.onSuccess) {
          props.onSuccess()
        }
        setNotification({
          active: true,
          type: 'success',
          message: 'You have successfully created a folder.',
        })
      },
      onError: () => {
        setNotification({
          active: true,
          type: 'danger',
          message: 'The folder failed to create.',
        })
      },
    }
  )
}

export const useDeleteFileMutation = (props: { campaignId: number; onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: DeleteFileReq) => {
      const resp = await API.post('client', '/file-manager/file/delete', {
        body: req,
      })
      return resp as DeleteFileResp
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['file-manager', props.campaignId])
        if (props.onSuccess) {
          props.onSuccess()
        }
        setNotification({
          active: true,
          type: 'success',
          message: 'You have successfully deleted a file.',
        })
      },
      onError: () => {
        setNotification({
          active: true,
          type: 'danger',
          message: 'The file failed to delete.',
        })
      },
    }
  )
}

export const useDeleteFolderMutation = (props: { campaignId: number; onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: DeleteFolderReq) => {
      const resp = await API.post('client', '/file-manager/folder/delete', {
        body: req,
      })
      return resp as DeleteFolderResp
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['file-manager', props.campaignId])
        if (props.onSuccess) {
          props.onSuccess()
        }
        setNotification({
          active: true,
          type: 'success',
          message: 'You have successfully deleted a folder.',
        })
      },
      onError: () => {
        setNotification({
          active: true,
          type: 'danger',
          message: 'The folder failed to delete.',
        })
      },
    }
  )
}
