import { Grid, Group, Paper, SimpleGrid, Text } from '@mantine/core'
import { salesSegments } from '@ospace/common'
import { Opportunity, SalesInvoice } from '@ospace/schemas'
import { MoreInfo, RatioChart, SalesWidgetsHelper } from '@ospace/shared'

interface Props {
  sales: SalesInvoice[]
  currency: string
  opportunities: Opportunity[]
}
export function SalesWidgets({ currency, sales, opportunities }: Props) {
  const segmentData = salesSegments({ sales, opportunities })
  const cards = SalesWidgetsHelper({ sales, currency }).map((item) => (
    <Paper withBorder p='lg' radius='md' key={item.title} aria-label={item.title}>
      <Grid>
        <Grid.Col span='auto'>
          <Paper p={5}>
            <Group position='left'>
              <Text c='dimmed' tt='uppercase' fw={700} fz='md'>
                {item.title}
              </Text>
              <MoreInfo info={item.tooltip} />
            </Group>
            <Group
              position='apart'
              mt='md'
              c='gray.7'
              aria-label={`${item.title.toUpperCase()} VALUE`}
            >
              {item.element}
            </Group>
          </Paper>
        </Grid.Col>
      </Grid>
    </Paper>
  ))
  return (
    <Grid>
      <Grid.Col md={6}>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          {cards}
        </SimpleGrid>
      </Grid.Col>
      <Grid.Col md={6}>
        <RatioChart
          segmentData={segmentData}
          currency={currency}
          label={'ratio of sales'}
          abbreviatedCurrency
          tooltip={
            'NEXTGEN initiated sales are sales transactions that originated from an oSpace initiated opportunity.  Partner iniitiated sales originate from Partner sourced opportunities.  The remaining sales transactions originate from Vendor sourced opportunities'
          }
        />
      </Grid.Col>
    </Grid>
  )
}
