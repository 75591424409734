import React, { createContext, useContext, useState } from 'react'
import { Client } from '@ospace/schemas'

export type ClientLink = {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export type ClientDataContextModel = {
  client?: Client
  setClient: (client: Client) => void
}

const ClientDataContext = createContext<ClientDataContextModel>({
  setClient: (client: Client) => {},
})

const ClientDataProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState<Client>()
  const value: ClientDataContextModel = {
    client,
    setClient,
  }
  return <ClientDataContext.Provider value={value}>{children}</ClientDataContext.Provider>
}

const useClientData = () => {
  return useContext(ClientDataContext)
}

export { ClientDataProvider, useClientData }
