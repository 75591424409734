import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, createStyles, Group, Text } from '@mantine/core'

import { usePageData } from '../legacy/PageData'

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 600,
    color: theme.colors.dark[9],
    marginRight: theme.spacing.md,
  },
  link: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[5],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
  activeLink: {
    color: theme.colors.dark[7],
  },
}))

export const PageTitleBreadcrumb: FC = () => {
  const { pageTitle, pageBreadcrumbs } = usePageData()
  const { classes } = useStyles()

  const items = pageBreadcrumbs?.map((item, index) => (
    <Link to={item.path} key={index} className={item.isActive ? classes.activeLink : classes.link}>
      {item.title}
    </Link>
  ))

  return (
    <Group>
      <Text className={classes.title}>{pageTitle}</Text>
      <Breadcrumbs>{items}</Breadcrumbs>
    </Group>
  )
}
