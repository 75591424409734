import React from 'react'
import { Flex, Group, Text } from '@mantine/core'
import { Duration } from '@ospace/common'
import {
  LineChart,
  plotConvertedLeads,
  plotDiscovery,
  plotLeads,
  plotMeetingBooked,
} from '@ospace/leads-dashboard'
import { Lead } from '@ospace/schemas'
import { MoreInfo } from '@ospace/shared'

import { BaseCard } from '../BaseCard'
import { CircularChart } from '../CircularRatioChart'
import { SideBySideFigures } from '../SideBySideFigures'

import { TopCard } from './TopCard'

const GraphHeader = ({
  left,
  right,
  title,
  tooltip,
  children,
}: {
  title: string
  tooltip: string
  left: {
    label: string
    value: string | number
  }
  right: {
    label: string
    value: string | number
  }
  children?: React.ReactNode
}) => {
  return (
    <>
      <Group position='left'>
        <Text tt='uppercase' c='dimmed' fw={600} fz='md'>
          {title}
        </Text>
        <MoreInfo info={tooltip} />
      </Group>
      <SideBySideFigures left={left} right={right} mt='md' />
      {children}
    </>
  )
}

const DEFAULT_COLOR = '#4C6EF5'
export const LeadsCard = ({ leads, duration }: { leads: Lead[]; duration: Duration }) => {
  const discoveryChart = plotDiscovery(leads, duration)
  const leadsChart = plotLeads(leads, discoveryChart.right.value, duration, 'white')
  const meetingBookedChart = plotMeetingBooked(leads, leadsChart.left.value, duration)
  const convertedLeadChart = plotConvertedLeads({
    leads,
    leadsCount: leadsChart.left.value,
    duration,
    color: DEFAULT_COLOR,
    size: 180,
    thickness: 12,
  })
  const convertedLeadsCount = convertedLeadChart.right.value
  const totalLeadsCount = convertedLeadChart.left.value

  return (
    <>
      <TopCard {...leadsChart} />
      <BaseCard>
        <GraphHeader {...convertedLeadChart}>
          <CircularChart
            sections={[
              {
                value: convertedLeadsCount,
                color: DEFAULT_COLOR,
              },
            ]}
            total={`${Math.round((convertedLeadsCount / totalLeadsCount) * 100) || 0}%`}
          />
        </GraphHeader>
        <GraphHeader {...meetingBookedChart}>
          <LineChart
            data={[
              {
                id: 'pipeline',
                color: DEFAULT_COLOR,
                data: meetingBookedChart.data,
              },
            ]}
            enableArea={true}
          />
          <Group position='apart' fw={500} c='dimmed'>
            <Flex justify={`flex-start`}>{meetingBookedChart.chartLabels.leftLabel}</Flex>
            <Flex justify={`flex-start`}>{meetingBookedChart.chartLabels.rightLabel}</Flex>
          </Group>
        </GraphHeader>
      </BaseCard>
    </>
  )
}
