import { Button, Card, createStyles, Flex, Text } from '@mantine/core'

const CallToActionCards = ({
  title,
  description,
  buttonLabel,
  classes,
}: {
  title: string
  description: string
  buttonLabel: string
  classes: {
    card: any
    title: any
    button: any
  }
}) => {
  return (
    <Card className={classes.card} padding='lg' radius='md' withBorder shadow='sm'>
      <Text className={classes.title}>{title}</Text>
      <Text mt='sm' size='md' mih='80px'>
        {description}
      </Text>
      <Flex justify='flex-end'>
        <Button
          className={classes.button}
          mt='md'
          radius='md'
          component='a'
          href='https://ospace.nextgen.group/strategy-meeting'
        >
          {buttonLabel}
        </Button>
      </Flex>
    </Card>
  )
}

export const GenerateMoreLeads = () => {
  const useStyles = createStyles(() => ({
    card: {
      backgroundColor: '#464E5F',
      color: 'white',
    },
    title: {
      fontSize: '18px',
      fontWeight: 700,
    },
    button: {
      backgroundColor: '#FFA53F',
      '&:hover': {
        backgroundColor: 'rgba(255,165,63,0.63)',
      },
    },
  }))
  const { classes } = useStyles()
  return (
    <CallToActionCards
      title='Generate more leads'
      description='Explore our leads generation by creating a new oSpace Program. Chat with one of our Program
        Mangers today.'
      buttonLabel='Create more leads'
      classes={classes}
    />
  )
}

export const ScaleWithUsToday = () => {
  const useStyles = createStyles(() => ({
    card: {
      backgroundColor: '#FFA53F',
      color: 'white',
    },
    title: {
      fontSize: '18px',
      fontWeight: 700,
    },
    button: {
      backgroundColor: '#464E5F',
      '&:hover': {
        backgroundColor: 'rgba(70,78,95,0.61)',
      },
    },
  }))
  const { classes } = useStyles()
  return (
    <CallToActionCards
      title='Scale with us today'
      description='Leveraging the experience and local knowlege of the NEXTGEN Group Sales team allows you to access invaluable customer insights and market data.'
      buttonLabel='Scale now'
      classes={classes}
    />
  )
}
