import React from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Campaign, CampaignStatus } from '@ospace/schemas'
import { Table, useSearchParams } from '@ospace/shared'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { useCampaigns } from '../hooks'
import { defaultCampaignSort } from '../utils'

dayjs.extend(isBetween)

const CellLink = (props: { to: string; children: React.ReactNode }) => (
  <Link to={props.to} className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
    {props.children}
  </Link>
)

export const CampaignsListPage: React.FC<any> = () => {
  const params = useSearchParams()
  const statusFilter = params.get('filter')
  const campaignStatusValues = Object.values(CampaignStatus) as string[]
  const { isLoading, data: campaigns } = useCampaigns()

  const filter = ({ status, endDate }: Campaign) => {
    if (statusFilter === 'Ending Soon') {
      return dayjs(endDate).isBetween(Date(), dayjs().add(1, 'month'), 'day')
    }
    if (statusFilter && campaignStatusValues.includes(statusFilter)) {
      return status === statusFilter
    }
    return true
  }

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{buildPageTitle(statusFilter)}</span>
        </h3>
        <div className='card-toolbar'>
          <div className='card-toolbar'></div>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {isLoading ? (
            <div className='d-flex align-items-center justify-content-center'>
              <Spinner animation='grow'></Spinner>
            </div>
          ) : (
            <Table
              columns={columns}
              data={campaigns
                ?.filter(filter)
                .sort(defaultCampaignSort)
                .map((campaign) => ({
                  campaign: {
                    id: campaign.id,
                    name: campaign.name,
                  },
                  ...campaign,
                  startDate: dayjs(campaign.startDate).format('DD MMM,  YYYY'),
                  endDate: campaign.endDate ? dayjs(campaign.endDate).format('DD MMM,  YYYY') : '',
                }))}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const buildPageTitle = (status: string | null) => {
  switch (status) {
    case 'In Progress':
      return 'In Progress Campaigns'
    case 'Ending Soon':
      return 'Campaigns Ending Soon'
    case 'Paused':
      return 'Paused Campaigns'
    case 'Ended':
      return 'Ended Campaigns'
    default:
      return 'Campaigns'
  }
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'campaign.name',
    header: 'Name',
    cell: (info) => (
      <CellLink to={`/campaign/view/${info.row.original.campaign.id}`}>{info.getValue()}</CellLink>
    ),
  },
  {
    accessorKey: 'campaign.id',
    id: 'programId',
    header: 'Program ID',
    cell: (info) => (
      <Link
        to={`/campaign/view/${info.getValue()}`}
        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
      >
        {info.getValue()}
      </Link>
    ),
  },
  {
    accessorKey: 'client.name',
    id: 'client',
    header: 'Client',
    cell: (info) => (
      <CellLink to={`/client/view/${info.row.original.client.id}`}>{info.getValue()}</CellLink>
    ),
  },
  {
    accessorKey: 'startDate',
    header: 'Start Date',
    cell: (info: any) => info.getValue(),
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'endDate',
    header: 'End Date',
    cell: (info: any) => info.getValue(),
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: (info: any) => {
      return (
        <span
          className={info.getValue() ? 'badge badge-light-success' : 'badge badge-light-warning'}
        >
          {info.getValue()}
        </span>
      )
    },
    enableGlobalFilter: false,
  },
]
