import { useState } from 'react'
import BTable from 'react-bootstrap/Table'
import { FileManagerFile, FileManagerFolder, GetFolderResp } from '@ospace/schemas/file-manager'
import { ConfirmationModal, ConfirmationModalRenderButtonProps } from '@ospace/shared'
import clsx from 'clsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { useDeleteFileMutation, useDeleteFolderMutation } from '../hooks'

import './Table.scss'

dayjs.extend(relativeTime)

export type SortOption = 'date-desc' | 'alpha-asc'
type TableProps = {
  setFolderPath: (folderPath: string) => void
  setDropFolder: (folderPath: string) => void
  getFolderResp: GetFolderResp
  isCampaignManager?: boolean
  sortOption: SortOption
}
export const Table = (props: TableProps) => {
  let sortFn = sortDateDesc
  if (props.sortOption === 'alpha-asc') {
    sortFn = sortNameAsc
  }
  return (
    <BTable bordered className='flushed table-hover file-manager__Table'>
      <tbody>
        {/* folders always search by name */}
        {props.getFolderResp.folders.sort(sortNameAsc).map((folder) => (
          <FolderRow {...props} folder={folder} key={folder.folderPath} />
        ))}
        {/* files respond to sortOption */}
        {props.getFolderResp.files.sort(sortFn).map((file) => (
          <FileRow {...props} file={file} key={file.url} />
        ))}
      </tbody>
    </BTable>
  )
}

const FolderRow = (
  props: TableProps & {
    folder: FileManagerFolder
  }
) => {
  const [isOnDrag, setIsOnDrag] = useState(false)
  const deleteMutation = useDeleteFolderMutation({ campaignId: props.getFolderResp.campaignId })
  return (
    <tr
      key={props.folder.name}
      style={{
        opacity: deleteMutation.isLoading ? '0.5' : '1.0',
      }}
    >
      <td style={{ width: '2em' }}>
        <i className={clsx('bi', { 'bi-folder-fill': isOnDrag }, { 'bi-folder2': !isOnDrag })}></i>
      </td>
      <td style={{ width: '30em' }}>
        <button
          type='button'
          className={clsx('btn btn-link btn-sm', isOnDrag && 'drag-on')}
          onDrop={(e) => {
            e.preventDefault()
            setIsOnDrag(false)
            props.setDropFolder('')
          }}
          onDragEnter={(e) => {
            e.preventDefault()
            setIsOnDrag(true)
            props.setDropFolder(props.folder.folderPath)
          }}
          onDragLeave={(e) => {
            e.preventDefault()
            setIsOnDrag(false)
            props.setDropFolder('')
          }}
          onClick={(e) => {
            e.preventDefault()
            props.setFolderPath(props.folder.folderPath)
          }}
          style={{ padding: 0 }}
        >
          {props.folder.name}
        </button>
      </td>
      <td style={{ width: '15em' }} />
      {props.isCampaignManager ? (
        <td style={{ width: '3em' }}>
          <ConfirmationModal
            alertMessage={`Are you sure you want to delete the ${props.folder.name} folder? All of the contents will be deleted.`}
            action={() => {
              deleteMutation.mutateAsync({
                campaignId: props.getFolderResp.campaignId,
                folderPath: props.folder.folderPath,
              })
            }}
            renderButton={(rp) => <TrashButton {...rp} loading={deleteMutation.isLoading} />}
          />
        </td>
      ) : null}
    </tr>
  )
}

const FileRow = (
  props: TableProps & {
    file: FileManagerFile
  }
) => {
  const deleteMutation = useDeleteFileMutation({ campaignId: props.getFolderResp.campaignId })
  const lastModified = dayjs(props.file.lastModified)
  return (
    <tr
      key={props.file.name}
      style={{
        opacity: deleteMutation.isLoading ? '0.5' : '1.0',
      }}
      data-test-key='file-row'
    >
      <td style={{ width: '2em' }}>
        <i className='bi bi-file-earmark'></i>
      </td>
      <td style={{ width: '30em' }}>
        <a href={props.file.url}>{props.file.name}</a>
      </td>
      <td style={{ width: '15em' }}>{`${lastModified.fromNow(true)} ago, ${lastModified.format(
        'DD/MM/YYYY'
      )}`}</td>
      {props.isCampaignManager ? (
        <td style={{ width: '3em' }}>
          <ConfirmationModal
            alertMessage={`Are you sure you want to delete ${props.file.name}?`}
            action={() => {
              deleteMutation.mutateAsync({
                campaignId: props.getFolderResp.campaignId,
                folderPath: props.getFolderResp.folderPath,
                name: props.file.name,
              })
            }}
            renderButton={(rp) => <TrashButton {...rp} loading={deleteMutation.isLoading} />}
          />
        </td>
      ) : null}
    </tr>
  )
}

const TrashButton = (props: ConfirmationModalRenderButtonProps & { loading?: boolean }) => {
  const { loading, ...rp } = props
  return (
    <button
      {...rp}
      type='button'
      className={clsx('btn btn-link btn-sm', !loading && 'file-manager__Table--show-on-tr-hover')}
      style={{ padding: 0 }}
      disabled={loading}
      title='Delete item'
    >
      {loading ? (
        <span className='spinner-border spinner-border-sm' />
      ) : (
        <i className='bi bi-trash'></i>
      )}
    </button>
  )
}

const sortDateDesc = (a: FileManagerFile, b: FileManagerFile) => {
  if (a.lastModified < b.lastModified) return 1
  if (a.lastModified > b.lastModified) return -1
  return 0
}

const sortNameAsc = (
  a: FileManagerFile | FileManagerFolder,
  b: FileManagerFile | FileManagerFolder
) => {
  const aName = a.name.toLowerCase()
  const bName = b.name.toLowerCase()
  if (aName > bName) return 1
  if (aName < bName) return -1
  return 0
}
