import { Anchor, Container, createStyles, Group, Text } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  footer: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    margin: -12,
  },

  inner: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing.xl,
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}))

type Link = {
  href: string
  label: string
}

export const Footer = () => {
  const links: Link[] = [
    {
      label: 'About us',
      href: 'https://nextgen.group/ospace',
    },
    {
      label: 'Contact us',
      href: 'https://nextgen.group/contact-us',
    },
  ]
  const { classes } = useStyles()
  const items = links.map((link) => (
    <Anchor<'a'>
      color='gray'
      key={link.label}
      href={link.href}
      onClick={(event) => event.preventDefault()}
    >
      {link.label}
    </Anchor>
  ))

  return (
    <div className={classes.footer}>
      <Container className={classes.inner} fluid={true}>
        <Group>
          <Text color='dimmed'>{new Date().getFullYear()} &copy;</Text>
          <Anchor<'a'> color='dark' href='https://nextgen.group/ospace'>
            oSpace
          </Anchor>
        </Group>
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  )
}
