import { FC } from 'react'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'

import { CampaignForm } from '../components/create-campaign/CampaignForm'

const CreateCampaignPage: FC = () => {
  return (
    <>
      <PageTitle>Create Campaign</PageTitle>
      <CampaignForm type='create' />
    </>
  )
}

export { CreateCampaignPage }

export const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  return <CreateCampaignPage />
}

export const CreateCampaignPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
