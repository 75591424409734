import * as Yup from 'yup'

export const opportunitySchema = Yup.object({
  sysId: Yup.string().required(),
  oppNumber: Yup.string().required(),
  oppStatus: Yup.string().required(),
  oppType: Yup.string().required(),
  probability: Yup.string().required(),
  active: Yup.boolean().required(),
  programId: Yup.number().nullable(),
  programName: Yup.string().nullable(), // looked up from id in oSpace (do not use denomalized value from ServiceNow)
  startDate: Yup.string().required(),
  closeDate: Yup.string().nullable(),
  expiryDate: Yup.string().nullable(),
  buyPriceLocal: Yup.string().nullable(),
  // buyPriceFx is the buy price in the buyers currency (usually USD). The oSpace app should use the `calculatedBuyPriceLocal`.
  buyPriceFx: Yup.string().nullable(),
  partnerSourced: Yup.boolean().required(),
  partnerId: Yup.string().nullable(),
  partnerName: Yup.string().nullable(),
  partnerContactId: Yup.string().nullable(),
  partnerContactName: Yup.string().nullable(),
  primary: Yup.boolean().nullable(),
  endUserId: Yup.string().nullable(),
  endUserName: Yup.string().nullable(),
  assignedToId: Yup.string().nullable(),
  assignedToName: Yup.string().nullable(),
  vendorId: Yup.string().required(),
  vendorName: Yup.string().required(),
  vendorContactId: Yup.string().nullable(),
  vendorContactName: Yup.string().nullable(),
  updatedAt: Yup.string().required(),
  dealRegStatus: Yup.string().nullable(),
  oppName: Yup.string().nullable(),
  notes: Yup.string().nullable(),

  // calculatedBuyPriceLocal is a opsace specific projection. It is the
  // buyPriceFx if that is set, or falls back to an estimated price if it is
  // not set. It should be the default price used in the oSpace app.
  // Note, that the estimated prices are in local currency while the buyPriceFx
  // is in the Fx currency. This is considered acceptable, due to the estimate
  // being a ball-park anyway. See OP-1064.
  calculatedBuyPrice: Yup.string().required(),
})
export type Opportunity = Yup.InferType<typeof opportunitySchema>

// ServiceNowPaginatedResponse is a type we have defined for paginating
// responses from the service now API.
export const serviceNowPaginatedResponseSchema = Yup.object({
  count: Yup.number().min(0).nullable(), // only set when there is no offsetOppNumber.
  limit: Yup.number().min(1).required(),
  pageToken: Yup.string().nullable(),
  nextPageToken: Yup.string().nullable(), // pagination token, is the last oppNumber in response. Null if there is no next page.
  result: Yup.array(Yup.object().required()).required(),
})
export type ServiceNowPaginatedResponse = Yup.InferType<typeof serviceNowPaginatedResponseSchema>

export const listOpportunitiesRequestSchema = Yup.object({
  vendorId: Yup.string().required(), // a value of '' will return all opportunities for all vendors
  pageToken: Yup.string().nullable(),
  limit: Yup.number().min(1).required(),
  showNotes: Yup.boolean().required(),
})
export type ListOpportunitiesRequest = Yup.InferType<typeof listOpportunitiesRequestSchema>

export const listOpportunitiesResponseSchema = serviceNowPaginatedResponseSchema
  .omit(['result'])
  .concat(
    Yup.object({
      opportunities: Yup.array().of(opportunitySchema).required(),
    })
  )
export type ListOpportunitiesResponse = Yup.InferType<typeof listOpportunitiesResponseSchema>

// listAllOpportunitiesRequest is used to dump all opportunities into Snowy
export const listAllOpportunitiesRequestSchema = listOpportunitiesRequestSchema.omit(['vendorId'])
export type listAllOpportunitiesRequest = Yup.InferType<typeof listAllOpportunitiesRequestSchema>

export const filterSettingsSchema = Yup.object({
  dateRangeStart: Yup.string().nullable(),
  dateRangeEnd: Yup.string().nullable(),
  oppStatus: Yup.array().of(Yup.string()),
  oppType: Yup.array().of(Yup.string()),
  partnerName: Yup.array().of(Yup.string()),
  endUserName: Yup.array().of(Yup.string()),
  probability: Yup.array().of(Yup.string()),
})
export type FilterSettings = Yup.InferType<typeof filterSettingsSchema>
export const filterSettingsInit: FilterSettings = {
  dateRangeStart: null,
  dateRangeEnd: null,
  oppStatus: [],
  oppType: [],
  partnerName: [],
  endUserName: [],
  probability: [],
}

export const updateFilterSettingsRequestSchema = Yup.object({
  clientId: Yup.number().required(),
  userId: Yup.number().required(),
  filterSettings: filterSettingsSchema.required(),
})
export type UpdateFilterSettingsRequest = Yup.InferType<typeof updateFilterSettingsRequestSchema>

export const getFilterSettingsRequestSchema = Yup.object({
  clientId: Yup.number().required(),
  userId: Yup.number().required(),
})
export type GetFilterSettingsRequest = Yup.InferType<typeof getFilterSettingsRequestSchema>

export const getFilterSettingsResponseSchema = Yup.object({
  clientId: Yup.number().required(),
  userId: Yup.number().required(),
  filterSettings: filterSettingsSchema.required(),
})
export type GetFilterSettingsResponse = Yup.InferType<typeof getFilterSettingsResponseSchema>

export const serviceNowSecretSchema = Yup.object({
  username: Yup.string().required(),
  password: Yup.string().required(),
  host: Yup.string().required(), // e.g. 'https://nextgendev.servicenowapi.com'
})
export type ServiceNowSecret = Yup.InferType<typeof serviceNowSecretSchema>
