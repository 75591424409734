import { RingProgressProps } from '@mantine/core/lib/RingProgress/RingProgress'
import { Duration } from '@ospace/common'
import { Lead } from '@ospace/schemas'

import { CircularChart } from './CircularChart'
import { plotGraph } from './plotGraph'

interface Props extends Omit<RingProgressProps, 'sections'> {
  leads: Lead[]
  leadsCount: number
  duration: Duration
  color?: string
}
export const plotConvertedLeads = ({
  leads,
  leadsCount,
  duration,
  color = '#7546AA',
  ...rest
}: Props) => {
  const { count } = plotGraph(leads, 'opportunityDate', duration)

  return {
    title: 'CONVERTED LEADS',
    left: {
      label: 'Total leads',
      value: leadsCount,
    },
    right: {
      label: 'Converted leads',
      value: count,
    },
    tooltip:
      'The doughnut shows the percentage of opportunities generated in the selected time period and meetings booked in the selected time period.',
    color: 'hsl(32, 100%, 62%)',
    chart: CircularChart({
      value: count,
      percentage: Math.round((count / leadsCount) * 100) || 0,
      color,
      ...rest,
    }),
    data: [],
    chartLabels: {
      leftLabel: '',
      rightLabel: '',
    },
  }
}
