import { userTypes } from '@ospace/core-auth'
import dayjs from 'dayjs'

export const getRemotePublicURL = (key: string) => {
  return `https://${process.env.REACT_APP_BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${key}`
  //return await Storage.get(key)
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const sum = (array: any[], key: string) => {
  return array?.reduce((a: any, c: any) => a + c[key], 0) || 0
}

export const parseDate = (date: string | null | undefined, format: string | null | undefined) => {
  if (!date) return ''
  return dayjs(date, format || 'YYYY-MM-DD').format('YYYY-MM-DD')
}

export const sortDates = (
  a: string | undefined | null,
  b: string | undefined | null,
  format?: string | null | undefined
) => {
  const dateA = dayjs(a, format || 'DD-MM-YYYY')
  const dateB = dayjs(b, format || 'DD-MM-YYYY')

  if (!dateA.isValid() && !dateB.isValid()) return 0
  if (!dateA.isValid()) return 1
  if (!dateB.isValid()) return -1

  if (dateA.isSame(dateB)) return 0
  if (dateA.isBefore(dateB)) return -1
  return 1
}

export const isInternalUserByRole = (userRole: userTypes): boolean =>
  [
    userTypes.Admin,
    userTypes.ClientManager,
    userTypes.CampaignManager,
    userTypes.InternalViewer,
  ].includes(userRole)

export const userTypeOptions = [
  {
    value: 'ClientViewer',
    label: 'Client Viewer',
  },
  {
    value: 'ClientUser',
    label: 'Client User',
  },
  {
    value: 'CampaignUser',
    label: 'Campaign User',
  },
]

export const isInternalUser = (userType: string) =>
  userTypeOptions?.some((option) => option.value === userType)
