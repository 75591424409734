import { ReactElement, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { SpinnerButton } from './SpinnerButton'

export type ConfirmationModalRenderButtonProps = { onClick: () => void }
export type ConfirmationModalProps = {
  action: () => void
  alertMessage: string | ReactElement
  renderButton: (renderProps: ConfirmationModalRenderButtonProps) => ReactElement
  isLoading?: boolean
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { alertMessage, action, isLoading } = props
  const [modal, toggleModal] = useState(false)

  const onClickHanlder = () => {
    toggleModal(true)
  }

  const handleClose = () => {
    toggleModal(false)
  }

  const handleContinue = async () => {
    await action()
    toggleModal(false)
  }

  return (
    <>
      {alertMessage && (
        <Modal show={modal} onHide={handleClose} centered={true}>
          <Modal.Body>{alertMessage}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <SpinnerButton loading={!!isLoading} variant='primary' onClick={handleContinue}>
              Proceed
            </SpinnerButton>
          </Modal.Footer>
        </Modal>
      )}
      {props.renderButton({ onClick: onClickHanlder })}
    </>
  )
}
