import { Client } from '@ospace/schemas'

import { userTypes } from './constants'

type Opts = {
  userRole: userTypes
  client: Client
}

export const isClientTeamsAvailableToUserRole = (opts: Opts) => {
  // If showTeams is not set, the teams page is not available
  if (!opts.client?.showTeams) return false

  // if the vendorId is set, internal viewers can see the teams page
  if (opts.userRole === userTypes.Admin) return true
  if (opts.userRole === userTypes.ClientManager) return true
  if (opts.userRole === userTypes.InternalViewer) return true
  if (opts.userRole === userTypes.CampaignManager) return true

  // only ClientUser external users can see the teams page
  if (opts.userRole === userTypes.ClientUser || opts.userRole === userTypes.ClientViewer)
    return true
  return false
}
