import { useQuery } from 'react-query'

import { ContactService } from '../services'
interface ContactUploadStatus {
  terminated: boolean
  failedUploads: any[]
  importSize: number
  FAILED: number
  SUCCEEDED: number
  status: string
}

export const useContactUploadStatus = (importId: number, refetchInterval: number | false) => {
  return useQuery<ContactUploadStatus>(
    ['contacts-upload-status', importId],
    () => ContactService.getContactUploadStatus(importId),
    { refetchInterval, refetchIntervalInBackground: true }
  )
}
