import { Center, Divider, Grid, Group, Paper, SimpleGrid, Text } from '@mantine/core'
import { Duration } from '@ospace/common'
import { abbreviateNumber } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import { CurrencyText } from '@ospace/shared/components/CurrencyText'
import { MoreInfo } from '@ospace/shared/components/MoreInfo'

import { pipelineChartPlotter } from './pipelineChartPlotter'

const TextLabel = ({ title, value }: { title: string; value: string }) => (
  <div>
    <Text c='dimmed' fw={400} fz='12px' mt={10}>
      {title}
    </Text>
    <Group spacing={4} align='baseline'>
      <Text fw={700} fz='24px'>
        {value}
      </Text>
    </Group>
  </div>
)

interface Props {
  duration: Duration
  opportunities: Opportunity[]
  currency: string
}
export function PipelineChart({ currency, opportunities, duration }: Props) {
  const cards = pipelineChartPlotter({ opportunities, duration }).map((stat) => {
    return (
      <Paper withBorder p='lg' radius='md' key={stat.title}>
        <Grid>
          <Grid.Col span='auto'>
            <Paper p={5}>
              <Group position='left'>
                <Text c='dimmed' tt='uppercase' fw={600} fz='12px'>
                  {stat.title}
                </Text>
                <MoreInfo info={stat.infoText} />
              </Group>
              <Group position='apart' mb={35} c='gray.7'>
                <CurrencyText
                  title='Total value'
                  currency={currency}
                  value={abbreviateNumber(stat.totalValue)}
                />
                <TextLabel title='Total deals' value={`${stat.totalDeals}`} />
              </Group>
            </Paper>
          </Grid.Col>
          <Grid.Col span={1}>
            <Center h='100%' mx='auto'>
              <Divider size='sm' h='100%' orientation='vertical' />
            </Center>
          </Grid.Col>
          <Grid.Col span='auto'>
            <Paper>
              <Group mt={26} position='apart' c='gray.7'>
                <CurrencyText
                  title='Deal reg'
                  currency={currency}
                  value={abbreviateNumber(stat.dealReg)}
                />
                <TextLabel title='Approved deal reg' value={stat.dealRegCount.toString()} />
              </Group>
              <Text c='dimmed' fz='sm' mt='sm'>
                <Text component='span' c='teal' fw={600}>
                  {stat.percent}%
                </Text>{' '}
                of total
              </Text>
            </Paper>
          </Grid.Col>
        </Grid>
      </Paper>
    )
  })

  return (
    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      {cards}
    </SimpleGrid>
  )
}
