export enum Metrics {
  intents = 'Intent reports',
  companiesDiscovered = 'Companies discovered',
  contactsDiscovered = 'Contacts discovered',
  telesaleHours = 'Telesale hours',
  meetingBooked = 'Meetings booked',
  digitalProspectingMeetingBooked = 'Meetings booked',
  targetPipelineValue = 'Target pipeline',
  dealRegDone = 'Deal reg done',
  digitalLeads = 'Digital engagement',
  connectionRequestsSent = 'Connection requests sent',
  connectionRequestsAccepted = 'Connection requests accepted',
  impressions = 'Impressions',
  campaignClicks = 'Campaign clicks',
}

export enum UserStatus {
  'FORCE_CHANGE_PASSWORD' = 'INVITATION EMAIL SENT',
  'UNCONFIRMED' = 'USER CREATED BUT UNCONFIRMED',
  'CONFIRMED' = 'EMAIL VERIFIED',
  'EXTERNAL_PROVIDER' = 'SSO VERIFIED',
  'ARCHIVED' = 'NO LONGER ACTIVE',
  'UNKNOWN' = 'UNKNOWN',
  'RESET_REQUIRED' = 'USER CONFIRMED BUT RESET REQUIRED',
}

export const DEFAULT_CURRENCY = 'USD'
