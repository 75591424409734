import React from 'react'
import { useNavigate } from 'react-router'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { createUserSchema } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'

import { UserForm } from '../components/UserForm'
import { useCreateUserMutation } from '../hooks/useCreateUserMutation'

export const CreateUserPage: React.FC = () => {
  const navigate = useNavigate()
  const mutation = useCreateUserMutation()

  const initValues = {
    clients: [],
    campaigns: [],
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    userType: undefined,
    avatar: '',
    contactNumber: '',
    region: 'au',
  }

  return (
    <>
      <PageTitle>Create User</PageTitle>
      <div className='card'>
        <UserForm
          initialValues={{
            ...initValues,
            avatarFile: null as null | File,
          }}
          validationSchema={createUserSchema}
          isSubmitting={mutation.isLoading}
          onSubmit={async (values: any) => {
            await mutation.mutateAsync({
              ...values,
            })
            navigate(`/user`)
          }}
        />
      </div>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin])
  return <CreateUserPage />
}

export const CreateUserPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
