import { SalesInvoice } from '@ospace/schemas'
import { Opportunity } from '@ospace/schemas'

export const salesSegments = ({
  opportunities,
  sales,
}: {
  opportunities: Opportunity[]
  sales: SalesInvoice[]
}) => {
  // OP-1161: this used to be a .reduce(), and took ~1ms per record with 11000
  // records. Changing it to .forEach() reduced the time to ~0.0002ms per
  // record.
  const opportunitiesByOppNumber: { [key: string]: Opportunity } = {}
  opportunities.forEach((opportunity) => {
    opportunitiesByOppNumber[opportunity.oppNumber] = opportunity
  })

  let nextGen = {
    value: 0,
    deals: 0,
  }
  let partner = {
    value: 0,
    deals: 0,
  }
  let vendor = {
    value: 0,
    deals: 0,
  }
  let total = 0

  sales.forEach((sale) => {
    const price = +(sale.buyPriceFx || 0)
    const opportunity = sale.oppNumber ? opportunitiesByOppNumber[sale.oppNumber] : null
    if (opportunity?.programName) {
      nextGen.value += price
      if (sale.creditNote.toLowerCase() !== 'creditnote') {
        nextGen.deals++
      }
    } else if (opportunity?.partnerSourced) {
      partner.value += price
      if (sale.creditNote.toLowerCase() !== 'creditnote') {
        partner.deals++
      }
    } else {
      vendor.value += price
      if (sale.creditNote.toLowerCase() !== 'creditnote') {
        vendor.deals++
      }
    }
    total += price
  })

  return {
    total,
    nextGen,
    partner,
    vendor,
  }
}
