import { Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { ErrorBoundary } from '@ospace/shared'

import { Notes } from '../components/Notes'
import { usePotentialOpportunities } from '../hooks'

type PotentialOpportunitesBoxPageProps = { campaignId: number }

const PotentialOpportunitesPage = (props: PotentialOpportunitesBoxPageProps) => {
  const { isFetching, data: potentialOpportunites } = usePotentialOpportunities(props.campaignId)

  return (
    <CampaignPage>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <Row>
          <Col sm={12}>
            <div className={`card`}>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3'>Potential Opportunites</span>
                </h3>
              </div>
              <div className={`card-body`} style={{ overflow: 'scroll' }}>
                <Notes notes={potentialOpportunites} />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)

  return <PotentialOpportunitesPage campaignId={campaignId} />
}

export const PotentialOpportunitesPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
