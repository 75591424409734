import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { Card, Center, Loader, Text } from '@mantine/core'
import { useCheckRole, UserService } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { EditUserRequest, editUserSchema } from '@ospace/schemas'
import { Campaign, Client } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'

import { UserForm } from '../components/UserForm'
import { useUserMutation } from '../hooks/useUserMutation'

type Props = { userId: string }
const EditUserPage = (props: Props) => {
  const navigate = useNavigate()

  const {
    status,
    data: user,
    error,
    isFetching,
  } = useQuery(['user', props.userId], () => UserService.getUser(props.userId))
  const mutation = useUserMutation()

  const initValues: EditUserRequest = {
    firstName: user?.firstName as string,
    lastName: user?.lastName as string,
    userType: user?.type as string,
    jobTitle: user?.jobTitle,
    region: user?.region || 'au',
    email: user?.email as string,
    clients: user?.clients.map((client: Client) => client.id) as number[],
    campaigns: user?.campaigns.map((campaign: Campaign) => campaign.id),
    identityId: user?.identityId,
    oldUserType: user?.type,
    avatar: user?.avatar,
    contactNumber: user?.contactNumber,
    signedAvatarUrl: user?.signedAvatarUrl,
    enableProgramNotifications: user?.enableProgramNotifications as boolean,
    enableDashboardNotifications: user?.enableDashboardNotifications as boolean,
  }

  return (
    <>
      <PageTitle>Edit User</PageTitle>
      {isFetching ? (
        <Center>
          <Loader />
        </Center>
      ) : status === 'error' ? (
        <Text color={'red'}>Error: {(error as any).message}</Text>
      ) : (
        <Card>
          <UserForm
            userId={props.userId}
            initialValues={{
              ...initValues,
              avatarFile: null as null | File,
            }}
            validationSchema={editUserSchema}
            isSubmitting={mutation.isLoading}
            onSubmit={async (values: any) => {
              await mutation.mutateAsync({
                ...values,
                id: user?.id,
              })
              navigate(`/user`)
            }}
          />
        </Card>
      )}
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin])
  const params: any = useParams()
  const userId = params?.id

  return <EditUserPage userId={userId} />
}

export const EditUserPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
