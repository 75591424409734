import { createStyles, Grid, Paper, Text } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  notes: {
    border: '1px solid',
    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4],
    borderRadius: theme.radius.md,
    color: theme.colors.gray[8],
    fontSize: theme.fontSizes.md,
    padding: theme.spacing.md,
    marginTop: theme.spacing.md,
  },
  noteTitle: {
    color: theme.colors.gray[7],
    fontWeight: 'bold',
    fontSize: theme.fontSizes.md,
  },
}))

export const Notes = ({
  notes,
  showNotes,
}: {
  notes: string | undefined | null
  showNotes: boolean
}) => {
  const { classes } = useStyles()

  if (!showNotes || !notes) {
    return <></>
  }
  return (
    <Grid.Col span={12}>
      <Text className={classes.noteTitle}>Last Note</Text>
      <Paper className={classes.notes}>
        {notes?.split('\n').map((line, index) => (
          <Text key={index} color='dark'>
            {line}
          </Text>
        ))}
      </Paper>
    </Grid.Col>
  )
}
