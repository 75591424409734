import { useParams } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { CampaignPage } from '@ospace/campaign'
import { userTypes } from '@ospace/core-auth'
import { ErrorBoundary } from '@ospace/shared'

import { FileManager } from '../components/FileManager'

type FileManagerPageProps = {
  campaignId: number
  isCampaignManager: boolean
}
export const FileManagerPage = (props: FileManagerPageProps) => {
  return (
    <CampaignPage>
      <FileManager {...props} />
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)
  const userRole = useUserRole()

  return (
    <FileManagerPage
      campaignId={campaignId}
      isCampaignManager={userRole <= userTypes.CampaignManager}
    />
  )
}

export const FileManagerPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
