import * as Yup from 'yup'

export enum AccountType {
  NetNew = 'Net New',
  TargetAccount = 'Target Account',
}

export const intentReportConfigurationSchema = Yup.object({
  id: Yup.number().positive().required(),
  provider: Yup.string().required(),
  accountType: Yup.mixed<AccountType>().oneOf(Object.values(AccountType)).required(),
  configuration: Yup.object({
    reportId: Yup.string().required(),
  }).required(),
})
export type IntentReportConfiguration = Yup.InferType<typeof intentReportConfigurationSchema>

export const createIntentReportConfigurationRequestSchema = Yup.object({
  reportId: Yup.string().uuid('Report id must be a guid/uuid type').label('Report id'),
  accountType: Yup.string().required(),
})
export type CreateIntentReportConfigurationRequest = Yup.InferType<
  typeof createIntentReportConfigurationRequestSchema
>
