import { useMutation, useQueryClient } from 'react-query'
import { UserService } from '@ospace/auth'
import { CreateUserRequest } from '@ospace/schemas'
import { useNotificationContext } from '@ospace/shared'

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation((user: CreateUserRequest) => UserService.createUser(user), {
    onSuccess: (data) => {
      setNotification({
        active: true,
        message: ' You have successfully created the user.',
        type: 'success',
      })
      queryClient.invalidateQueries('users')
      queryClient.refetchQueries('users')
    },
    onError: (e: any) => {
      setNotification({
        active: true,
        message: e.message,
        type: 'danger',
      })
    },
  })
}
