import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Center, Grid, Loader, Skeleton } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { useClient } from '@ospace/client'
import { isClientTeamsAvailableToUserRole } from '@ospace/core-auth'
import { ClientPageLayout, SelectedPage } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'
import { useUser } from '@ospace/user'

import { MemberList } from '../components/MemberList'
import { useTeams } from '../hooks'

const SkeletonLoader = () => (
  <Grid>
    <Grid.Col span={4} bg={'#fff'}>
      <Skeleton height={200} radius='md' />
    </Grid.Col>
    <Grid.Col span={4} bg={'#fff'}>
      <Skeleton height={200} radius='md' />
    </Grid.Col>
    <Grid.Col span={4} bg={'#fff'}>
      <Skeleton height={200} radius='md' />
    </Grid.Col>
  </Grid>
)
export const MyTeamsPage = ({ clientId }: { clientId: number }) => {
  const userRole = useUserRole()
  const navigate = useNavigate()
  const { isLoading: isLoadingClient, data: client } = useClient(clientId)
  const { isLoading: isLoadingUser, data: user } = useUser()
  const { isLoading: isLoadingTeams, data: teamMembers } = useTeams(clientId)

  if (isLoadingClient || !client || isLoadingUser || !user) {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }

  if (!isClientTeamsAvailableToUserRole({ userRole, client })) {
    navigate('/error/403')
  }

  return (
    <ClientPageLayout
      status='ready'
      client={client}
      user={user}
      pageId={'my-teams'}
      role={userRole}
    >
      <>
        <SelectedPage>My Teams</SelectedPage>
        {isLoadingTeams || !teamMembers ? (
          <SkeletonLoader />
        ) : (
          <MemberList teamMembers={teamMembers} />
        )}
      </>
    </ClientPageLayout>
  )
}

const Controller = () => {
  const params = useParams()
  const clientId = Number(params?.clientId)

  return <MyTeamsPage clientId={clientId} />
}

export const MyTeamsPageHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
