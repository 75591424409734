import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Campaign, Objective, ObjectiveResult } from '@ospace/schemas'
import { Metrics } from '@ospace/shared'
import { ErrorMessage, Field, Form } from 'formik'

type CampaignObjectiveFieldProps = {
  title: string
  text: string
  fieldName: keyof Objective
  totalFieldName: keyof ObjectiveResult
  toggleFieldName: keyof Objective
  objective: Objective
  disabled?: boolean
}

const CampaignObjectiveField = (props: CampaignObjectiveFieldProps) =>
  props.objective[props.toggleFieldName] ? (
    <Row className='mb-10'>
      <Col>
        <span className='fw-bolder fs-6'>{props.title}</span>
        <br></br>
        {props.disabled ? <span className='text-warning'>Automated field</span> : <></>}
      </Col>
      <Col>
        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={props.fieldName}
          placeholder=''
          min={0}
          disabled={props.disabled || false}
        />
        <span>Number of {props.text} in specified time period</span>
        <div className='text-danger mt-2'>
          <ErrorMessage name={props.fieldName} />
        </div>
      </Col>
      <Col>
        <Field
          name={props.totalFieldName}
          type='number'
          className='form-control form-control-lg form-control-solid'
          min={0}
          placeholder=''
        />
        <span>Total {props.text} since start of campaign</span>
        <div className='text-danger mt-2'>
          <ErrorMessage name={props.totalFieldName} />
        </div>
      </Col>
    </Row>
  ) : null

const ObjectiveForm: React.FC<{
  mutation: any
  campaign: Campaign
  backLink: any
}> = (props) => {
  const { mutation, campaign, backLink } = props
  return (
    <Form className='form' noValidate>
      <div className='card-content'>
        <div className='card-body py-lg-10 px-lg-10'>
          <div className='row  mb-10'>
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Start date</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify start date of the campaign'
                ></i>
              </label>

              <Field
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='startDate'
                placeholder=''
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='startDate' />
              </div>
            </div>
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>End date</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify end date of the campaign'
                ></i>
              </label>

              <Field
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='endDate'
                placeholder=''
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='endDate' />
              </div>
            </div>
          </div>

          {!!campaign.objective?.isDataIntelligenceActive && (
            <div className='fv-row'>
              <h2 className='mt-20 mb-10'>Data and Intelligence</h2>
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.intents}
                text='intent reports'
                fieldName='intents'
                totalFieldName='totalIntents'
                toggleFieldName='isIntentsEnabled'
                disabled={false}
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.companiesDiscovered}
                text='companies discovered'
                fieldName='companiesDiscovered'
                totalFieldName='totalCompaniesDiscovered'
                toggleFieldName='isCompaniesDiscoveredEnabled'
                disabled={false}
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.contactsDiscovered}
                text='contacts discovered'
                fieldName='contactsDiscovered'
                totalFieldName='totalContactsDiscovered'
                toggleFieldName='isContactsDiscoveredEnabled'
                disabled={false}
              />
            </div>
          )}

          {!!campaign.objective?.isTeleProspectingActive && (
            <div className='fv-row'>
              <h2 className='mt-20 mb-10'>Tele Prospecting</h2>
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.telesaleHours}
                text='telesales hours'
                fieldName='telesaleHours'
                totalFieldName='totalTelesaleHours'
                toggleFieldName='isTelesaleHoursEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.meetingBooked}
                text='meetings booked'
                fieldName='meetingBooked'
                totalFieldName='totalMeetingBooked'
                toggleFieldName='isMeetingBookedEnabled'
                disabled={false}
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.targetPipelineValue}
                text='target pipeline value'
                fieldName='targetPipelineValue'
                totalFieldName='totalTargetPipelineValue'
                toggleFieldName='isTargetPipelineValueEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.dealRegDone}
                text='deal reg done'
                fieldName='dealRegDone'
                totalFieldName='totalDealRegDone'
                toggleFieldName='isDealRegDoneEnabled'
              />
            </div>
          )}

          {!!campaign.objective?.isDigitalProspectingActive && (
            <div className='fv-row'>
              <h2 className='mt-20 mb-10'>Digital Prospecting</h2>
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.digitalLeads}
                text='digital engagement'
                fieldName='digitalLeads'
                totalFieldName='totalDigitalLeads'
                toggleFieldName='isDigitalLeadsEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.digitalProspectingMeetingBooked}
                text='meetings booked'
                fieldName='digitalProspectingMeetingBooked'
                totalFieldName='totalDigitalProspectingMeetingBooked'
                toggleFieldName='isDigitalProspectingMeetingBookedEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.connectionRequestsSent}
                text='connection requests sent'
                fieldName='connectionRequestsSent'
                totalFieldName='totalConnectionRequestsSent'
                toggleFieldName='isConnectionRequestsSentEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.connectionRequestsAccepted}
                text='connection requests accepted'
                fieldName='connectionRequestsAccepted'
                totalFieldName='totalConnectionRequestsAccepted'
                toggleFieldName='isConnectionRequestsSentEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.impressions}
                text='impressions'
                fieldName='impressions'
                totalFieldName='totalImpressions'
                toggleFieldName='isImpressionsEnabled'
              />
              <CampaignObjectiveField
                objective={campaign.objective}
                title={Metrics.campaignClicks}
                text='campaign clicks'
                fieldName='campaignClicks'
                totalFieldName='totalCampaignClicks'
                toggleFieldName='isCampaignClicksEnabled'
              />
            </div>
          )}
          <button type='submit' className='btn btn-primary' disabled={mutation.isLoading}>
            {!!mutation.isLoading && (
              <span className='spinner-border spinner-border-sm align-middle mx-2' />
            )}
            Submit
          </button>
          <Link to={backLink} className='btn btn-secondary m-10'>
            Cancel
          </Link>
        </div>
      </div>
    </Form>
  )
}

export { ObjectiveForm }
