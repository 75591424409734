import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { PasswordField, useNotificationContext } from '@ospace/shared'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AuthLayout } from '../components/AuthLayout'
import { UserService } from '../services/user'

const confirmSchema = Yup.object().shape({
  tempPassword: Yup.string().required('Temp Password is required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 letters')
    .max(50, 'Maximum 50 letters')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Minimum 8 letters')
    .max(50, 'Maximum 50 letters')
    .required('Confirm Password is required'),
})

const initialValues = {
  tempPassword: '',
  newPassword: '',
  confirmPassword: '',
}

export function ConfirmPage() {
  const params: any = useParams()
  const navigate = useNavigate()
  const { setNotification } = useNotificationContext()
  const mutation = useMutation(
    (values: any) => {
      return UserService.confirmUser({
        username: params?.username,
        tempPassword: values.tempPassword,
        newPassword: values.newPassword,
      })
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'Your password has been sucessfully changed.',
          type: 'success',
        })
        navigate('/auth/login')
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  const onSubmit = async (values: any) => {
    await mutation.mutateAsync(values)
  }

  const validateConfirmPassword = (pass: any, value: any) => {
    let error = ''
    if (pass && value) {
      if (pass !== value) {
        error = 'Password not matched'
      }
    }
    return error
  }

  if (!params.username) {
    return (
      <AuthLayout>
        <p className='text-center text-danger'>Username not provided.</p>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <Formik validationSchema={confirmSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => (
          <Form className='form w-100'>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Confirm account</h1>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Temp Password</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify the temporary password provided'
                ></i>
              </label>

              <Field
                type='password'
                component={PasswordField}
                className='form-control form-control-lg form-control-solid'
                name='tempPassword'
                placeholder=''
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='tempPassword' />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>New Password</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique new password'
                ></i>
              </label>

              <Field
                type='password'
                component={PasswordField}
                className='form-control form-control-lg form-control-solid'
                name='newPassword'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='newPassword' />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Confirm Password</span>
              </label>

              <Field
                type='password'
                component={PasswordField}
                className='form-control form-control-lg form-control-solid'
                name='confirmPassword'
                validate={(value: any) => validateConfirmPassword(values.newPassword, value)}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='confirmPassword' />
              </div>
            </div>

            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-lg btn-primary me-3'
                disabled={mutation.isLoading}
              >
                {mutation.isLoading && (
                  <span className='spinner-border spinner-border-sm align-middle mx-2' />
                )}
                <span className='indicator-label'>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}
