import * as Yup from 'yup'

export const memberSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  contactNumber: Yup.string().required(),
  role: Yup.string().required(),
  signedAvatarUrl: Yup.string().required(),
})

export type Member = Yup.InferType<typeof memberSchema>
