import { Opportunity } from '@ospace/schemas'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'

import { Duration } from './duration'

dayjs.extend(customParseFormat)
dayjs.extend(isBetween)

export const dateFilterByDates = ({
  date,
  duration,
  format = 'DD-MM-YYYY',
}: {
  date: string
  duration: Duration
  format?: string
}) => dayjs(date, format).isBetween(duration.dates.start, duration.dates.end, 'day', '[]')

export const dateFilter = ({
  date,
  duration,
  format = 'DD-MM-YYYY',
}: {
  date: string
  duration: Duration
  format?: string
}) =>
  dayjs(date, format).isBetween(
    duration.segmentsDuration.start,
    duration.segmentsDuration.end,
    'day',
    '[]'
  )

export const opportunityFilter = ({ primary, oppStatus }: Opportunity) =>
  primary && oppStatus === 'Ongoing'
