import { Duration } from '@ospace/common'
import { dateFilter, opportunityFilter } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'

export const pipelineRatioHelper = ({
  opportunities,
  duration,
}: {
  opportunities: Opportunity[]
  duration: Duration
}) => {
  let nextGen = {
    value: 0,
    deals: 0,
  }
  let partner = {
    value: 0,
    deals: 0,
  }
  let vendor = {
    value: 0,
    deals: 0,
  }
  let total = 0

  opportunities
    .filter(
      (opportunity) =>
        opportunityFilter(opportunity) &&
        dateFilter({ date: opportunity.closeDate || '', duration })
    )
    .forEach((opportunity) => {
      const price = +(opportunity.calculatedBuyPrice || 0)

      if (opportunity.programId) {
        nextGen.value += price
        nextGen.deals++
      } else if (opportunity.partnerSourced) {
        partner.value += price
        partner.deals++
      } else {
        vendor.value += price
        vendor.deals++
      }
      total += price
    })

  return {
    total,
    nextGen,
    partner,
    vendor,
  }
}
