import { useLocation, useNavigate } from 'react-router-dom'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'

import { ContactTable } from '../components/ContactTable'

export const ContactUploadFailedPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const contacts = location?.state?.contacts.map((contact: any) => ({
    ...contact,
    validationErrors: [],
    warnings: [],
    hasDuplicate: false,
  }))

  return (
    <>
      <PageTitle>Failed Hubspot Contact Upload</PageTitle>
      <div className='card'>
        <div className={`card`}>
          <ContactTable contacts={contacts} title={`Failed contact upload (${contacts.length})`} />

          <div className='card-footer'>
            <button type='submit' className='btn btn-primary' onClick={() => navigate(-1)}>
              Go back
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  return <ContactUploadFailedPage />
}

export const ContactUploadFailedRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
