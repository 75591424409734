import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ActionIcon,
  Anchor,
  Avatar,
  Container,
  createStyles,
  Divider,
  Group,
  Image,
  Menu,
  Paper,
  rem,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { Drawer } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { User } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitleBreadcrumb } from '@ospace/layout'
import { Client } from '@ospace/schemas'
import { MoreInfo } from '@ospace/shared'
import {
  IconChevronDown,
  IconExternalLink,
  IconHelpCircle,
  IconSettings,
  IconUser,
  IconUsers,
} from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    borderBottom: `${rem(2)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[2]
    }`,
  },

  mainSection: {
    padding: `${theme.spacing.xs} ${rem(40)}`,
    borderBottom: `${rem(2)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[2]
    }`,
    fontSize: theme.fontSizes.md,
  },

  subHeader: {
    padding: `${theme.spacing.xs} ${rem(40)}`,
  },

  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',
    fontSize: theme.fontSizes.md,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },
  },

  userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },

  userLink: {
    fontSize: theme.fontSizes.md,
  },

  helpLink: {
    '&:hover': {
      backgroundColor: '#f1f2f4',
    },
  },
}))

interface HeaderProps {
  user?: User
  role: userTypes
  subHeader?: ReactElement
  client?: Client
  pageTitle?: string
}
export const CustomHeader = (props: HeaderProps) => {
  const { user, client, subHeader, role } = props
  const { classes, cx } = useStyles()
  const [userMenuOpened, setUserMenuOpened] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <div className={classes.header}>
      <Drawer
        position='right'
        opened={opened}
        onClose={close}
        size='28%'
        title={
          <Group position='apart'>
            <Text c='dimmed'>Help</Text>
            <MoreInfo info='Help' />
          </Group>
        }
        overlayProps={{ opacity: 0.5, blur: 2 }}
        styles={{
          inner: { marginTop: 60 },
          content: { paddingLeft: 20, paddingRight: 20 },
        }}
      >
        <HelpDrawerContent />
      </Drawer>
      <Container fluid={true} className={classes.mainSection}>
        <Group position='apart'>
          <Group position='left'>
            {client ? (
              <>
                <Image src={client.signedLogoUrl} width='auto' height={55} />
                <Text role='heading' size='xl' fw='bold'>
                  {client.name} {props.pageTitle ? `- ${props.pageTitle}` : ''}
                </Text>
              </>
            ) : (
              <>
                <Link to='/' aria-label='home'>
                  <Image src={'/media/logos/oSpace.svg'} width={200} height={40} />
                </Link>
                <Text role='heading' size='xl' fw='bold'>
                  {props.pageTitle ? props.pageTitle : ''}
                </Text>
              </>
            )}
          </Group>

          <Menu
            width={260}
            position='bottom-end'
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
          >
            <Group>
              <ActionIcon variant='transparent' onClick={open} aria-label='Open Help'>
                <IconHelpCircle onClick={open} />
              </ActionIcon>
              {role === userTypes.Admin ? <AdminMenu /> : null}
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                  <Group spacing={7}>
                    <Avatar src={user?.signedAvatarUrl} radius='sm' size={40} color='dark'>
                      {`${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`}
                    </Avatar>
                    <IconChevronDown size={rem(14)} stroke={1.5} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
            </Group>
            <Menu.Dropdown>
              <Menu.Item>
                <Link to='/user/edit' className={classes.userLink}>
                  <Text color='dark'>Edit profile</Text>
                </Link>
              </Menu.Item>
              <Menu.Item className={classes.userLink}>
                <Link to='/logout' className={classes.userLink}>
                  <Text color='red'>Logout</Text>
                </Link>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Container>
      {subHeader && (
        <Container fluid={true} className={classes.subHeader}>
          {subHeader}
          <PageTitleBreadcrumb />
        </Container>
      )}
    </div>
  )
}

const HelpLink = ({
  href,
  children,
  showDivider = true,
}: {
  children: React.ReactNode
  href: string
  showDivider?: boolean
}) => {
  const { classes } = useStyles()
  return (
    <Anchor href={href} target='_blank' rel='noopener noreferrer' className={classes.helpLink}>
      <Group position='apart' py='xs'>
        <Text fw={700}>{children}</Text>
        <ActionIcon variant='transparent'>
          <IconExternalLink />
        </ActionIcon>
      </Group>
      {showDivider && <Divider size='sm' />}
    </Anchor>
  )
}

const HelpDrawerContent = () => {
  return (
    <Paper c='#464E5F' mt='md'>
      <Text fw={700}>How do I see my Pipeline?</Text>
      <Text>Navigate through the left hand side menu, select Pipeline</Text>

      <Text fw={700} mt='md'>
        Where does the data for the Ledas, Pipeline, and Sales dashboard come from?
      </Text>
      <Text>The data references data from NEXTGEN internal systems.</Text>

      <Text fw={700} mt='md'>
        How do I select the time period on the Leads, Pipeline, and Sales dashboards?
      </Text>
      <Text>
        Select the date range or by predefined period, month, quarter, financial year or custom date
        by selecting the date range desired.
      </Text>

      <Text fw={700} mt='md'>
        How do I view my vendor management team within the platform?
      </Text>
      <Text mb='xl'>
        {' '}
        If this is enabled, click the "My Team" link on the bottom left hand side of the
      </Text>
      <HelpLink href='https://app.teamwalnut.com/player/?demoId=3c91a54b-8709-4106-b2e2-219f823d69f4&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'>
        oSpace Platform guided walkthrough
      </HelpLink>
    </Paper>
  )
}

const AdminMenu = () => {
  const { classes } = useStyles()

  return (
    <Menu
      width={260}
      position='bottom-end'
      transitionProps={{ transition: 'pop-top-right' }}
      withinPortal
      aria-label='Admin Menu'
    >
      <Menu.Target>
        <ActionIcon size={32} variant='default' color='#464E5F' style={{ border: 0 }}>
          <IconSettings />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item>
          <Link to='/user' className={classes.userLink}>
            <Group>
              <IconUser color={'#464E5F'} />
              <Text color={'dark'}>User Management</Text>
            </Group>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to='/client/create' className={classes.userLink}>
            <Group>
              <IconUsers color={'#464E5F'} />
              <Text color={'dark'}>Create Client</Text>
            </Group>
          </Link>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
