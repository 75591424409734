import * as Yup from 'yup'

import { clientSchema } from '../client'
import { objectiveSchema } from '../objective'

import { icpSchema, upsertICPRequestSchema } from './icp'
import {
  createIntentReportConfigurationRequestSchema,
  intentReportConfigurationSchema,
} from './intentReportConfiguration'

export enum CampaignStatus {
  IN_PROGRESS = 'In Progress',
  ENDED = 'Ended',
  PAUSED = 'Paused',
}

export enum CampaignCrm {
  HUBSPOT = 'Hubspot',
  FIVECRM = 'FiveCRM',
}

const productSchema = Yup.object({
  id: Yup.string().required(),
  productLines: Yup.array().of(Yup.string().required()).required(),
  name: Yup.string().required(),
})

export const campaignSchema = Yup.object({
  id: Yup.number().positive().required(),
  status: Yup.mixed<CampaignStatus>().oneOf(Object.values(CampaignStatus)).required(),
  name: Yup.string().required().label('Campaign name'),
  crm: Yup.mixed<CampaignCrm>().oneOf(Object.values(CampaignCrm)).required(),
  description: Yup.string().nullable().label('Campaign description'),
  startDate: Yup.string().required().label('Start date'),
  endDate: Yup.string().nullable().label('End date'),
  intentReportConfigurations: Yup.array(intentReportConfigurationSchema).required(),
  lookerClientId: Yup.string().nullable().label('Looker client id'),
  client: clientSchema.required(), // TODO: define in client and import
  icps: Yup.array().of(icpSchema).required(),
  objective: objectiveSchema.required(),
  headerMeta: Yup.object({
    meetingsBooked: Yup.number().positive(),
  }),
  products: Yup.array().of(productSchema).required(),
})
export type Campaign = Yup.InferType<typeof campaignSchema>

export const createCampaignRequestSchema = campaignSchema
  .pick(['name', 'description', 'startDate', 'endDate', 'crm'])
  .concat(
    Yup.object({
      clientId: Yup.number().positive().required(),
      lookerClientId: Yup.string().nullable().label('Looker client id'),
      isIntentsEnabled: Yup.bool().required(),
      isCompaniesDiscoveredEnabled: Yup.bool().required(),
      isContactsDiscoveredEnabled: Yup.bool().required(),
      isTelesaleHoursEnabled: Yup.bool().required(),
      isMeetingBookedEnabled: Yup.bool().required(),
      isTargetPipelineValueEnabled: Yup.bool().required(),
      isDealRegDoneEnabled: Yup.bool().required(),
      isDigitalLeadsEnabled: Yup.bool().required(),
      isDigitalProspectingMeetingBookedEnabled: Yup.bool().required(),
      isConnectionRequestsSentEnabled: Yup.bool().required(),
      isConnectionRequestsAcceptedEnabled: Yup.bool().required(),
      isImpressionsEnabled: Yup.bool().required(),
      isCampaignClicksEnabled: Yup.bool().required(),
      intents: Yup.number().when('isDataIntelligenceActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Intents'),
      }),
      companiesDiscovered: Yup.number().when('isDataIntelligenceActive', {
        is: true,
        then: () =>
          Yup.number().positive().required().min(0).integer().label('Companies discovered'),
      }),
      contactsDiscovered: Yup.number().when('isDataIntelligenceActive', {
        is: true,
        then: () =>
          Yup.number().positive().required().min(0).integer().label('Contacts discovered'),
      }),
      telesaleHours: Yup.number().when('isTeleProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Telesales hours'),
      }),
      meetingBooked: Yup.number().when('isTeleProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Meetings Booked'),
      }),
      digitalProspectingMeetingBooked: Yup.number().when('isDigitalProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Meetings Booked'),
      }),
      targetPipelineValue: Yup.number().when('isTeleProspectingActive', {
        is: true,
        then: () =>
          Yup.number().positive().required().min(0).integer().label('Target pipeline value'),
      }),
      dealRegDone: Yup.number().when('isTeleProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Deal reg done'),
      }),
      digitalLeads: Yup.number().when('isDigitalProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Digital leads'),
      }),
      connectionRequestsSent: Yup.number().when('isDigitalProspectingActive', {
        is: true,
        then: () =>
          Yup.number().positive().required().min(0).integer().label('Connection requests sent'),
      }),
      connectionRequestsAccepted: Yup.number().when('isDigitalProspectingActive', {
        is: true,
        then: () =>
          Yup.number().positive().required().min(0).integer().label('Connection requests accepted'),
      }),
      impressions: Yup.number().when('isDigitalProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Impression'),
      }),
      campaignClicks: Yup.number().when('isDigitalProspectingActive', {
        is: true,
        then: () => Yup.number().positive().required().min(0).integer().label('Campaign clicks'),
      }),
      isDataIntelligenceActive: Yup.boolean().required(),
      isTeleProspectingActive: Yup.boolean().required(),
      isDigitalProspectingActive: Yup.boolean().required(),
      icps: Yup.array().of(upsertICPRequestSchema).required(),
      products: Yup.array().of(productSchema).required(),
      intentReportConfigurations: Yup.array()
        .of(createIntentReportConfigurationRequestSchema)
        .test('unique', 'Report id must be unique', (values: any) => {
          const uuids = values.map((value: { reportId: string }) => value.reportId)
          return new Set(uuids).size === values.length
        }),
    })
  )
export type CreateCampaignRequest = Yup.InferType<typeof createCampaignRequestSchema>

export const updateCampaignRequestSchema = createCampaignRequestSchema.concat(
  campaignSchema.pick(['id', 'status'])
)
export type UpdateCampaignRequest = Yup.InferType<typeof updateCampaignRequestSchema>
