import { ErrorMessage, Field } from 'formik'

interface Props {
  fieldName: string
  label: string
  tooltip?: string
  required?: boolean
  type?: 'text' | 'date' | 'number'
  labelAlignment?: 'left' | 'top'
  labelStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  disabled?: boolean
}

export const Input = ({
  label,
  tooltip,
  required,
  fieldName,
  type = 'text',
  labelAlignment = 'top',
  labelStyle,
  inputStyle,
  disabled = false,
}: Props) => {
  return (
    <>
      {labelAlignment === 'top' && (
        <>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span style={labelStyle} className={required ? 'required' : ''}>
              {label}
            </span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={tooltip}
            ></i>
          </label>
          <Field
            style={inputStyle}
            aria-label={label}
            type={type}
            className='form-control form-control-lg form-control-solid'
            name={fieldName}
            placeholder=''
          />
        </>
      )}

      {labelAlignment === 'left' && (
        <>
          <label className='d-flex flex-stack cursor-pointer'>
            <span style={labelStyle} className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>{label}</span>
              </span>
            </span>
            <span style={inputStyle}>
              <Field
                style={disabled ? { color: 'white' } : {}}
                disabled={disabled}
                aria-label={label}
                type='number'
                className='form-control form-control-lg form-control-solid'
                name={fieldName}
                placeholder=''
              />
            </span>
          </label>
        </>
      )}
      <div className='text-danger mt-2'>
        <ErrorMessage name={fieldName} />
      </div>
    </>
  )
}
