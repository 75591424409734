import * as React from 'react'
import { Badge, Flex, Progress } from '@mantine/core'
import { Campaign } from '@ospace/schemas'
import dayjs from 'dayjs'

const programStatusColour = (status: string | null) => {
  switch (status) {
    case 'In Progress':
      return 'green'
    case 'Ending Soon':
      return 'yellow'
    case 'Paused':
      return 'dark'
    case 'Ended':
      return 'red'
    default:
      return 'gray'
  }
}

export default function ProgramHeader({ campaign }: { campaign: Campaign }) {
  const totalProgramDate = dayjs(campaign?.endDate).diff(dayjs(campaign?.startDate), 'day')
  const passedProgramDate = dayjs().diff(dayjs(campaign?.startDate), 'day')
  const progress =
    (passedProgramDate / totalProgramDate) * 100 ||
    // default progress to 5% if totalProgramDate is 0 or NaN
    // is this to show some progress in the UI
    5

  return (
    <Flex gap='md' align='center' justify='space-between'>
      <Flex align='center' gap='md' justify='start' style={{ flexGrow: 4 }}>
        <span>Start date: {dayjs(campaign?.startDate).format('DD/MM/YYYY')}</span>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Progress value={progress} />
        </div>
      </Flex>
      {dayjs(campaign?.endDate).isValid() && (
        <span style={{ flexGrow: 1 }}>
          End date: {dayjs(campaign?.endDate).format('DD/MM/YYYY')}
        </span>
      )}
      <span style={{ flexGrow: 1 }}>Program ID: {campaign?.id}</span>
      <span>
        Status: <Badge color={programStatusColour(campaign?.status)}>{campaign?.status}</Badge>
      </span>
    </Flex>
  )
}
