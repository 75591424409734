import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useCheckRole } from '@ospace/auth'
import { useCampaign } from '@ospace/campaign'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { createObjectiveResultSchema, ObjectiveResult } from '@ospace/schemas/objective'
import { useNotificationContext } from '@ospace/shared'
import { Formik } from 'formik'

import { inits } from '../components/CreateObjectiveHelper'
import { ObjectiveForm } from '../components/ObjectiveForm'
import { ObjectiveService } from '../services'

const CreateObjectiveResultPage: React.FC = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  const queryClient = useQueryClient()
  const params: any = useParams()
  const navigate = useNavigate()
  const { setNotification } = useNotificationContext()

  const campaignId = Number(params?.id)
  const { status, data: campaign, error } = useCampaign(campaignId)
  const mutation = useMutation(
    (objective: ObjectiveResult) => {
      const objectResultDTO = {
        ...objective,
        objective: campaign?.objective?.id,
        campaign: {
          name: campaign?.name,
          crm: campaign?.crm,
          id: campaign?.id,
        },
        client: {
          id: campaign?.client?.id,
          name: campaign?.client?.name,
        },
      }
      return ObjectiveService.createObjectiveResult(objectResultDTO)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'You have successfully updated the campaign.',
          type: 'success',
        })
        queryClient.invalidateQueries('campaigns')
        queryClient.invalidateQueries('campaign')
      },
    }
  )

  return (
    <>
      <PageTitle>Update Campaign Performance</PageTitle>

      {status === 'loading' || status === 'idle' ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : status === 'error' ? (
        <span>Error: {(error as any).message}</span>
      ) : (
        <>
          <div className='card'>
            <Formik
              initialValues={inits}
              validationSchema={createObjectiveResultSchema}
              onSubmit={async (values, actions) => {
                await mutation.mutateAsync(values as any)
                actions.resetForm()
                navigate(-1)
              }}
            >
              {() => (
                <ObjectiveForm
                  mutation={mutation}
                  campaign={campaign}
                  backLink={`/campaign/view/${campaign?.id}`}
                />
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  )
}

export { CreateObjectiveResultPage }
