import * as AmplifyAPI from 'aws-amplify/api'
import * as AmplifyAuth from 'aws-amplify/auth'

type APIoptions = {
  body?: any
  queryStringParameters?: any
}

const APIMod = () => {
  const customHeader = async () => {
    return {
      Authorization: `Bearer ${(await AmplifyAuth.fetchAuthSession()).tokens?.idToken?.toString()}`,
    }
  }

  const get = async (apiName: string, path: string, options?: APIoptions) => {
    const { body } = await AmplifyAPI.get({
      apiName,
      path,
      options: {
        ...options,
        headers: await customHeader(),
        queryParams: options?.queryStringParameters,
      },
    }).response

    return (await body.json()) as any
  }

  const post = async (apiName: string, path: string, options?: APIoptions) => {
    const response = await AmplifyAPI.post({
      apiName,
      path,
      options: {
        ...options,
        headers: await customHeader(),
        queryParams: options?.queryStringParameters,
      },
    }).response

    return (await response.body.json()) as any
  }

  const put = async (apiName: string, path: string, options?: APIoptions) => {
    const response = await AmplifyAPI.put({
      apiName,
      path,
      options: {
        ...options,
        headers: await customHeader(),
        queryParams: options?.queryStringParameters,
      },
    }).response

    return (await response.body.json()) as any
  }

  const del = async (apiName: string, path: string, options?: APIoptions) => {
    const response = await AmplifyAPI.del({
      apiName,
      path,
      options: {
        ...options,
        headers: await customHeader(),
        queryParams: options?.queryStringParameters,
      },
    }).response

    return response
  }

  const patch = async (apiName: string, path: string, options?: APIoptions) => {
    const response = await AmplifyAPI.patch({
      apiName,
      path,
      options: {
        ...options,
        headers: await customHeader(),
        queryParams: options?.queryStringParameters,
      },
    }).response

    return (await response.body.json()) as any
  }

  return {
    get,
    post,
    put,
    del,
    patch,
  }
}

export const API = APIMod()
