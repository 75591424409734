import React from 'react'
import { Select } from '@mantine/core'

export const REGION_OPTIONS = [
  {
    label: '🇦🇺  AUS',
    value: 'au',
  },
  {
    label: '🇺🇸  USA',
    value: 'us',
  },
]

export const RegionSelect = ({
  inputProps,
  onChange,
}: {
  inputProps: {
    value: any
    onChange: any
    checked?: any
    error?: any
    onFocus?: any
    onBlur?: any
  }
  onChange: (value: string) => void
}) => {
  return (
    <Select
      mb='md'
      size='md'
      name='region'
      withinPortal
      {...inputProps}
      data={REGION_OPTIONS}
      placeholder='Region'
      label='Region'
      onChange={onChange}
      withAsterisk
    />
  )
}
