import { useQuery } from 'react-query'
import { API } from '@ospace/shared'

const getTeams = async (clientId: number) => {
  const teams = await API.get('client', `/clients/${clientId}/teams`, {})

  return teams
}

export const useTeams = (clientId: number) => {
  return useQuery(['teamMembers', clientId], () => getTeams(clientId))
}
