import React from 'react'
import { Group, Text } from '@mantine/core'

export const CurrencyText = ({
  title,
  value,
  currency,
  currencyColor,
  ...props
}: {
  title?: string
  value: string | number
  currency: string
  currencyColor?: string
} & React.HTMLProps<HTMLDivElement>) => (
  <div {...props}>
    {title && (
      <Text c='dimmed' fw={400} fz='12px' mt={10}>
        {title}
      </Text>
    )}
    {value.toString() !== '' && (
      <Group spacing={6} align='baseline'>
        <Text c={currencyColor || 'dimmed'} fw={700} fz='14px'>
          {currency}
        </Text>
        <Text fw={700} fz='24px'>
          {value}
        </Text>
      </Group>
    )}
  </div>
)
