import { Group, Text } from '@mantine/core'
import {
  abbreviateNumber,
  currencyFormatter,
  dateFilter,
  Duration,
  salesSegments,
} from '@ospace/common'
import { groupByOrderNumber } from '@ospace/common'
import { getTopBy } from '@ospace/sales-dashboard'
import { Opportunity, SalesInvoice } from '@ospace/schemas'
import { MoreInfo, SalesWidgetsHelper } from '@ospace/shared'
import { CurrencyText } from '@ospace/shared/components/CurrencyText'

import { BaseCard } from '../BaseCard'
import { CircularRatioChart } from '../CircularRatioChart'
import { SideBySideFigures } from '../SideBySideFigures'
import { TwoColumnTable } from '../TwoColumnTable'

import { TopCard } from './TopCard'

const COLORS = {
  NEXTGEN_INITIATED: '#0CA678',
  PARTNER_INITIATED: '#34D3A4',
  VENDOR_INITIATED: '#6DF6CE',
}
export const SalesCard = ({
  sales: _sales,
  opportunities,
  currency,
  duration,
}: {
  sales: SalesInvoice[]
  currency: string
  opportunities: Opportunity[]
  duration: Duration
}) => {
  const sales = groupByOrderNumber(
    (_sales || []).filter(({ invoiceDate }) =>
      dateFilter({ date: invoiceDate || '', duration, format: 'YYYY-MM-DD' })
    ) as SalesInvoice[]
  )

  const segmentData = salesSegments({ sales, opportunities })
  const totals = SalesWidgetsHelper({ sales, currency })
  const ratioData = [
    {
      color: COLORS.NEXTGEN_INITIATED,
      label: 'NEXTGEN Initiated',
      value: (
        <CurrencyText value={abbreviateNumber(segmentData.nextGen.value)} currency={currency} />
      ),
    },
    {
      color: COLORS.PARTNER_INITIATED,
      label: 'Partner Initiated',
      value: (
        <CurrencyText value={abbreviateNumber(segmentData.partner.value)} currency={currency} />
      ),
    },
    {
      color: COLORS.VENDOR_INITIATED,
      label: 'Vendor Initiated',
      value: (
        <CurrencyText value={abbreviateNumber(segmentData.vendor.value)} currency={currency} />
      ),
    },
  ]
  const total = segmentData.nextGen.value + segmentData.partner.value + segmentData.vendor.value
  const partnerInitiatedPercentage = Math.round((segmentData.partner.value / total) * 100)
  const nextgenInitiatedPercentage = Math.round((segmentData.nextGen.value / total) * 100)
  const { format } = currencyFormatter(true)
  const totalSales = totals.find((item) => item.title.toUpperCase().includes('TOTAL SALES'))
  const quantity = totals.find((item) => item.title.toUpperCase().includes('QUANTITY'))
  const topUsers = getTopBy(5)(sales, 'endUserName').map((item) => ({
    ...item,
    value: format(item.value),
  }))
  const totalSalesValue = totalSales?.text.toString() || '0'
  return (
    <>
      <TopCard total={totalSalesValue} currency={currency} />
      <BaseCard>
        <Group position='left'>
          <Text c='dimmed' tt='uppercase' fw={600} fz='md'>
            RATIO OF SALES
          </Text>
          <MoreInfo info={'Ratio of sales'} />
        </Group>
        <CircularRatioChart
          ratioData={ratioData}
          partnerInitiatedPercentage={partnerInitiatedPercentage}
          nextgenInitiatedPercentage={nextgenInitiatedPercentage}
          total={`${currency} ${totalSalesValue}`}
        />
        <SideBySideFigures
          left={{
            label: 'Total sales',
            value: <CurrencyText value={totalSalesValue} currency={currency} />,
            tooltip: totalSales?.tooltip || 'Total sales',
          }}
          right={{
            label: 'Quantity',
            value: quantity?.text || 0,
            tooltip: quantity?.tooltip || 'Quantity',
          }}
          mt='md'
        />
        <TwoColumnTable
          title={{
            text: 'TOP 5 END USERS',
            tooltip: 'Top 5 end users',
          }}
          data={topUsers}
          currency={currency}
        />
      </BaseCard>
    </>
  )
}
