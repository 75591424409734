import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useSearchParams } from '@ospace/shared'

export const EMAIL_TRACKING_DATA_DOG_ACTION = 'email-tracking'

export const useDataDogEmailTracker = () => {
  const params = useSearchParams()
  useEffect(() => {
    if (params.get(EMAIL_TRACKING_DATA_DOG_ACTION)) {
      datadogRum.addAction(EMAIL_TRACKING_DATA_DOG_ACTION, {})
    }
  }, [params])
}
