import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PasswordField, toAbsoluteUrl, useNotificationContext } from '@ospace/shared'
import { LocalStorage } from '@ospace/shared'
import * as Auth from 'aws-amplify/auth'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { setDataDogUser } from '../../datadog'
import { AuthLayout } from '../components/AuthLayout'
import { useAuthContext } from '../contexts'
import { AuthActions } from '../reducers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(320, 'Maximum of 320 characterrs')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function LoginPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const { dispatch } = useAuthContext()

  const { setNotification } = useNotificationContext()
  const [loading, setLoading] = useState(false)

  const redirectPath = location.state?.from || '/'
  const queryClient = useQueryClient()

  useEffect(() => {
    // make sure the react-query cache is cleared from any previous sessions
    queryClient.clear()
  }, [queryClient])

  const onOfficeLogin = async () => {
    let signInUserSession = await Auth.fetchAuthSession()
    if (signInUserSession?.tokens) {
      //already signed in
      navigate(redirectPath)
      return
    }

    dispatch({
      type: AuthActions.FETCH_USER_DATA_INIT,
    })
    try {
      await Auth.signInWithRedirect({
        provider: {
          custom: 'COGNITO' as any,
        },
        customState: redirectPath,
      })
      const signInUserSession = await Auth.fetchAuthSession()

      LocalStorage.set('session', signInUserSession)
      await setDataDogUser(signInUserSession)
      dispatch({
        type: AuthActions.FETCH_USER_DATA_SUCCESS,
        payload: { session: signInUserSession },
      })
    } catch (e) {
      dispatch({
        type: AuthActions.FETCH_USER_DATA_FAILURE,
        payload: { error: e },
      })
      console.error(e)
    }
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    let signInUserSession = await Auth.fetchAuthSession()
    if (signInUserSession?.tokens) {
      //already signed in
      navigate(redirectPath)
      return
    }
    try {
      await Auth.signIn({
        username: values.email,
        password: values.password,
      })

      signInUserSession = await Auth.fetchAuthSession()

      LocalStorage.set('session', signInUserSession)
      await setDataDogUser(signInUserSession)
      dispatch({
        type: AuthActions.FETCH_USER_DATA_SUCCESS,
        payload: { session: signInUserSession.tokens },
      })

      navigate(redirectPath)
    } catch (e: any) {
      dispatch({
        type: AuthActions.FETCH_USER_DATA_FAILURE,
        payload: { error: e.message },
      })
      setLoading(false)
      setNotification({
        active: true,
        message: e.message,
        type: 'danger',
      })
    }
  }

  return (
    <AuthLayout>
      <Formik validationSchema={loginSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => (
          <>
            <Form className='form w-100'>
              <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Sign In to oSpace</h1>
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span>Email</span>
                </label>

                <Field
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  name='email'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='email' />
                </div>
              </div>

              {/* begin::Form group */}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span>Password</span>
                  <Link
                    to='/auth/forgot-password'
                    className='link-primary fs-6 fw-bolder'
                    style={{ marginLeft: '5px' }}
                  >
                    Forgot Password ?
                  </Link>
                </label>

                <Field
                  type='password'
                  component={PasswordField}
                  className='form-control form-control-lg form-control-solid'
                  name='password'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='password' />
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Action */}
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={loading}
                >
                  {loading && (
                    <span className='spinner-border spinner-border-sm align-middle mx-2' />
                  )}
                  <span className='indicator-label'>Submit</span>
                </button>
                {/* begin::Separator */}
                <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
              </div>
              {/* end::Action */}
            </Form>
            <button
              onClick={onOfficeLogin}
              className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/microsoft-5.svg')}
                className='h-20px me-3'
              />
              Continue with Office 365
            </button>
          </>
        )}
      </Formik>
    </AuthLayout>
  )
}
