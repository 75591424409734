import React from 'react'
import { useLocation } from 'react-router-dom'

/**
 * useSearchParams is only implemented in v6.
 * https://v5.reactrouter.com/web/example/query-parameters
 */
export const useSearchParams = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}
