import { Duration } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import { RatioChart } from '@ospace/shared'

import { pipelineRatioHelper } from './pipelineRatioHelper'

interface Props {
  duration: Duration
  opportunities: Opportunity[]
  currency: string
}
export function PipelineRatio({ duration, opportunities, currency }: Props) {
  const segmentData = pipelineRatioHelper({ opportunities, duration })
  return RatioChart({
    segmentData,
    currency,
    label: 'ratio of pipeline',
    tooltip:
      'This chart displays the total number and total value of deals, segmented by the source of the deal.',
  })
}
