import React, { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Grid,
  Group,
  LoadingOverlay,
  Pagination,
  Paper,
  Select,
  Skeleton,
  Text,
  TextInput,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useClients } from '@ospace/client'
import { userTypes } from '@ospace/core-auth'
import { Client } from '@ospace/schemas'
import { MoreInfo, useSearchParams } from '@ospace/shared'
import { IconSearch } from '@tabler/icons-react'

import { ClientBox } from './ClientBox'

type ClientsListProps = {
  role: userTypes
  title: string
  hideSearch?: boolean
  hidePagination?: boolean
}

const ITEMS_PER_PAGE = 12
const PAGE_SIZE_OPTIONS = Array.from(
  // number of options
  { length: 4 },
  // multiply index by items per page and add items per page
  (_, i) => i * ITEMS_PER_PAGE + ITEMS_PER_PAGE
)

const usePagination = (pageLimit: number, searchValue = '', role: userTypes) => {
  const params = useSearchParams()
  const showDeletedClient = role === userTypes.Admin || role === userTypes.ClientManager
  const filter = params.get('filter') || null
  const [currentPage, setCurrentPage] = useState(1)
  const { data, status, isFetching } = useClients({
    // API next page starts at zero
    nextPage: currentPage - 1,
    pageLimit,
    searchValue,
    withDeleted: showDeletedClient,
  })
  const totalPages = !data?.recordCount ? 0 : Math.ceil(data?.recordCount / pageLimit)

  // reset page counter to the beginning when search value changes
  useEffect(() => {
    if (searchValue) {
      setCurrentPage(1)
    }
  }, [searchValue])

  return {
    status,
    isFetching,
    currentPage,
    setCurrentPage,
    totalPages,
    pageData: (filter && filter === 'Active'
      ? data?.clients?.filter(({ active }) => active)
      : data?.clients || []) as Client[],
  }
}

export const ClientsList = ({ title, hideSearch, hidePagination, role }: ClientsListProps) => {
  const defaultPageSizeLabel = `Show ${PAGE_SIZE_OPTIONS[0]}`
  const [search, setSearch] = useState('')
  const [debouncedSearchValue] = useDebouncedValue(search, 300)
  const [pageSize, setPageSize] = useState(defaultPageSizeLabel)
  const { currentPage, setCurrentPage, pageData, isFetching, status, totalPages } = usePagination(
    +pageSize.replace('Show ', ''),
    debouncedSearchValue,
    role
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }
  if (status === 'loading') {
    return (
      <div aria-label='all-clients-loader'>
        <Skeleton height={700} radius='sm' />
      </div>
    )
  }

  return (
    <Paper bg={'white'} p={'xl'} withBorder mb={'md'}>
      <Group position='left'>
        <Text tt='uppercase' c='dimmed' fw={600} fz='md'>
          {title}
        </Text>
        <MoreInfo info={'More info'} />
      </Group>
      {!hideSearch && (
        <Center>
          <TextInput
            placeholder='Search clients'
            mb='md'
            icon={<IconSearch size='0.9rem' stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
            w={'60%'}
          />
        </Center>
      )}
      <Box pos='relative'>
        <LoadingOverlay aria-label='client-search-loader' visible={isFetching} overlayBlur={2} />
        {pageData.length === 0 ? (
          <Center h={300}>
            <Text c='dimmed'>No results found</Text>
          </Center>
        ) : (
          <Grid mt={'md'}>
            {pageData.map((client) => (
              <Grid.Col md={4} xs={12} key={client.id}>
                <ClientBox client={client} role={role} />
              </Grid.Col>
            ))}
          </Grid>
        )}
      </Box>
      {!hidePagination && (
        <Center m='xl'>
          <Pagination
            aria-label={'client-pagination'}
            value={currentPage}
            onChange={setCurrentPage}
            total={totalPages}
          />
          <Select
            style={{ width: 100 }}
            pl='sm'
            placeholder='Page size'
            defaultValue={defaultPageSizeLabel}
            onChange={(value) => setPageSize(value || defaultPageSizeLabel)}
            data={PAGE_SIZE_OPTIONS.map((size) => `Show ${size}`)}
          />
        </Center>
      )}
    </Paper>
  )
}
