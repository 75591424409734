import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { userTypes } from '@ospace/core-auth'
import * as Auth from 'aws-amplify/auth'

import { setDataDogUser } from '../../datadog'
import { setupAmplify } from '../amplify'
import { useAuthContext } from '../contexts'
import { AuthActions } from '../reducers'

export const useAuthCheck = () => {
  const { dispatch } = useAuthContext()

  setupAmplify()

  useEffect(() => {
    async function onLoad() {
      dispatch({
        type: AuthActions.FETCH_USER_DATA_INIT,
      })

      try {
        const session = await Auth.fetchAuthSession()
        await setDataDogUser(session)
        dispatch({
          type: AuthActions.FETCH_USER_DATA_SUCCESS,
          payload: { session: session.tokens },
        })
      } catch (error: any) {
        console.log('error', error)
        dispatch({
          type: AuthActions.FETCH_USER_DATA_FAILURE,
          payload: { error },
        })
      }
    }
    onLoad()
  }, [dispatch])
}

export const useUserRole = () => {
  const { session }: { session: any } = useAuthContext()

  if (session && session?.accessToken?.payload['cognito:groups']) {
    const groups: string[] = session?.accessToken?.payload['cognito:groups']
    const validGroups: any = groups
      ?.map((group: any) => {
        return userTypes[group]
      })
      .filter((g: any) => g !== undefined)

    const lowestRole = validGroups.length ? Math.max(...validGroups) : userTypes.ClientUser
    return lowestRole
  }
  return userTypes['ClientUser']
}

export const useCheckRole = (roles: any = []) => {
  const navigate = useNavigate()
  const role = useUserRole()

  if (!roles.includes(role)) return navigate('/error/403')
  else return true
}
