import { ReactElement } from 'react'
import * as React from 'react'
import { User } from '@ospace/auth'
import { calculateDuration, Duration, DurationSelection } from '@ospace/common'
import { userTypes } from '@ospace/core-auth'
import { Client } from '@ospace/schemas'
import { DurationPicker } from '@ospace/shared'

import { ClientPageLayout } from './ClientPageLayout'
import { PageId } from './SideNavbar'

export type DashboardLayoutProps =
  | {
      status: 'ready'
      client: Client
      duration: Duration
      user: User
      role: userTypes
      setDuration: (selection: DurationSelection) => void
      children: ReactElement
      pageId: PageId
      pageTitle: string
    }
  | {
      status: 'loading'
      client?: Client
      user?: User
      role: userTypes
      pageId: PageId
      pageTitle: string
    }

export const DashboardLayout = (props: DashboardLayoutProps) => {
  if (props.status === 'loading') {
    return (
      <ClientPageLayout
        status='loading'
        pageId={props.pageId}
        pageTitle={props.pageTitle}
        user={props.user}
        role={props.role}
        client={props.client}
        subHeader={<DurationPicker duration={calculateDuration('FY', 6)} setDuration={() => {}} />}
      />
    )
  }
  return (
    <ClientPageLayout
      status='ready'
      fluid={true}
      client={props.client}
      user={props.user}
      role={props.role}
      subHeader={<DurationPicker duration={props.duration} setDuration={props.setDuration} />}
      pageId={props.pageId}
      pageTitle={props.pageTitle}
    >
      {props.children}
    </ClientPageLayout>
  )
}
