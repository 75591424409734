import clsx from 'clsx'

type ButtonProps = {
  variant: 'primary' | 'secondary'
  size: 'sm'
  type?: 'submit'
  onClick?: () => void
  disabled?: boolean
  submitting?: boolean
  children: React.ReactNode
  className?: string
}

export const Button = (props: ButtonProps) => (
  <button
    type={props.type}
    className={clsx('btn me-2', `btn-${props.variant}`, `btn-${props.size}`, props.className)}
    disabled={props.disabled || props.submitting}
    onClick={props.onClick}
  >
    {props.submitting ? (
      <span className='spinner-border spinner-border-sm align-middle mx-2' />
    ) : null}
    {props.children}
  </button>
)
