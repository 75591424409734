import { Group, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared/components/MoreInfo'

export const TableHeader = () => {
  return (
    <Group position='left'>
      <Text c='dimmed' tt='uppercase' fw={600} fz='12px'>
        All Leads
      </Text>
      <MoreInfo info='This table is a detailed breakdown of all leads.' />
    </Group>
  )
}
