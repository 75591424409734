import { SalesInvoice } from '@ospace/schemas'

export const salesData = ({ sales }: { sales: SalesInvoice[] }) => {
  const totalSalesInvoicesCount = sales.length
  const totalSalesInvoicesValue = sales.reduce((sum, sale) => sum + +(sale.buyPriceFx || 0), 0)
  const averageSalesInvoicesValue = totalSalesInvoicesValue / totalSalesInvoicesCount
  const totalPartnersCount = new Set(sales.map((sale) => sale.partnerName)).size

  return {
    totalSalesInvoicesCount,
    totalSalesInvoicesValue,
    averageSalesInvoicesValue,
    totalPartnersCount,
  }
}

export const groupByOrderNumber = (salesInvoice: SalesInvoice[]): SalesInvoice[] => {
  const result = salesInvoice.reduce(
    (result, salesInvoice) => {
      const { orderNumber } = salesInvoice
      result[orderNumber] = {
        ...salesInvoice,
        buyPriceFx: (result[orderNumber]?.buyPriceFx || 0) + salesInvoice.buyPriceFx,
        buyPriceLocal: (result[orderNumber]?.buyPriceLocal || 0) + salesInvoice.buyPriceLocal,
      }
      return result
    },
    {} as { [orderNumber: string]: SalesInvoice }
  )

  return Object.values(result)
}
