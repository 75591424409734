import { currencyFormatter } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import { parseDate } from '@ospace/shared'
import {
  DashboardDetailedModalData,
  DEFAULT_MODAL_STEPS,
} from '@ospace/shared/components/DashboardDetailedModal'

const calculateActiveStep = (data: Opportunity | undefined) => {
  // originated from an oSpace Program
  if (data?.programId) {
    return {
      activeStep: 3,
      steps: DEFAULT_MODAL_STEPS,
    } // 3 is the opportunity step
  }

  return {
    activeStep: 1,
    steps: ['Opportunity', 'Sale'],
  }
}

export const transformModalData = (data: Opportunity | undefined, currency: string) => {
  return {
    steps: calculateActiveStep(data),
    title: data?.vendorName || '',
    revenue: data?.calculatedBuyPrice,
    leftColumn: [
      {
        label: 'Opportunity Name',
        value: data?.oppName,
      },
      {
        label: 'End User',
        value: data?.endUserName,
      },
      {
        label: 'Partner',
        value: data?.partnerName,
      },
      {
        label: 'Opportunity Type',
        value: data?.oppType,
      },
      {
        label: 'Probability',
        value: data?.probability,
      },
    ],
    rightColumn: [
      {
        label: 'Value',
        value: `${currency} ${currencyFormatter().format(+(data?.calculatedBuyPrice || 0))}`,
      },
      {
        label: 'Closed Date',
        value: parseDate(data?.closeDate, 'DD-MM-YYYY'),
      },
      {
        label: 'Program Name',
        value: data?.programName,
      },
      {
        label: 'Vendor Contact',
        value: data?.vendorContactName,
      },
      {
        label: 'Opportunity Status',
        value: data?.oppStatus,
      },
    ],
  } as DashboardDetailedModalData
}
