import { FC } from 'react'
import { Input } from '@ospace/shared'
import { Field, useFormikContext } from 'formik'
import { upperFirst } from 'lodash'

interface Props {
  titleFieldName: string
  title: string
  analytics: { label: string; fieldName: string }[]
  children?: React.ReactNode
}

export const AnalyticsContainer: FC<Props> = ({ title, titleFieldName, children, analytics }) => {
  const { values, setFieldValue } = useFormikContext()
  const formValues = values as any
  return (
    <div className='w-100'>
      <div className='fv-row mb-10 d-flex flex-stack'>
        <h3 className='stepper-title'>{title}</h3>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field
            name={titleFieldName}
            className='form-check-input'
            type='checkbox'
            onChange={(event: Event) => {
              const target = event.target as HTMLInputElement
              setFieldValue(titleFieldName, target.checked)
              analytics.forEach((field) => {
                setFieldValue(`is${upperFirst(field.fieldName)}Enabled`, target.checked)
              })
            }}
          />
        </label>
      </div>

      <div className='fv-row'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span>Objectives</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your objectives'
          ></i>
        </label>
        {analytics.map((field) => (
          <div className='mb-10 d-flex' key={field.label}>
            <div>
              <Input
                disabled={
                  !formValues[titleFieldName] ||
                  !formValues[`is${upperFirst(field.fieldName)}Enabled`]
                }
                labelStyle={{ width: 200 }}
                inputStyle={{ width: 100 }}
                type='number'
                labelAlignment='left'
                {...field}
              />
            </div>
            <label
              style={{
                paddingLeft: 20,
              }}
              className='form-check form-switch form-check-custom form-check-solid'
            >
              <Field
                disabled={!formValues[titleFieldName]}
                name={`is${upperFirst(field.fieldName)}Enabled`}
                className='form-check-input'
                type='checkbox'
              />
            </label>
          </div>
        ))}
        {children}
      </div>
    </div>
  )
}
