import * as React from 'react'
import { Progress } from '@mantine/core'
import SkeletonLoader from '@ospace/distribution-pipeline/components/SkeletonLoader'

export const DashboardLoader = ({ progress, total }: { progress: number; total: number }) => (
  <div style={{ margin: '-26px' }}>
    <Progress animate size='xs' value={((progress || 2) / (total || 5000)) * 100} />
    <SkeletonLoader />
  </div>
)
