import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { ErrorBoundary } from '@ospace/shared'

import { CompaniesTable } from '../components/Companies'
import { useCompanies } from '../hooks/useContacts'

type Props = { campaignId: number }

export const CompaniesPage = (props: Props) => {
  const { isFetching, data: companies } = useCompanies(props.campaignId)

  return (
    <CampaignPage>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>All Companies</span>
          </h3>
        </div>
        <div className='card-body'>
          {isFetching ? (
            <div className='d-flex align-items-center justify-content-center'>
              <Spinner animation='grow'></Spinner>
            </div>
          ) : (
            <CompaniesTable companies={companies} />
          )}
        </div>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)
  return <CompaniesPage campaignId={campaignId} />
}

export const CompaniesPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
