import { abbreviateNumber, Duration, plotGraphData } from '@ospace/common'
import { pipelineChartPlotter } from '@ospace/distribution-pipeline'
import { Opportunity } from '@ospace/schemas'
import { CurrencyText } from '@ospace/shared/components/CurrencyText'
import { IconChartBubble } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { LineGraphCard } from '../LineGraphCard'

const parseDate = (opportunity: Opportunity) => {
  try {
    const date = (opportunity.closeDate || opportunity.startDate).split('-')
    const month = date[1]
    const year = date[2]
    const day = date[0]
    return `${year}-${month}-${day}`
  } catch (e) {
    console.error('parseDate', e)
    return dayjs().format('YYYY-MM-DD')
  }
}
export const TopCard = ({
  opportunities,
  duration,
  currency,
}: {
  duration: Duration
  opportunities: Opportunity[]
  currency: string
}) => {
  const closedDeals = pipelineChartPlotter({ opportunities, duration }).find(({ title }) =>
    title.includes('close')
  )
  const groupedByCloseDate = (closedDeals?.opportunities || []).reduce(
    (acc, opportunity) => {
      const date = parseDate(opportunity)
      if (!acc[date]) {
        acc[date] = +(opportunity.calculatedBuyPrice || 0)
      }
      acc[date] = acc[date] + +(opportunity.calculatedBuyPrice || 0)
      return acc
    },
    {} as Record<string, number>
  )

  const graph = plotGraphData(groupedByCloseDate, duration)

  return (
    <LineGraphCard
      backgroundColor={'#7950F2'}
      iconBackgroundColor={'#6944D6'}
      icon={IconChartBubble}
      heading={'PIPELINE'}
      title={'TOTAL PIPELINE REVENUE'}
      tooltip={closedDeals?.infoText}
      data={graph.chartData.data}
      chartLabels={graph.chartData.chartLabels}
      left={{
        label: 'Total value',
        value: (
          <CurrencyText
            value={abbreviateNumber(closedDeals?.totalValue || 0)}
            currency={currency}
            currencyColor='white'
          />
        ),
      }}
      right={{
        label: 'Total deals',
        value: closedDeals?.totalDeals.toString() || '0',
      }}
    />
  )
}
