import { FC } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CampaignCrm,
  CampaignStatus,
  CreateCampaignRequest,
  createCampaignRequestSchema,
  UpdateCampaignRequest,
} from '@ospace/schemas'
import { KTSVG, useNotificationContext } from '@ospace/shared'
import { Form, Formik, FormikValues } from 'formik'

import { useClientCampaign } from '../../hooks'
import { CampaignService } from '../../services'
import { Product, ProductsTable } from '../ProductsTable'

import { AnalyticsContainer } from './steps/AnalyticsContainer'
import { Step1 } from './steps/Step1'
import { Step6 } from './steps/Step6'
import { StepICP } from './steps/StepICP'
import { Stepper, StepperContent, useStepper } from './Stepper'

enum Step {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven,
}

const stepSchemas = {
  [Step.One]: createCampaignRequestSchema.pick([
    'name',
    'description',
    'startDate',
    'lookerClientId',
  ]),
  [Step.Two]: createCampaignRequestSchema.pick([
    'intents',
    'companiesDiscovered',
    'contactsDiscovered',
  ]),
  [Step.Three]: createCampaignRequestSchema.pick([
    'telesaleHours',
    'meetingBooked',
    'targetPipelineValue',
    'dealRegDone',
  ]),
  [Step.Four]: createCampaignRequestSchema.pick([
    'digitalLeads',
    'connectionRequestsAccepted',
    'connectionRequestsSent',
    'impressions',
    'campaignClicks',
    'digitalProspectingMeetingBooked',
  ]),
  [Step.Five]: createCampaignRequestSchema.pick(['icps']),
  [Step.Six]: createCampaignRequestSchema.pick(['lookerClientId', 'intentReportConfigurations']),
  [Step.Seven]: createCampaignRequestSchema.pick(['products']),
}

const stepCount = Object.keys(stepSchemas).length

type CampaignFormProps =
  | {
      type: 'edit'
      campaign: UpdateCampaignRequest
    }
  | { type: 'create' }

const CampaignForm: FC<CampaignFormProps> = (props: CampaignFormProps) => {
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const params: any = useParams() // TODO: get router out of here
  const clientId = props.type === 'edit' ? props.campaign.clientId : Number(params?.id)

  useClientCampaign(clientId!) // Q: why doesn't this return anything?
  const { currentStep, setCurrentStep, goNextStep, goPrevStep, isFinalStep, isFirstStep } =
    useStepper<Step>(stepCount)
  const initValues: CreateCampaignRequest = {
    // Q: do initial values belong here or in models?
    ...{
      name: '',
      crm: CampaignCrm.HUBSPOT,
      description: '',
      startDate: '',
      endDate: '',
      intents: 0,
      isIntentsEnabled: true,
      companiesDiscovered: 0,
      isCompaniesDiscoveredEnabled: true,
      contactsDiscovered: 0,
      isContactsDiscoveredEnabled: true,
      telesaleHours: 0,
      isTelesaleHoursEnabled: true,
      meetingBooked: 0,
      isMeetingBookedEnabled: true,
      targetPipelineValue: 0,
      isTargetPipelineValueEnabled: true,
      dealRegDone: 0,
      isDealRegDoneEnabled: true,
      digitalLeads: 0,
      isDigitalLeadsEnabled: true,
      digitalProspectingMeetingBooked: 0,
      isDigitalProspectingMeetingBookedEnabled: true,
      connectionRequestsSent: 0,
      isConnectionRequestsSentEnabled: true,
      connectionRequestsAccepted: 0,
      isConnectionRequestsAcceptedEnabled: true,
      impressions: 0,
      isImpressionsEnabled: true,
      campaignClicks: 0,
      isCampaignClicksEnabled: true,
      isDataIntelligenceActive: true,
      isTeleProspectingActive: true,
      isDigitalProspectingActive: true,
      lookerClientId: null,
      status: CampaignStatus.IN_PROGRESS,
      intentReportConfigurations: [],
      icps: [],
      products: [],
    },
    ...(props.type === 'edit' ? props.campaign : {}),
    clientId,
  }

  const mutation = useMutation(
    (camp: CreateCampaignRequest) => {
      const campaignDTO: any = {}
      for (const [key, value] of Object.entries(camp)) {
        campaignDTO[key] = value === '' ? null : value
      }
      return props.type === 'edit'
        ? CampaignService.editCampaign(campaignDTO, props.campaign.id)
        : CampaignService.createCampaigns(campaignDTO)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: `You have successfully ${
            props.type === 'edit' ? 'edited' : 'created'
          } the campaign.`,
          type: 'success',
        })
        if (props.type === 'edit') queryClient.invalidateQueries(['campaign', props.campaign.id])
        queryClient.invalidateQueries({
          queryKey: ['client-campaign', clientId],
          refetchInactive: true,
        })
        queryClient.invalidateQueries({ queryKey: ['clients'], refetchInactive: true })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.message,
          type: 'danger',
        })
      },
    }
  )

  const submitStep = async (values: CreateCampaignRequest, actions: FormikValues) => {
    if (!isFinalStep) {
      goNextStep()
    } else {
      setCurrentStep(1)
      await mutation.mutateAsync(values)
      actions.resetForm()
      navigate(-1) // TODO: navigate to a specific route, don't rely on history here.
    }
  }

  return (
    <div className='card'>
      <div className='card-content'>
        <div className='d-flex flex-wrap flex-lg-nowrap justify-content-center card-body py-lg-10 px-lg-10'>
          <div className='d-flex justify-content-center justify-content-xl-start w-xl-300px'>
            <Stepper
              currentStep={currentStep}
              numSteps={stepCount}
              steps={[
                { step: Step.One, title: 'Basic Details' },
                { step: Step.Two, title: 'Data and Intelligence' },
                { step: Step.Three, title: 'Teleprospecting' },
                { step: Step.Four, title: 'Digital prospecting' },
                { step: Step.Five, title: 'ICP' },
                { step: Step.Six, title: 'Integration' },
                { step: Step.Seven, title: 'Products' },
              ]}
            />
          </div>
          <div className='ps-lg-10 d-flex flex-grow-1 flex-center bg-white rounded'>
            <Formik
              validationSchema={stepSchemas[currentStep as Step]}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({ values, setFieldValue }) => (
                <Form
                  className='form'
                  noValidate
                  id='kt_create_account_form'
                  aria-label='create campaign form'
                >
                  <StepperContent step={Step.One} currentStep={currentStep}>
                    <Step1 type={props.type} values={values} />
                  </StepperContent>

                  <StepperContent step={Step.Two} currentStep={currentStep}>
                    <AnalyticsContainer
                      title='Data and Intelligence'
                      titleFieldName='isDataIntelligenceActive'
                      analytics={[
                        {
                          label: 'Number of Intent Reports',
                          fieldName: 'intents',
                        },
                        {
                          label: 'Companies discovered',
                          fieldName: 'companiesDiscovered',
                        },
                        {
                          label: 'Contacts discovered',
                          fieldName: 'contactsDiscovered',
                        },
                      ]}
                    ></AnalyticsContainer>
                  </StepperContent>

                  <StepperContent step={Step.Three} currentStep={currentStep}>
                    <AnalyticsContainer
                      title='Teleprospecting'
                      titleFieldName='isTeleProspectingActive'
                      analytics={[
                        {
                          label: 'Telesales Hours',
                          fieldName: 'telesaleHours',
                        },
                        {
                          label: 'Meetings Booked',
                          fieldName: 'meetingBooked',
                        },
                        {
                          label: 'Target Pipeline Value',
                          fieldName: 'targetPipelineValue',
                        },
                        {
                          label: 'Number of Deal Reg',
                          fieldName: 'dealRegDone',
                        },
                      ]}
                    />
                  </StepperContent>

                  <StepperContent step={Step.Four} currentStep={currentStep}>
                    <AnalyticsContainer
                      title='Digital prospecting'
                      titleFieldName='isDigitalProspectingActive'
                      analytics={[
                        {
                          label: 'Digital engagement',
                          fieldName: 'digitalLeads',
                        },

                        {
                          label: 'Meetings Booked',
                          fieldName: 'digitalProspectingMeetingBooked',
                        },
                        {
                          label: 'Connection requests sent',
                          fieldName: 'connectionRequestsSent',
                        },
                        {
                          label: 'Connection requests accepted',
                          fieldName: 'connectionRequestsAccepted',
                        },
                        {
                          label: 'Impressions',
                          fieldName: 'impressions',
                        },
                        {
                          label: 'Campaign clicks',
                          fieldName: 'campaignClicks',
                        },
                      ]}
                    />
                  </StepperContent>

                  <StepperContent step={Step.Five} currentStep={currentStep}>
                    <StepICP values={values} />
                  </StepperContent>

                  <StepperContent step={Step.Six} currentStep={currentStep}>
                    <Step6 type={props.type} values={values} />
                  </StepperContent>

                  <StepperContent step={Step.Seven} currentStep={currentStep}>
                    <ProductsTable
                      products={values.products}
                      postProduct={(product: Product) => {
                        // if product don't exist in products array, add it
                        if (!values.products.find((p) => p.id === product.id)) {
                          setFieldValue('products', [...values.products, product])
                        } else {
                          // if product exist, update it
                          setFieldValue(
                            'products',
                            values.products.map((p) => (p.id === product.id ? product : p))
                          )
                        }
                      }}
                      deleteProduct={(productId: string) => {
                        setFieldValue(
                          'products',
                          values.products.filter((p: Product) => p.id !== productId)
                        )
                      }}
                    />
                  </StepperContent>

                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      {isFirstStep ? null : (
                        <button
                          onClick={goPrevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Back
                        </button>
                      )}
                    </div>

                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={mutation.isLoading}
                      >
                        {mutation.isLoading && (
                          <span className='spinner-border spinner-border-sm align-middle mx-2' />
                        )}
                        <span className='indicator-label'>
                          {isFinalStep ? 'Submit' : 'Continue'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CampaignForm }
