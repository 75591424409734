import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { useClient } from '@ospace/client'
import { ClientHeader } from '@ospace/client/components/ClientHeader' // circular import
import { ClientNav } from '@ospace/client/components/ClientNav' // circular import
import { ClientSwitcher, PageLink, PageTitle } from '@ospace/layout'
import { SelectedPage } from '@ospace/layout'

import { Campaigns } from '../components/Campaigns'
import { NewCampaignButton } from '../components/NewCampaignButton'
import { useClientCampaign } from '../hooks'

const ClientCampaignsPage: React.FC = () => {
  const params: any = useParams()
  const clientId = Number(params?.id!)
  const client = useClient(clientId)
  const userRole = useUserRole()
  const campaigns = useClientCampaign(clientId || 0, !!clientId)

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isActive: false,
    },
    {
      title: 'Clients',
      path: '/',
      isActive: true,
    },
  ]

  return (
    <>
      {campaigns.isFetching || client.isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : campaigns.status === 'error' ? (
        <span>Error: {(campaigns.error as any).message}</span>
      ) : (
        <>
          <ClientSwitcher client={client?.data} />
          <SelectedPage>Programs</SelectedPage>
          <PageTitle breadcrumbs={breadCrumbs}>Overview</PageTitle>
          {client.isSuccess && campaigns.isSuccess && (
            <>
              <ClientHeader client={client.data} userRole={userRole} campaigns={campaigns.data} />
              <ClientNav client={client.data} userRole={userRole} selectedTab='campaigns'>
                <NewCampaignButton client={client.data} userRole={userRole} />
              </ClientNav>
            </>
          )}
          {campaigns.isSuccess && <Campaigns campaigns={campaigns.data} />}
        </>
      )}
    </>
  )
}

export { ClientCampaignsPage }
