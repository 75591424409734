import * as React from 'react'
import { Button, createStyles, Flex, Menu, rem } from '@mantine/core'
import { Campaign } from '@ospace/schemas'
import { IconCaretDownFilled } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  navButtons: {
    backgroundColor: 'white',
    color: 'black',
    paddingBottom: 10,
    fontSize: theme.fontSizes.md,
    fontWeight: 10,
    borderRadius: 0,
    '&:hover': {
      borderBottom: `${rem(2)} solid #00acee`,
      backgroundColor: 'transparent',
    },
  },
  menu: {
    fontSize: theme.fontSizes.md,
  },
}))

export default function ProgramNavigation({ campaign }: { campaign: Campaign }) {
  const { classes } = useStyles()
  return (
    <div
      style={{
        backgroundColor: 'white',
        paddingLeft: 17,
        paddingTop: 10,
        borderBottom: '0.125rem solid #e9ecef',
      }}
    >
      <Flex align='center' gap={'xs'} justify='start'>
        <Button className={classes.navButtons} component='a' href={`/campaign/view/${campaign.id}`}>
          Overview
        </Button>
        <Menu shadow='md' offset={0}>
          <Menu.Target>
            <Button className={classes.navButtons}>
              <>
                Data and Intelligence
                <IconCaretDownFilled
                  size={rem(13)}
                  style={{
                    marginLeft: 2,
                  }}
                />
              </>
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              className={classes.menu}
              component='a'
              href={`/campaign/${campaign.id}/contact`}
            >
              Contacts
            </Menu.Item>
            <Menu.Item
              component='a'
              href={`/campaign/${campaign.id}/company`}
              className={classes.menu}
            >
              Accounts
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label className={classes.menu}>Analytics Dashboards</Menu.Label>
            {campaign?.objective?.isIntentsEnabled && (
              <Menu.Item
                component='a'
                href={`/campaign/data-intel/${campaign.id}/dashboard/intent`}
                className={classes.menu}
              >
                Intent data
              </Menu.Item>
            )}
            {campaign?.objective?.isIntentsEnabled && (
              <Menu.Item
                component='a'
                href={`/campaign/data-intel/${campaign.id}/dashboard/intent_and_engagement`}
                className={classes.menu}
              >
                Intent and engagement
              </Menu.Item>
            )}
            {campaign?.objective?.isContactsDiscoveredEnabled && (
              <Menu.Item
                component='a'
                href={`/campaign/data-intel/${campaign.id}/dashboard/contact`}
                className={classes.menu}
              >
                Contact data
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>{' '}
        <Button
          className={classes.navButtons}
          component='a'
          href={`/campaign/${campaign.id}/files`}
        >
          Files
        </Button>
        <Button
          className={classes.navButtons}
          component='a'
          href={`/campaign/${campaign.id}/potential-opportunities`}
        >
          Potential Opportunities
        </Button>
        <Button
          className={classes.navButtons}
          component='a'
          href={`/campaign/${campaign.id}/account-insights`}
        >
          Account Insights
        </Button>
      </Flex>
    </div>
  )
}
