import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Table } from '@ospace/shared'
import { ColumnDef } from '@tanstack/react-table'

import { Company } from '../schemas/Company'

type CompaniesTableProps = {
  companies: Company[]
}

const CompaniesTable: FC<any> = ({ companies }: CompaniesTableProps, ref) => {
  const { pathname } = useLocation()

  const columns: ColumnDef<Company>[] = [
    {
      accessorFn: (row) => row.name,
      header: 'Name',
      cell: (info: any) => {
        return (
          <Link
            to={`${pathname}/${info.row.original.id}`}
            className='text-hover-priamry cursor-pointer'
          >
            {`${info.row.original.name}`}
          </Link>
        )
      },
    },
    { accessorKey: 'city', header: 'City' },
    { accessorKey: 'website', header: 'Website' },
    { accessorFn: (row) => row.employeeRange, header: 'Employees' },
    { accessorFn: (row) => row.industry, header: 'Industry' },
  ]

  return (
    <div className={`card`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table columns={columns} data={companies} />
        </div>
      </div>
    </div>
  )
}

export { CompaniesTable }
