import { ErrorMessage, Field } from 'formik'

interface Props {
  fieldName: string
  label: string
  tooltip?: string
  children: React.ReactNode
}

export const Select = ({ children, label, tooltip, fieldName }: Props) => {
  return (
    <>
      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
        <span>{label}</span>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title={tooltip}
        ></i>
      </label>
      <Field
        name={fieldName}
        as='select'
        className='form-select form-control form-control-lg form-control-solid'
      >
        {children}
      </Field>
      <div className='text-danger mt-2'>
        <ErrorMessage name={fieldName} />
      </div>
    </>
  )
}
