import { Text } from '@mantine/core'

export const TextLabel = ({ title, value }: { title: string; value: string }) => (
  <div>
    <Text c='dimmed' fw={400} fz='sm' mt={10}>
      {title}
    </Text>
    <Text fw={700} fz='xl'>
      {value}
    </Text>
  </div>
)
