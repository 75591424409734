import { Box, createStyles, Group, Paper, Progress, rem, SimpleGrid, Text } from '@mantine/core'
import { abbreviateNumber, currencyFormatter } from '@ospace/common'
import { CurrencyText, MoreInfo } from '@ospace/shared'

interface Props {
  segmentData: SegmentData
  currency: string
  label: string
  abbreviatedCurrency?: boolean
  tooltip: string
}

interface Stats {
  value: number
  deals: number
}

interface SegmentData {
  total: number
  nextGen: Stats
  partner: Stats
  vendor: Stats
}

const useStyles = createStyles((theme) => ({
  progressLabel: {
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },
  stat: {
    borderBottom: `${rem(7)} solid`,
    paddingBottom: rem(10),
  },
}))

const transform = ({ total, nextGen, partner, vendor }: SegmentData) => ({
  total,
  data: [
    {
      label: 'NEXTGEN Initiated',
      color: '#7546AA',
      ...nextGen,
      percentage: Math.round((nextGen.value / total) * 100),
    },
    {
      label: 'Partner Initiated',
      color: '#A78EC9',
      ...partner,
      percentage: Math.round((partner.value / total) * 100),
    },
    {
      label: 'Vendor Initiated',
      color: '#C4B4DC',
      ...vendor,
      percentage: Math.round((vendor.value / total) * 100),
    },
  ],
})

export function RatioChart({
  segmentData,
  label,
  abbreviatedCurrency = false,
  tooltip,
  currency,
}: Props) {
  const formatter = currencyFormatter(true)
  const { classes } = useStyles()
  const { data, total } = transform(segmentData)
  const segments = data.map((segment) => ({
    value: segment.percentage,
    color: segment.color,
    label: segment.percentage > 1 ? `${segment.percentage}%` : undefined,
  }))

  const descriptions = data.map((stat) => (
    <Box key={stat.label} sx={{ borderBottomColor: stat.color }} className={classes.stat}>
      <Text fz='md' c='dimmed' fw={700}>
        {stat.label}
      </Text>
      <Group position='apart' align='flex-end' spacing={0} mt={18}>
        <Text fz='sm' c='dimmed'>
          Value
        </Text>
        <Text fz='sm' c='dimmed'>
          Transactions
        </Text>
      </Group>
      <Group position='apart' align='flex-end' spacing={0} fz='lg' c='gray.7'>
        <CurrencyText
          aria-label={`${label} ${stat.label} value`}
          currency={currency}
          value={abbreviatedCurrency ? abbreviateNumber(stat.value) : formatter.format(stat.value)}
        />
        <Text fw={700}>{stat.deals}</Text>
      </Group>
    </Box>
  ))

  return (
    <Paper withBorder p={20} radius='md' h='100%'>
      <Group align='flex-end' spacing='xs' aria-label={`${label} value`.toLowerCase()}>
        <CurrencyText
          currency={currency}
          value={abbreviatedCurrency ? abbreviateNumber(total) : formatter.format(total)}
        />
      </Group>
      <Group position='left'>
        <Text c='dimmed' tt='uppercase' fw={700} fz='md'>
          {label}
        </Text>
        <MoreInfo info={tooltip} />
      </Group>
      <Progress
        sections={segments}
        size={34}
        classNames={{ label: classes.progressLabel }}
        mt={10}
      />
      <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt='xl'>
        {descriptions}
      </SimpleGrid>
    </Paper>
  )
}
