import { Link } from 'react-router-dom'
import { userTypes } from '@ospace/core-auth'
import { KTSVG } from '@ospace/shared'

type NewCampaignButtonProps = {
  userRole: any
  client: any
}

export const NewCampaignButton = (props: NewCampaignButtonProps) => {
  return (
    <>
      {(props.userRole === userTypes.Admin || props.userRole === userTypes.ClientManager) && (
        <Link to={`/client/${props.client.id}/campaign/create`} className='btn btn-primary btn-sm'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          New Campaign
        </Link>
      )}
    </>
  )
}
