import { Flex, Group, Paper, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared'

const TextLabel = ({
  label,
  value,
  position,
}: {
  label: string
  value: string | number
  position: 'start' | 'end'
}) => (
  <div>
    <Text c='dimmed' fw={400} fz='12px' mt={10}>
      {label}
    </Text>
    <Flex fw={700} fz='24px' justify={`flex-${position}`} c='gray.7'>
      {value}
    </Flex>
  </div>
)

export interface LeadsWidgetProps {
  title: string
  tooltip: string
  color: string
  data: { x: string; y: number }[]
  left: any
  right: any
  chartLabels: any
  chart: any
  withBorder?: boolean
}

export const LeadsWidget = (item: LeadsWidgetProps) => {
  const border = item.withBorder !== false
  return (
    <Paper withBorder={border} p={border ? 'lg' : 0} radius='md' key={item.title}>
      <Group position='left'>
        <Text c='dimmed' tt='uppercase' fw={600} fz='12px'>
          {item.title}
        </Text>
        <MoreInfo info={item.tooltip} />
      </Group>
      {item.chart({
        chartLabels: item.chartLabels,
        data: [
          {
            id: item.title,
            color: item.color,
            data: item.data,
          },
        ],
      })}

      <Group position='apart' mt='md'>
        <TextLabel {...item.left} position={'start'} />
        <TextLabel {...item.right} position={'end'} />
      </Group>
    </Paper>
  )
}
