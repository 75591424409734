import { Divider, Flex, Grid, Group, Stack, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared'

export const TwoColumnTable = ({
  title,
  data,
  currency,
}: {
  title: {
    text: string
    tooltip: string
  }
  data: { key: string; value: string }[]
  currency: string
}) => {
  return (
    <Stack mt='xl'>
      <Group position='left'>
        <Text c='dimmed' tt='uppercase' fw={600} fz='md'>
          {title.text}
        </Text>
        <MoreInfo info={title.tooltip} />
      </Group>
      <Stack mt='md' spacing={10} c='gray.7'>
        {data.map(({ key, value }, i) => (
          <div key={`${key}${value}`}>
            <Grid>
              <Grid.Col
                span={8}
                style={{
                  overflowWrap: 'break-word',
                }}
              >
                {key}
              </Grid.Col>
              <Grid.Col span={4}>
                <Flex justify={'flex-end'}>{`${currency} ${value}`}</Flex>
              </Grid.Col>
            </Grid>
            {
              // Don't show divider for last item
              i !== data.length - 1 && <Divider my='sm' />
            }
          </div>
        ))}
      </Stack>
    </Stack>
  )
}
