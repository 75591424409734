import { createStyles, Divider, Flex, Grid, Group, List, Modal, Stepper, Text } from '@mantine/core'
import { currencyFormatter } from '@ospace/common'
import { ColDef } from '@ospace/shared'

export const DEFAULT_MODAL_STEPS = ['Discovered', 'Leads', 'Meeting', 'Opportunity', 'Sale']

export type DashboardDetailedModalData = {
  steps: { activeStep: number; steps: string[] }
  title: string
  revenue: number | string
  leftColumn: { label: string; value: string }[]
  rightColumn: { label: string; value: string }[]
}

const useStyles = createStyles((theme) => ({
  list: {
    fontSize: theme.fontSizes.md,
  },
  listItem: {
    fontWeight: 'bold',
    padding: `${theme.spacing.xs} 0`,
    overflowWrap: 'anywhere',
    [`& span`]: {
      color: theme.colors.gray[6],
      display: 'flex',
    },
    [`& span div`]: {
      color: theme.colors.gray[7],
    },
  },
  title: {
    color: theme.colors.gray[7],
    fontWeight: 'bold',
    fontSize: theme.fontSizes.lg,
  },
  revenue: {
    color: theme.colors.dark[7],
    fontWeight: 'bold',
    fontSize: theme.fontSizes.lg,
  },
  divider: {
    position: 'relative',
    left: '-30px',
    width: '110%',
  },
  stepIcon: {
    borderRadius: 'unset',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}))

const ListItem = ({ label, value }: { label: string; value: string | undefined }) => {
  const { classes } = useStyles()

  return (
    <List.Item className={classes.listItem}>
      {label}:
      <Text fw='500' ml='xs' color='dark'>
        {value}
      </Text>
    </List.Item>
  )
}

const ModalStepper = ({ steps }: DashboardDetailedModalData) => {
  const { classes } = useStyles()
  return (
    <Stepper
      px='md'
      active={steps.activeStep}
      py='md'
      classNames={{
        stepIcon: classes.stepIcon,
      }}
    >
      {steps.steps.map((step) => (
        <Stepper.Step c='gray.7' key={step} label={step} description=''></Stepper.Step>
      ))}
    </Stepper>
  )
}
const Header = ({ data, currency }: { data: DashboardDetailedModalData; currency: string }) => {
  const { classes } = useStyles()
  return (
    <Flex justify='space-between' px='md' pt='xs' fz={20}>
      <Text className={classes.title}>{data?.title}</Text>
      <Group fw='500' c='gray.7'>
        <span>Revenue: </span>
        <Text className={classes.revenue}>
          {currency} {currencyFormatter().format(+(data?.revenue || 0))}
        </Text>
      </Group>
    </Flex>
  )
}

const Content = ({
  leftColumn,
  rightColumn,
}: DashboardDetailedModalData & { campaignName: string }) => {
  const { classes } = useStyles()
  return (
    <Grid gutter={5} gutterXs='md' gutterMd='xl' gutterXl={30}>
      <Grid.Col md={6} sm={12}>
        <List listStyleType='none' className={classes.list}>
          {leftColumn.map((item) => (
            <ListItem label={item.label} value={item.value} key={`${item.label}${item.value}`} />
          ))}
        </List>
      </Grid.Col>
      <Grid.Col md={6} sm={12}>
        <List listStyleType='none' className={classes.list}>
          {rightColumn.map((item) => (
            <ListItem label={item.label} value={item.value} key={`${item.label}${item.value}`} />
          ))}
        </List>
      </Grid.Col>
    </Grid>
  )
}

export const DashboardDetailedModal = (props: any) => {
  const {
    data,
    colDef,
    children,
  }: {
    data: DashboardDetailedModalData & { campaignName: string }
    colDef: ColDef[]
    children?: React.ReactNode
  } = props

  const { classes } = useStyles()
  const { colDef: _, ...rest } = props
  const currency = colDef?.find((c: any) => c.key === 'companyAnnualRevenue')?.currency || 'USD'

  return (
    <Modal.Root {...rest} size='70%'>
      <Modal.Overlay />
      <Modal.Content p='xl'>
        <Modal.CloseButton className={classes.closeButton} />
        <Header data={data} currency={currency} />
        <Divider my='sm' className={classes.divider} />
        <ModalStepper {...data} />
        <Modal.Body>
          <Content {...data} />
          {children}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
