import { datadogRum } from '@datadog/browser-rum'
import * as Auth from 'aws-amplify/auth'

export * from './hooks'

export async function setDataDogUser(session: Auth.AuthSession) {
  const email = session?.tokens?.idToken?.payload?.email

  let userEmail =
    // try to get the email from the session,
    (email ? email.toString() : null) ||
    // if not get it from the current user
    (await Auth.getCurrentUser()).username ||
    // finally get it from the local storage
    localStorage.getItem('lastUserEmail')

  if (!userEmail) {
    console.error('No user email found. Unable to set user in DataDog.', session)
    return
  }

  localStorage.setItem('lastUserEmail', userEmail)
  datadogRum.setUserProperty('email', userEmail)
  if (userEmail.includes('@')) {
    datadogRum.setUserProperty('name', userEmail.split('@')[0])
    datadogRum.setUserProperty('company', userEmail.split('@')[1])
  }
}
