import { useState } from 'react'
import { useQuery } from 'react-query'
import { ListSalesInvoiceResponse, SalesInvoice } from '@ospace/schemas'
import { API } from '@ospace/shared'

const DEFAULT_PAGE_LIMIT = 500
export const useSalesDashBoard = (clientId: number, vendorId: string, JIWARegion: string) => {
  const [progress, setProgress] = useState(0)
  const [total, setTotal] = useState(0)
  const result = useQuery<SalesInvoice[]>({
    queryKey: ['client', clientId, 'sales-dashboard', vendorId],
    retry: false,
    queryFn: () =>
      fetchSalesDashBoard(clientId, JIWARegion, setTotal, setProgress, total, vendorId),
    // The query will not execute until the userId exists
    enabled: !!vendorId,
  })

  return {
    ...result,
    progress,
    total,
  }
}

const fetchSalesDashBoard = async (
  clientId: number,
  JIWARegion: string,
  setTotal: (total: number) => void,
  setProgress: (progress: number) => void,
  total: number,
  vendorId: string
) => {
  let accumulated: SalesInvoice[] = []
  let nextPage: number | undefined | null = null
  let result: ListSalesInvoiceResponse

  do {
    result = await API.get('client', `/clients/${clientId}/sales-dashboard`, {
      queryStringParameters: {
        limit: DEFAULT_PAGE_LIMIT,
        page: nextPage || '',
        vendorId,
        JIWARegion,
      },
    })

    nextPage = result.nextPage
    if (result.totalRecordCount && !total) {
      setTotal(result.totalRecordCount)
    }
    accumulated = [...accumulated, ...result.salesInvoices]
    setProgress(accumulated.length)
  } while (result.nextPage)

  return accumulated
}
