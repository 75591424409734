import { Avatar, Group, Paper, Text, ThemeIcon } from '@mantine/core'
import { Member } from '@ospace/schemas'
import { IconAt, IconPhone } from '@tabler/icons-react'

export function MemberBox({ user }: { user: Member }) {
  return (
    <Paper radius='md' withBorder p='xl' bg={'#fff'}>
      <Avatar src={user.signedAvatarUrl} size={120} radius={120} mx='auto' />
      <Text ta='center' fz='md' fw={500} mt='xl' transform={'uppercase'}>
        {user.role}
      </Text>
      <Text ta='center' fz='xl' fw={900} my='xs' c={'gray.7'}>
        {user.name}
      </Text>
      {user.contactNumber ? (
        <Group align='center' position='center' my={'xs'} ml={-20}>
          <ThemeIcon c={'dimmed'} variant='white'>
            <IconPhone />
          </ThemeIcon>
          <Text fz='md'>{user.contactNumber}</Text>
        </Group>
      ) : (
        <></>
      )}

      {user.email ? (
        <Group align='center' position='center' my={'xs'} ml={-20}>
          <ThemeIcon c={'dimmed'} variant='white'>
            <IconAt />
          </ThemeIcon>
          <Text fz='md' style={{ lineBreak: 'anywhere' }}>
            {user.email}
          </Text>
        </Group>
      ) : (
        <></>
      )}
    </Paper>
  )
}
