import { Opportunity } from '@ospace/schemas'

import { Duration } from './duration'
import { dateFilter, opportunityFilter } from './filters'

interface DateFilter {
  startDate: string
  closeDate: string
  duration: Duration
}
export const totalCreatedPipelineFilter = ({ startDate, duration }: DateFilter) =>
  dateFilter({ date: startDate, duration })
const totalRevenueFilter = ({ closeDate, duration }: DateFilter) =>
  dateFilter({ date: closeDate, duration })

const dealStatsByDateFilter = ({
  opportunities,
  duration,
  dateFilter,
}: {
  opportunities: Opportunity[]
  duration: Duration
  dateFilter: (d: DateFilter) => boolean
}) => {
  let totalDeals = 0
  let dealReg = 0
  let dealRegCount = 0
  const filteredOpportunities = opportunities.filter(
    (opportunity) =>
      opportunityFilter(opportunity) &&
      dateFilter({
        closeDate: opportunity.closeDate || '',
        startDate: opportunity.startDate,
        duration,
      })
  )

  const totalValue = filteredOpportunities.reduce((sum, opportunity) => {
    totalDeals++
    if (opportunity.dealRegStatus === 'Approved') {
      dealRegCount++
      dealReg += +(opportunity.calculatedBuyPrice || 0)
    }
    return sum + +(opportunity.calculatedBuyPrice || 0)
  }, 0)
  return {
    opportunities: filteredOpportunities,
    totalValue,
    totalDeals,
    dealRegCount,
    dealReg,
    percent: Math.round((dealReg / totalValue) * 100 || 0),
  }
}

export const totalCreatedPipeline = ({
  opportunities,
  duration,
}: {
  duration: Duration
  opportunities: Opportunity[]
}) => {
  return dealStatsByDateFilter({
    opportunities,
    duration,
    dateFilter: totalCreatedPipelineFilter,
  })
}

export const totalRevenue = ({
  opportunities,
  duration,
}: {
  duration: Duration
  opportunities: Opportunity[]
}) => {
  return dealStatsByDateFilter({
    opportunities,
    duration,
    dateFilter: totalRevenueFilter,
  })
}
