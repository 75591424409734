import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { useClientCampaign } from '@ospace/campaign'
import { FilterSettings, ListOpportunitiesResponse, Opportunity } from '@ospace/schemas'
import { useNotificationContext } from '@ospace/shared'
import { API } from '@ospace/shared'

const DEFAULT_INITIAL_REQUEST_INTERVAL_MS = 200
export const DEFAULT_PAGE_LIMIT = 500

type Opts = {
  clientId: number
  enabled: boolean
  limit?: number
  initialRequestInterval?: number
}

async function* list(opts: Opts) {
  const {
    limit = DEFAULT_PAGE_LIMIT,
    initialRequestInterval = DEFAULT_INITIAL_REQUEST_INTERVAL_MS,
    clientId,
  } = opts
  let requestTimeout: Promise<void> = Promise.resolve()
  let requestInterval = initialRequestInterval
  let pageToken: null | string = ''
  let hasRun = false

  while (!hasRun || pageToken) {
    await requestTimeout
    requestTimeout = waitForMs(requestInterval)

    try {
      const pageResp: ListOpportunitiesResponse = await API.get(
        'client',
        `/clients/${clientId}/opportunity?limit=${limit}&pageToken=${pageToken}`,
        {
          // queryStringParameters: { limit, pageToken },
        }
      )
      hasRun = true
      pageToken = pageResp.nextPageToken || null
      yield pageResp
    } catch (err: any) {
      // backoff and retry 3 times on rate limit
      if (err?.response?.status === 429 && requestInterval < initialRequestInterval * 8) {
        requestInterval = 2 * requestInterval
      } else {
        throw err
      }
    }
  }
}

export const useOpportunitiesProgress = () => {
  const [progress, setProgress] = useState(0)
  const [total, setTotal] = useState(0)
  return {
    progress,
    total,
    setProgress,
    setTotal,
  }
}

const fetchOpportunities =
  ({
    setProgress,
    setTotal,
    total,
  }: {
    setProgress: (progress: number) => void
    setTotal: (total: number) => void
    total: number
  }) =>
  async (opts: Opts): Promise<Opportunity[]> => {
    let opportunities: Opportunity[] = []
    for await (const opportunityPage of list(opts)) {
      if (!total && opportunityPage.count) {
        setTotal(opportunityPage.count)
      }
      opportunities = [...opportunities, ...opportunityPage.opportunities]
      setProgress(opportunities.length)
    }
    return opportunities
  }

export const useOpportunities = (
  opts: Opts
): UseQueryResult<Opportunity[]> & {
  progress: number
  total: number
} => {
  const clientCampaignResult = useClientCampaign(opts.clientId, true)
  const { progress, total, setProgress, setTotal } = useOpportunitiesProgress()
  const result = useQuery({
    queryKey: ['client', opts.clientId, 'distributionPipeline'],
    retry: false,
    queryFn: () =>
      fetchOpportunities({
        setProgress,
        setTotal,
        total,
      })(opts),
    enabled: opts.enabled === undefined ? true : opts.enabled,
  })
  if (result.status !== 'success') return { ...result, progress, total }

  // wait for clientCampaigns to load
  // if (clientCampaignResult.status === 'loading') {
  //   return clientCampaignResult as UseQueryResult<Opportunity[], any>
  // }

  // once both hooks are loaded, populate programName. On error of clientCampaignResult, treat it the same as no campaign found
  return {
    ...result,
    progress,
    total,
    data: result.data.map((opportunity) => {
      if (opportunity.programId) {
        const campaign = clientCampaignResult.data?.find(
          (campaign) => campaign.id === Number(opportunity.programId)
        )
        if (campaign) {
          return {
            ...opportunity,
            programName: campaign.name,
          }
        }
        // if campaign not found, remove the invalid programId
        return {
          ...opportunity,
          programId: null,
          programName: null,
        }
      }
      return opportunity
    }),
  }
}

const waitForMs = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms))

type OpportunitiesFilterSettings =
  | {
      status: 'loading'
    }
  | {
      status: 'ready'
      filterSettings: FilterSettings
      updateFilterSettings: (filterSettings: FilterSettings) => void
    }
export const useOpportunitiesFilterSettings = (opts: {
  clientId: number
}): OpportunitiesFilterSettings => {
  const [state, updateState] = useState({
    status: 'loading' as 'loading' | 'ready',
    filterSettings: {} as FilterSettings,
  })
  const queryKey = ['client', opts.clientId, 'distributionPipelineFilterSettings']
  const queryClient = useQueryClient()

  const mutation = useMutation(async (filterSettings: FilterSettings) => {
    await API.put('client', `/clients/${opts.clientId}/opportunity-filter-settings`, {
      body: filterSettings,
    })
    queryClient.setQueryData(queryKey, filterSettings)
  })

  const resp = useQuery({
    queryKey,
    retry: false,
    queryFn: async () => {
      return await API.get('client', `/clients/${opts.clientId}/opportunity-filter-settings`, {})
    },
    onError: (err: any) => {
      console.error(err)
    },
  })

  useEffect(() => {
    if (state.status !== 'ready' && resp.status === 'success') {
      updateState({ ...state, status: 'ready', filterSettings: resp.data })
    }
    if (state.status !== 'ready' && resp.status === 'error') {
      // on error, we want to proceed with default settings
      updateState({ ...state, status: 'ready' })
    }
    if (state.status !== 'loading' && (resp.status === 'loading' || resp.status === 'idle')) {
      // this will happen if you change clientId
      updateState({ status: 'loading', filterSettings: {} as FilterSettings })
    }
  }, [resp.status, resp.data, state])

  return {
    ...state,
    updateFilterSettings: (filterSettings: FilterSettings) => {
      updateState({ status: 'ready', filterSettings })
      mutation.mutate(filterSettings)
    },
  }
}

export const useInvalidateOpportunitiesCache = (opts: { clientId: number }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('client', `/clients/${opts.clientId}/opportunity-invalidate-vendor-cache`, {})
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['client', opts.clientId, 'distributionPipeline'],
      })
      setNotification({
        active: true,
        type: 'success',
        message: 'Distribution Pipeline cache invalidated',
      })
    },
  })

  return mutation
}
