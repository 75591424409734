import { useState } from 'react'
import clsx from 'clsx'

export const PasswordField = ({ field, form }: any) => {
  const [showHidePassword, changeShowHidePassword] = useState(false)
  const hasError = form.touched[field.name] && form.errors[field.name]

  return (
    <div className='input-group input-group-lg'>
      <input
        type={showHidePassword ? 'text' : 'password'}
        {...field}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          hasError ? 'input-error input-field' : 'input-field'
        )}
      />
      <div className='input-group-prepend'>
        <span
          className='input-group-text form-control-lg form-control-solid border-0'
          onClick={() => changeShowHidePassword(!showHidePassword)}
        >
          <i className='bi bi-eye-slash-fill'></i>
        </span>
      </div>
    </div>
  )
}
