import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Skeleton, Title } from '@mantine/core'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { Notes, useNotesForPerson } from '@ospace/note'
import { ErrorBoundary } from '@ospace/shared'

import { CompanyBox } from '../components/CompanyBox'
import { PersonBox } from '../components/PersonBox'
import { PersonDetailedBox } from '../components/PersonDetailedBox'
import { useGetContact } from '../hooks/useContacts'
import { Company } from '../schemas/Company'

type ContactDetailPageProps = { campaignId: number; contactId: number }

const NotesBox = (props: { campaignId: number; contactId: number }) => {
  const { data: notes } = useNotesForPerson(props.campaignId, props.contactId)

  return notes?.length > 0 ? (
    <Row className={`card`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3'>Call notes</span>
        </h3>
      </div>
      <div
        className={`card-body`}
        style={{
          overflow: 'auto',
        }}
      >
        <Notes notes={notes} />
      </div>
    </Row>
  ) : (
    <></>
  )
}

export const ContactDetailPage = (props: ContactDetailPageProps) => {
  const { isFetching, data } = useGetContact(props.campaignId, props.contactId)
  const contact = data?.contact

  return (
    <CampaignPage>
      {isFetching ? (
        <Skeleton height={500} visible={isFetching} />
      ) : !contact ? (
        <Title>Contact {props.contactId} does not exist</Title>
      ) : (
        <Row>
          <Col sm={4}>
            <PersonBox contact={contact} />
          </Col>
          <Col sm={8}>
            <Row className={`card mb-4`}>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3'>Company Info</span>
                </h3>
              </div>
              <div className={`card-body`}>
                {contact?.company && <CompanyBox company={contact?.company as Company} />}
              </div>
            </Row>
            <Row className={`card mb-4`}>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3'>Contact Detailed Info</span>
                </h3>
              </div>
              <div className={`card-body`}>
                <PersonDetailedBox contact={contact} />
              </div>
            </Row>
            <NotesBox campaignId={props.campaignId} contactId={props.contactId} />
          </Col>
        </Row>
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)
  const contactId: number = Number(params?.contactId)

  return <ContactDetailPage campaignId={campaignId} contactId={contactId} />
}

export const ContactDetailPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
