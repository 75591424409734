import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { Center, Loader } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { useClients } from '@ospace/client'
import { DashboardPage } from '@ospace/client-dashboard'
import { userTypes } from '@ospace/core-auth'
import { ClientPageLayout, PageTitle, SelectedPage } from '@ospace/layout'
import { ClientsPage } from '@ospace/new-client'
import { ErrorBoundary } from '@ospace/shared'
import { useUser } from '@ospace/user'

import { CampaignCards } from '../components/CampaignCards'
import { getUserNavigationCategory } from '../utils'

// CampaignHomePage is for users who can only see campaigns
export const CampaignHomePage = (props: { role: userTypes }) => {
  const user = useUser()
  const client = useClients({
    nextPage: 0,
    pageLimit: 1,
    enabled: !!user.data,
  })

  return client.status === 'success' && user.status === 'success' ? (
    <ClientPageLayout
      status='ready'
      user={user.data}
      role={props.role}
      client={client.data?.clients[0] || user?.data?.clients[0]}
      subHeader={<PageTitle />}
      pageId='programs'
      pageTitle='Home'
    >
      <>
        <PageTitle>Home</PageTitle>
        <SelectedPage>Home</SelectedPage>
        <CampaignCards />
      </>
    </ClientPageLayout>
  ) : (
    <Center maw='100vh' h='100vh' mx='auto'>
      <Loader variant='bars' />
    </Center>
  )
}

const Controller = () => {
  const { data: user, status } = useUser()
  const role = useUserRole()

  if (status === 'loading') {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }
  if (status !== 'success') {
    return <Navigate to='/login' />
  }

  const navigationCategory = getUserNavigationCategory(user, role)
  switch (navigationCategory) {
    case 'external-multilpe-clients':
      return <ClientsPage />
    case 'external-single-client':
      return <DashboardPage clientId={user.clients[0]?.id} />
    case 'internal':
      return <ClientsPage />
    case 'campaign':
      return <CampaignHomePage role={role} />
    default:
      assertUnreachable(navigationCategory)
  }
}

export const HomePageRouteHandler = () => {
  return (
    <ErrorBoundary>
      <Controller />
    </ErrorBoundary>
  )
}

function assertUnreachable(_: never): never {
  throw new Error('unreachable')
}
