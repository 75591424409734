import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Contact } from '@ospace/contact'
import { Table } from '@ospace/shared'
import { Spoiler } from '@ospace/shared'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'

import { Note } from '../models/Note'

export type NoteProps = {
  notes: Note[]
}

export const Notes: React.FC<any> = (props: any) => {
  const { notes } = props

  const { pathname } = useLocation()
  const getCampaignUrl = (pathName: string) => {
    const contactPath = pathName.split('/')
    return `/${contactPath[1]}/${contactPath[2]}`
  }

  const columns: ColumnDef<Note>[] = [
    {
      accessorFn: (row: Note) => `${dayjs(row.createdAt).format('DD MMM,  YYYY')}`,
      id: 'createdAt',
      header: 'Date',
      cell: (info: any) => info.getValue(),
      size: 100,
    },
    {
      accessorFn: (row) => row.person,
      header: 'Full Name',
      cell: (info: any) => {
        const contact: Contact = info.getValue()
        return (
          <Link
            to={`${getCampaignUrl(pathname)}/contact/${contact.id}`}
            className='text-hover-priamry cursor-pointer'
          >
            {`${contact.firstName} ${contact.lastName}`}
          </Link>
        )
      },
    },
    {
      accessorFn: (row) => row.company,
      header: 'Company',
      cell: (info: any) => {
        const company = info.getValue()
        return company?.name
      },
    },
    {
      accessorFn: (row: Note) => row.person,
      id: 'jobTitle',
      header: 'Job Titile',
      cell: (info: any) => {
        const contact = info.getValue()
        return `${contact.jobTitle}`
      },
    },
    // {
    //   accessorKey: 'interactionType',
    //   header: 'Interation type',
    //   cell: (info: any) => {
    //     const status = info.getValue()
    //     return <span className='badge badge-light-success me-auto'>{status}</span>
    //   },
    //   size: 100,
    // },
    {
      accessorFn: (row: Note) => row.deal,
      id: 'status',
      header: 'Status',
      cell: (info: any) => {
        const deal = info.getValue()
        return `${deal.status}`
      },
    },
    {
      accessorFn: (row: Note) => row.body,
      id: 'body',
      header: 'Comments',
      cell: (info: any) => {
        const body = info.getValue()
        const rawMarkup = (rawMarkup: string) => {
          return { __html: rawMarkup }
        }

        return (
          <Spoiler lines={6}>
            <div dangerouslySetInnerHTML={rawMarkup(body)} />
          </Spoiler>
        )
      },
      size: 400,
    },
  ]
  return <Table data={notes} columns={columns} hideSearch={true} />
}
