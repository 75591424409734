import { useEffect, useRef, useState } from 'react'
import { Flex, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared/components/MoreInfo'

export const GraphHeader = ({
  title,
  legend,
  tooltip,
}: {
  title: string
  legend: string
  tooltip: string
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    function handleWindowResize() {
      if (!ref.current) return
      setWidth(ref.current.clientWidth)
    }
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <>
      <Flex top={20} left={20} pos='relative' ref={ref}>
        <Text tt='uppercase' pr={10} pt={3} fw={700} fz='12px'>
          {title}
        </Text>
        <MoreInfo info={tooltip} />
      </Flex>
      <Text
        top={10}
        tt='uppercase'
        pos='relative'
        fw={700}
        fz='md'
        style={{
          left: width - 308,
          width: 0,
        }}
      >
        {legend}
      </Text>
    </>
  )
}
