import { LeadsWidgetProps } from '@ospace/leads-dashboard'
import { IconChartHistogram } from '@tabler/icons-react'

import { LineGraphCard } from '../LineGraphCard'
export const TopCard = (item: LeadsWidgetProps) => {
  return (
    <LineGraphCard
      backgroundColor={'#4C6EF5'}
      iconBackgroundColor={'#4260D8'}
      icon={IconChartHistogram}
      heading={'LEADS'}
      {...item}
    />
  )
}
