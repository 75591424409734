import { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { useCampaign } from '@ospace/campaign/hooks'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { userTypes } from '@ospace/core-auth'
import { ErrorBoundary } from '@ospace/shared'
import { Table as ReactTable } from '@tanstack/react-table'

import { ContactsTable } from '../components/Contacts'
import { Contact } from '../schemas/Contact'

type Props = { campaignId: number }

export const ContactsPage = (props: Props) => {
  const { data: campaign } = useCampaign(props.campaignId)
  const role: any = useUserRole()
  const contactTableRef = useRef<ReactTable<Contact[]>>(null)
  const [rowSelection, setRowSelection] = useState({})
  const isPermitted =
    role === userTypes.Admin ||
    role === userTypes.ClientManager ||
    role === userTypes.CampaignManager

  return (
    <CampaignPage>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>All Contacts</span>
          </h3>
          <div className='card-toolbar'>
            <div className='card-toolbar'>
              {isPermitted && campaign && (
                <Link
                  to={`/campaign/${campaign.id}/contact/upload`}
                  className='btn btn-sm btn-primary me-3'
                >
                  Upload Contacts
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className='card-body'>
          <ContactsTable
            campaignId={props.campaignId}
            ref={contactTableRef}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </div>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)
  return <ContactsPage campaignId={campaignId} />
}

export const ContactsPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
