import { useParams } from 'react-router-dom'
import { ProgramLayout } from '@ospace/layout/components/ProgramLayout'
import { ErrorBoundary } from '@ospace/shared'

import OpportunitiesTable from '../../components/partials/dashboard/OpportunitiesTable'
import { useDeals } from '../../hooks'

type Props = { campaignId: number }
export const NewOverviewPage = (props: Props) => {
  const deals = useDeals(props.campaignId)

  return (
    <ProgramLayout status={deals.isFetching ? 'loading' : 'ready'} pageId='programs' fluid={true}>
      <OpportunitiesTable deals={deals} />
    </ProgramLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <NewOverviewPage campaignId={campaignId} />
}

export const NewOverviewPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
