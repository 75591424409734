import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Center, Loader, Text } from '@mantine/core'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { ClientSwitcher, PageTitle } from '@ospace/layout'
import { updateClientRequestSchema } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'

import { ClientForm } from '../components/ClientForm'
import { useClient, useUpdateClientMutation } from '../hooks'

type Props = { clientId: number }
export const EditClientPage = (props: Props) => {
  const navigate = useNavigate()
  const { status, data: client, error } = useClient(props.clientId)
  const mutation = useUpdateClientMutation({ clientId: props.clientId })

  return (
    <>
      <PageTitle>Edit Client</PageTitle>
      <ClientSwitcher client={client} />
      <Card>
        {status === 'loading' || status === 'idle' ? (
          <Center>
            <Loader variant='dots' />
          </Center>
        ) : status === 'error' ? (
          <Text color='red'>Error: {(error as any).message}</Text>
        ) : (
          <ClientForm
            clientId={props.clientId}
            initialValues={{
              ...client,
              logoFile: null as null | File,
              teamMembers: client.teamMembers || [],
            }}
            isSubmitting={mutation.isLoading}
            onSubmit={async (values: any) => {
              await mutation.mutateAsync({
                ...values,
                id: client?.id,
              })
              navigate(`/client/view/${client?.id}`)
            }}
            validationSchema={updateClientRequestSchema}
          />
        )}
      </Card>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager])
  const params: any = useParams()
  const clientId = Number(params?.id)
  return <EditClientPage clientId={clientId} />
}

export const EditClientPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
