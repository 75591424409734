import { useQuery } from 'react-query'
import { API } from '@ospace/shared'

export interface Currency {
  code: string
  name: string
}

export const useCurrency = () => {
  return useQuery(['currency'], async () => {
    return (await API.get('client', `/currency`, {})) as Currency[]
  })
}
