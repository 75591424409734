import React from 'react'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { Card, Center, Loader, Text } from '@mantine/core'
import { User } from '@ospace/auth'
import { PageTitle } from '@ospace/layout'
import { EditUserProfileRequest, editUserProfileSchema } from '@ospace/schemas'
import { Campaign, Client } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'

import { UserProfileEditForm } from '../components/UserProfileEditForm'
import { useUser } from '../hooks/useUser'
import { useUserProfileMutation } from '../hooks/useUserProfileMutation'

export const EditUserProfile: React.FC = () => {
  const query: UseQueryResult = useUser()
  const mutation: UseMutationResult<any, any, any, any> = useUserProfileMutation()
  const user: User = (query as any)?.data

  let initValues: EditUserProfileRequest = {} as EditUserProfileRequest

  if (query.isSuccess) {
    initValues = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle || '',
      email: user?.email,
      clients: user?.clients.map((client: Client) => client.id),
      campaigns: user?.campaigns.map((campaign: Campaign) => campaign.id),
      identityId: user?.identityId,
      avatar: user?.avatar,
      contactNumber: user?.contactNumber,
      signedAvatarUrl: user?.signedAvatarUrl,
      enableProgramNotifications: user.enableProgramNotifications,
      enableDashboardNotifications: user.enableDashboardNotifications,
    }
  }

  return (
    <>
      <PageTitle>Edit your profile</PageTitle>
      {query.isLoading || query.isFetching ? (
        <Center>
          <Loader variant='bars' />
        </Center>
      ) : query.status === 'error' ? (
        <Text color={'red'}>Error: {(query.error as any).message}</Text>
      ) : (
        <Card>
          <UserProfileEditForm
            initialValues={{
              ...initValues,
              avatarFile: null as null | File,
            }}
            validationSchema={editUserProfileSchema}
            isSubmitting={mutation.isLoading}
            onSubmit={async (values: any) => {
              await mutation.mutateAsync(values)
            }}
          />
        </Card>
      )}
    </>
  )
}

const EditUserProfileController: React.FC = () => {
  return <EditUserProfile />
}

export const EditUserProfilePageRouteHandler = () => (
  <ErrorBoundary>
    <EditUserProfileController />
  </ErrorBoundary>
)
