import { CreateUserRequest, EditUserRequest } from '@ospace/schemas'
import { API, UserStatus } from '@ospace/shared'

import { User } from '../types'
// TODO: circular import

function transformUser(user: any): User {
  const email = user.email || user.UserAttributes?.find((attr: any) => attr.Name === 'email')?.Value
  const firstName = user.firstName || 'Ospace'
  const lastName = user.lastName || 'User'
  const name = `${firstName} ${lastName}`
  const userStatus = UserStatus[user.UserStatus as keyof typeof UserStatus]

  return {
    ...user,
    email,
    firstName,
    lastName,
    name,
    userStatus,
    enabled: user.Enabled,
    userAttributes: user.UserAttributes,
  }
}
const getUsers = async () => {
  const users = await API.get('client', `/users`, {})
  return users.map((user: User) => transformUser(user))
}

const getExternalUsers = async () => {
  const users = await API.get('client', `/users/external`, {})
  return users.map((user: User) => transformUser(user))
}

const getUser = async (identityId: string) => {
  const user = await API.get('client', `/users/${identityId}`, {})
  return transformUser(user)
}

const createUser = async (user: CreateUserRequest) => {
  return API.post('client', `/users`, {
    body: {
      username: user.email,
      ...user,
    },
  })
}

const editUser = async (identityId: string, user: EditUserRequest) => {
  return API.put('client', `/users/${identityId}`, {
    body: user,
  })
}

const removeUser = async (identityId: string) => {
  return API.post('client', `/users/${identityId}/remove`, {})
}

const restoreUser = async (identityId: string) => {
  return API.post('client', `/users/${identityId}/restore`, {})
}

const createSSOUser = (user: any) => {
  return API.post('client', `/users:create-sso`, {
    body: user,
  })
}

const confirmUser = async (passwords: any) => {
  return API.post('public', `/users:confirm`, {
    body: passwords,
  })
}

const forgotPassword = async (passwordDTO: any) => {
  return API.post('public', `/users:forgot-password`, {
    body: passwordDTO,
  })
}

const confirmForgotPassword = async (passwordDTO: any) => {
  return API.post('public', `/users:confirm-forgot-password`, {
    body: passwordDTO,
  })
}

const inviteUser = async (username: string) => {
  return API.post('client', `/users:invite`, {
    body: {
      username,
    },
  })
}

const changeUserStatus = async (username: string, status: boolean) => {
  return API.patch('client', `/users/${username}/enabled`, {
    body: {
      enabled: status,
    },
  })
}

const editProfile = async (username: string, user: Partial<EditUserRequest>) => {
  return API.put('client', `/users/${username}`, {
    body: user,
  })
}

export const UserService = {
  getUser,
  getUsers,
  getExternalUsers,
  createUser,
  createSSOUser,
  changeUserStatus,
  confirmUser,
  forgotPassword,
  confirmForgotPassword,
  editUser,
  inviteUser,
  editProfile,
  removeUser,
  restoreUser,
}
