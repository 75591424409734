import React from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { Group, Indicator } from '@mantine/core'
import { Form } from 'formik'
import { ErrorMessage, Field } from 'formik'

// Update file name when template is updated
// File location: /public/
const CONTACT_UPLOAD_TEMPLATE = 'contact-upload-template.v1.csv'

export const ContactUploadForm: React.FC<any> = (props: any) => {
  const { mutation, form, cancelLink } = props
  // cookie to store the contact upload template version
  // this is used to indicate to the user that there is a new template
  const [cookies, setCookie] = useCookies(['contactUploadTemplate'])
  return (
    <CookiesProvider>
      <Form className='form' noValidate id='kt_upload_contact_form'>
        <div className='card-body fv-row mb-10'>
          <div className='mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Upload Date</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify upload date'
              ></i>
            </label>

            <Field
              type='date'
              className='form-control form-control-lg form-control-solid'
              name='uploadDate'
              placeholder=''
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='uploadDate' />
            </div>
          </div>

          <div className='mb-10'>
            <Group position='apart'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>File to upload(CSV)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Choose the csv file to upload and import contacts'
                ></i>
              </label>
              {cookies.contactUploadTemplate}
              <Indicator
                size={
                  cookies.contactUploadTemplate === CONTACT_UPLOAD_TEMPLATE
                    ? 0 // hide indicator
                    : 20 // indicate to the user that there is a new template
                }
                label='New'
                offset={-7}
              >
                <a
                  href={`/${CONTACT_UPLOAD_TEMPLATE}`}
                  download
                  onClick={() => {
                    setCookie('contactUploadTemplate', CONTACT_UPLOAD_TEMPLATE)
                  }}
                >
                  Contact upload template
                </a>
              </Indicator>
            </Group>

            <Field
              type='file'
              className='form-control form-control-lg form-control-solid'
              name='csv'
              placeholder=''
              value={undefined}
              onChange={(event: any) => {
                form.setFieldValue('file', event.currentTarget.files[0])
                form.setFieldValue('csv', event.currentTarget.files[0].name)
              }}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='csv' />
            </div>
          </div>

          <div className='mb-10'>
            <div className='mb-0 fv-row'>
              <label className='d-flex align-items-center form-label mb-5'>
                Select Account Type
              </label>

              <div className='mb-0'>
                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        Net New
                      </span>
                      <span className='fs-6 fw-bold text-gray-400'>
                        Contacts found for newly discovered accounts
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name='accountType'
                      value='Net New'
                    />
                  </span>
                </label>

                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        Target Account
                      </span>
                      <span className='fs-6 fw-bold text-gray-400'>
                        Contacts found for targeted accounts
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name='accountType'
                      value='Target Account'
                    />
                  </span>
                </label>
              </div>

              <div className='text-danger mt-2'>
                <ErrorMessage name='accountType' />
              </div>
            </div>
          </div>

          <div className='card-footer'>
            <button type='submit' className='btn btn-primary' disabled={mutation.isLoading}>
              {mutation.isLoading && (
                <span className='spinner-border spinner-border-sm align-middle mx-2' />
              )}
              Submit
            </button>
            <Link to={cancelLink} className='btn btn-secondary m-10'>
              Cancel
            </Link>
          </div>
        </div>
      </Form>
    </CookiesProvider>
  )
}
