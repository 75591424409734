import { Paper } from '@mantine/core'
import { ResponsiveBar } from '@nivo/bar'
import { Duration } from '@ospace/common'

import { GraphHeader } from './GraphHeader'

export const plotChartByMonth =
  (duration: Duration) => (plotY: (segment: string) => Record<string, number>) => {
    // is it the same year
    const sameYear =
      new Date(duration.dates.start).getFullYear() === new Date(duration.dates.end).getFullYear()

    const getMonth = (date: string) => {
      const month = new Date(date).toLocaleString('default', { month: 'short' })
      const year = new Date(date).toLocaleString('default', { year: 'numeric' })
      const key = `${month} ${year}`
      return sameYear ? new Date(key).toLocaleString('default', { month: 'short' }) : key
    }
    return duration.segments.map((segment) => ({
      // x-axis
      month: getMonth(segment),
      // stacked bar charts
      ...plotY(segment),
    }))
  }

export const BarChart = ({
  data,
  indexBy,
  keys,
  colors,
  tooltip,
  axisLeftFormat,
  label,
  legendItemWidth,
  barChartTooltip,
  title,
}: {
  title: string
  barChartTooltip: string
  data: { [key: string]: any }[]
  indexBy: string
  keys: string[]
  colors: ({ id }: { id: string | number }) => string
  tooltip: ({ value, color }: { value: number; color: string }) => JSX.Element
  axisLeftFormat: (value: number) => string
  label: (item: any) => string
  legendItemWidth: number
}) => {
  return (
    <>
      <Paper
        id='bar-chart'
        withBorder
        p='sm'
        w='100%'
        h='500px'
        bg='white'
        maw='unset'
        c='dimmed'
        radius='md'
      >
        <GraphHeader title={title} legend='' tooltip={barChartTooltip} />
        <ResponsiveBar
          data={data}
          indexBy={indexBy}
          keys={keys}
          margin={{ top: 60, right: 50, bottom: 80, left: 50 }} // this is the margin of the chart itself, not the container
          padding={0.7}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={colors}
          tooltip={tooltip}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 15,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: axisLeftFormat,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor='white'
          enableLabel={true}
          legendLabel={({ id }) => id.toString().toUpperCase()}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -50,
              itemsSpacing: 2,
              itemWidth: legendItemWidth,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 15,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          label={label}
          role='application'
        />
      </Paper>
    </>
  )
}
