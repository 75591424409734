import { Box, Flex, Skeleton, Space } from '@mantine/core'
const SkeletonLoader = () => (
  <Box p='xl'>
    <Flex justify='center' align='center'>
      <Skeleton height={90} radius='sm' width='50%' />
      <Space w='md' />
      <Skeleton height={90} radius='sm' width='50%' />
    </Flex>
    <Skeleton height={600} mt='md' radius='sm' />
    <Skeleton height={200} mt='md' radius='sm' />
  </Box>
)

export default SkeletonLoader
