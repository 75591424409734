import { DefaultProps, Flex, Grid, Group, Stack, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared'

interface Props extends DefaultProps {
  labelColor?: string
  left: {
    label: string
    value: string | number | any
    tooltip?: string
  }
  right: {
    label: string
    value: string | number | any
    tooltip?: string
  }
}

export const SideBySideFigures = ({
  labelColor = 'dimmed',
  left: { label: leftLabel, value: leftValue, tooltip: leftTooltip },
  right: { label: rightLabel, value: rightValue, tooltip: rightTooltip },
  ...cssProps
}: Props) => {
  return (
    <Grid gutter={15} c='gray.7' {...cssProps}>
      <Grid.Col span={6}>
        <Stack spacing={0}>
          <Group position='left' c={labelColor}>
            <Text fz='md'>{leftLabel}</Text>
            {leftTooltip && <MoreInfo info={leftTooltip} iconColor={labelColor} />}
          </Group>
          <Text fz='24px' fw={700}>
            {leftValue}
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={6}>
        <Flex justify='flex-end'>
          <Stack spacing={0}>
            <Group position='left' c={labelColor}>
              <Text fz='md'>{rightLabel}</Text>
              {rightTooltip && <MoreInfo info={rightTooltip} iconColor={labelColor} />}
            </Group>
            <Flex justify='flex-end'>
              <Text fz='24px' fw={700}>
                {rightValue}
              </Text>
            </Flex>
          </Stack>
        </Flex>
      </Grid.Col>
    </Grid>
  )
}
