import { useMutation, useQueryClient } from 'react-query'
import { UserService } from '@ospace/auth'
import { EditUserProfileRequest } from '@ospace/schemas'
import { Storage, useNotificationContext } from '@ospace/shared'

export const useUserMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (user: EditUserProfileRequest & { avatarFile: File }) => {
      if (user.avatarFile) {
        await Storage().put(`users/${user.identityId}/${user.avatarFile.name}`, user.avatarFile)
      }

      return await UserService.editUser(
        user?.identityId as string,
        {
          ...user,
          avatar: user.avatarFile ? `users/${user.identityId}/${user.avatarFile.name}` : null,
        } as any
      )
    },
    {
      onSuccess: (data: any) => {
        setNotification({
          active: true,
          message: 'You have successfully updated user profile.',
          type: 'success',
        })
        queryClient.invalidateQueries(['user', data?.identityId])
        queryClient.refetchQueries(['users'])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response.data || e.message,
          type: 'danger',
        })
      },
    }
  )
}

export const useUserRemoveRestoreMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (
      user: EditUserProfileRequest & {
        remove: boolean
      }
    ) => {
      await UserService.changeUserStatus(user?.identityId as string, !user.remove)
      if (user.remove) {
        return await UserService.removeUser(user?.identityId as string)
      }
      return await UserService.restoreUser(user?.identityId as string)
    },
    {
      onSuccess: ({ identityId, action }: { identityId: string; action: string }) => {
        setNotification({
          active: true,
          message: `You have successfully ${action} user.`,
          type: 'success',
        })
        queryClient.invalidateQueries(['user', identityId])
        queryClient.setQueryData(['users'], (users: any) => {
          return users.map((u: any) =>
            u.identityId === identityId
              ? {
                  ...u,
                  enabled: action === 'restore',
                  deletedAt: action === 'restore' ? null : new Date().toString(),
                }
              : u
          )
        })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response.data || e.message,
          type: 'danger',
        })
      },
    }
  )
}
