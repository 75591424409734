import React from 'react'
import { Center, Grid, RingProgress, Stack, Text } from '@mantine/core'
import { MantineColor } from '@mantine/styles'

export const CircularChart = ({
  sections,
  total,
}: {
  sections: { value: number; color: MantineColor; tooltip?: React.ReactNode }[]
  total: string
}) => {
  return (
    <Center mt={10}>
      <RingProgress
        size={180}
        roundCaps
        thickness={12}
        sections={sections}
        label={
          <Center>
            <Text fw={700} fz='21px' c='gray.7'>
              {total}
            </Text>
          </Center>
        }
      />
    </Center>
  )
}

const VerticalCard = ({
  ratioData,
}: {
  ratioData: {
    color: string
    label: string
    value: string | JSX.Element
  }[]
}) => {
  return (
    <Stack mih='100px' fw={700} c='gray.7' justify='center' spacing='lg'>
      {ratioData.map(({ color, label, value }) => (
        <div
          style={{
            borderLeft: `6px solid ${color}`,
          }}
          key={label}
        >
          <Text pl={10} fz='12px' c='#868E96'>
            {label}
          </Text>
          <Text pl={10} fz='19px'>
            {value}
          </Text>
        </div>
      ))}
    </Stack>
  )
}
export const CircularRatioChart = ({
  ratioData,
  nextgenInitiatedPercentage,
  partnerInitiatedPercentage,
  total,
}: {
  ratioData: {
    color: string
    label: string
    value: string | JSX.Element
  }[]
  total: string
  nextgenInitiatedPercentage: number
  partnerInitiatedPercentage: number
}) => {
  const COLORS = ratioData.reduce(
    (acc, { color, label }) => {
      acc[label] = color
      return acc
    },
    {} as Record<string, string>
  )

  const sections = [
    { value: nextgenInitiatedPercentage || 0, color: COLORS['NEXTGEN Initiated'] },
    {
      value: partnerInitiatedPercentage || 0,
      color: COLORS['Partner Initiated'],
    },
    {
      value: 100 - (nextgenInitiatedPercentage + partnerInitiatedPercentage),
      color: COLORS['Vendor Initiated'],
    },
  ]
  return (
    <Grid gutter={15} mt='sm'>
      <Grid.Col span={6}>
        <VerticalCard ratioData={ratioData} />
      </Grid.Col>
      <Grid.Col span={6}>
        <CircularChart sections={sections} total={total} />
      </Grid.Col>
    </Grid>
  )
}
