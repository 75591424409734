import { useQuery } from 'react-query'
import { PaginationState } from '@tanstack/react-table'

import { Contact } from '../schemas/Contact'
import { ContactService } from '../services'

export const useContacts = (campaignId: number, pagination: PaginationState, filter?: string) => {
  return useQuery<{
    records: Contact[]
    recordCount: number
  }>({
    queryKey: ['contacts', { campaignId, pagination, filter }],
    queryFn: () => ContactService.getContactsForCampaign(campaignId, pagination, filter),
    keepPreviousData: true, // don't have 0 rows flash while changing pages/loading next page
  })
}

export const useGetContact = (campaignId: number, contactId: number) => {
  return useQuery<{
    contact: Contact | undefined
  }>({
    queryKey: ['contact-get', campaignId, contactId],
    queryFn: () => ContactService.getContact(campaignId, contactId),
  })
}

export const useContactsForCompanies = (campaignId: number, companyId: number) => {
  return useQuery(['contacts-companies', `${campaignId} ${companyId}`], () =>
    ContactService.getContactsForCompanies(campaignId, companyId)
  )
}

export const useCompanies = (campaignId: number) => {
  return useQuery(['companies', campaignId], () =>
    ContactService.getCompaniesForCampaign(campaignId)
  )
}
