import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert } from '@mantine/core'
import { useCheckRole } from '@ospace/auth'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'

import { useContactUpload } from '../hooks/useContactUpload'
import { useContactUploadStatus } from '../hooks/useContactUploadStatus'

type Props = {
  importId: number
  campaignId: number
}

const DEFAULT_INTERVAL_SECONDS = 10
const MAX_WAIT_INTERVAL = 50
const WARNING_MESSAGE = 'No upload process have started.'

export const ContactUploadStatusPage = ({ importId, campaignId }: Props) => {
  const [intervalMs, setIntervalMs] = useState<number | false>(DEFAULT_INTERVAL_SECONDS * 1000)
  const [refetchingCounter, SetRefetchingCounter] = useState<number>(0)
  const { isLoading, isRefetching, data: status } = useContactUploadStatus(importId, intervalMs)
  const contactUpload = useContactUpload(importId)

  useEffect(() => {
    if (status && status?.importSize > 0) {
      SetRefetchingCounter(0)
    }
    if ((status?.terminated && status.importSize > 0) || refetchingCounter > MAX_WAIT_INTERVAL) {
      setIntervalMs(false)
    }
  }, [isLoading, refetchingCounter, status])

  useEffect(() => {
    if (!contactUpload?.isLoading && !contactUpload?.data?.id) {
      setIntervalMs(false)
    }
  }, [contactUpload])

  useEffect(() => {
    if (isRefetching) {
      SetRefetchingCounter((r) => r + 1)
    }
  }, [isRefetching])

  return (
    <CampaignPage>
      <PageTitle>Upload Contacts Status</PageTitle>
      <div className='card' style={{ minHeight: 200 }}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Hubspot Contacts Upload: {contactUpload?.data?.fileName}
            </span>
          </h3>
        </div>
        <div
          style={{
            padding: '0 2.25rem',
            paddingTop: '1.5rem',
          }}
        >
          {!contactUpload?.isLoading && !contactUpload?.data?.id ? (
            <div>Import process #{importId} does not exist.</div>
          ) : (
            <>
              {isLoading || !status || status.importSize === 0 ? (
                <>
                  <div className='progress' style={{ height: 20 }}>
                    <div
                      className={`progress-bar bg-warning progress-bar-striped progress-bar-animated bg-primary`}
                      role='progressbar'
                      style={{ width: `100%`, fontWeight: 'bold' }}
                    >
                      Loading progress status
                    </div>
                  </div>
                  <div style={{ paddingTop: '1.5rem' }}>
                    {refetchingCounter > MAX_WAIT_INTERVAL && WARNING_MESSAGE}
                  </div>
                </>
              ) : (
                <>
                  {status.terminated ? (
                    <Alert variant='light' color='green' title='Contacts processed successfully'>
                      All {status.importSize} contacts have been processed successfully. You can now
                      view them in the contacts page tomorrow.
                    </Alert>
                  ) : (
                    <div className='progress' style={{ height: 20 }}>
                      <div
                        className={`progress-bar progress-bar-striped progress-bar-animated bg-primary`}
                        role='progressbar'
                        style={{ width: `100%`, fontWeight: 'bold', color: 'white' }}
                      >
                        In progress
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  const params: any = useParams()
  return <ContactUploadStatusPage importId={params.importId} campaignId={params.id} />
}

export const ContactUploadStatusPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
