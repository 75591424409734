import { ActionIcon, Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'

export const MoreInfo = ({ info, iconColor }: { info: string; iconColor?: string }) => (
  <Tooltip
    multiline
    width={150}
    color='black'
    withArrow
    position='bottom'
    transitionProps={{ duration: 200 }}
    label={info}
  >
    <ActionIcon>
      <IconInfoCircle
        size='1.125rem'
        style={{
          color: iconColor || 'inherit',
        }}
      />
    </ActionIcon>
  </Tooltip>
)
