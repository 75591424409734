import { useRef } from 'react'

import { Button } from './ui'

// The default file input is very ugly, and needs some fancy footwork to make
// it look good. This method is from MDN
// https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications#using_hidden_file_input_elements_using_the_click_method

export const UploadFilesButton = (props: {
  disabled?: boolean
  submitting?: boolean
  onChange: (file: File[]) => void
}) => {
  const el: any = useRef(null)
  const handleButtonClick = () => {
    if (el !== null) {
      el.current?.click()
    }
  }
  return (
    <>
      <Button
        onClick={() => handleButtonClick()}
        size='sm'
        variant='secondary'
        disabled={props.disabled}
        submitting={props.submitting}
      >
        Upload Files
      </Button>
      <input
        type='file'
        name='file'
        multiple
        onChange={(e) => {
          if (e.target.files) {
            props.onChange(Array.from(e.target.files))
          }
        }}
        value={''}
        style={{ display: 'none' }}
        ref={el}
      />
    </>
  )
}
