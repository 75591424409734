import { createContext, ReactChild, useContext, useReducer } from 'react'

import { AuthReducer, initialState } from '../reducers'

export type TAuthContext = {
  session: object | undefined
  loading: boolean
  dispatch: Function
}

export const AuthContext = createContext<TAuthContext>({
  session: undefined,
  loading: true,
  dispatch: () => {},
})

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('`useAuth` must be within a `AuthProvider`')
  }
  return context
}

export const AuthProvider = ({ children }: { children: ReactChild }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  return (
    <AuthContext.Provider
      value={{
        session: state.session,
        loading: state.loading,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
