import { Card, createStyles, Flex, Group, Text } from '@mantine/core'
import { LineChart } from '@ospace/leads-dashboard'
import { MoreInfo } from '@ospace/shared'
import { Icon } from '@tabler/icons-react'

import { SideBySideFigures } from './SideBySideFigures'

const useStyles = ({
  backgroundColor,
  iconBackgroundColor,
}: {
  backgroundColor: string
  iconBackgroundColor: string
}) =>
  createStyles(() => ({
    card: {
      backgroundColor: backgroundColor,
      color: 'white',
    },
    icon: {
      padding: 6,
      borderRadius: 6,
      backgroundColor: iconBackgroundColor,
      color: 'white',
    },
    textSmall: {
      fontSize: 12,
    },
    textLarge: {
      fontSize: 30,
    },
    detailedCard: {
      fontSize: '18px',
      height: '500px',
    },
    bar: {
      borderLeft: '6px solid #0CA678',
    },
    value: {
      fontSize: 30,
    },
  }))

export const LineGraphCard = ({
  icon: Icon,
  heading,
  title,
  tooltip,
  data,
  chartLabels,
  right,
  left,
  backgroundColor,
  iconBackgroundColor,
}: {
  backgroundColor: string
  iconBackgroundColor: string
  icon: Icon
  heading: string
  title: string
  tooltip?: string
  data?: { x: string; y: number }[]
  chartLabels?: { leftLabel: string; rightLabel: string }
  left: { label: string; value: string | number | JSX.Element }
  right: { label: string; value: string | number | JSX.Element }
}) => {
  const { classes } = useStyles({ backgroundColor, iconBackgroundColor })()

  return (
    <Card className={classes.card} withBorder shadow='sm' padding='lg' radius='md'>
      <Group>
        <div className={classes.icon}>
          <Icon />
        </div>
        <Text fw={700}>{heading}</Text>
      </Group>
      <Group mt='lg' position='left'>
        <Text tt='uppercase' fw={600} fz='md'>
          {title}
        </Text>
        {tooltip && <MoreInfo info={tooltip} iconColor='white' />}
      </Group>
      <SideBySideFigures labelColor='white' c='white' mt={1} left={left} right={right} />
      {data && chartLabels && (
        <>
          <LineChart
            data={[
              {
                id: 'pipeline',
                color: 'white',
                data,
              },
            ]}
            enableArea={true}
          />
          <Group position='apart'>
            <Flex justify={`flex-start`}>{chartLabels.leftLabel}</Flex>
            <Flex justify={`flex-start`}>{chartLabels.rightLabel}</Flex>
          </Group>
        </>
      )}
    </Card>
  )
}
