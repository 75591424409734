import { useState } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { Lead } from '@ospace/schemas'
import { API } from '@ospace/shared'

const DEFAULT_PAGE_LIMIT = 150
const DEFAULT_INITIAL_REQUEST_INTERVAL_MS = 200

const waitForMs = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms))

type Opts = {
  clientId: number
  limit?: number
  initialRequestInterval?: number
}

async function* list(opts: Opts) {
  const {
    limit = DEFAULT_PAGE_LIMIT,
    initialRequestInterval = DEFAULT_INITIAL_REQUEST_INTERVAL_MS,
    clientId,
  } = opts
  let requestTimeout: Promise<void> = Promise.resolve()
  let requestInterval = initialRequestInterval
  let pageToken: null | string = null
  let hasRun = false

  while (!hasRun || pageToken) {
    await requestTimeout
    requestTimeout = waitForMs(requestInterval)

    try {
      const pageResp: any = await API.get('client', `/clients/${clientId}/leads`, {
        queryStringParameters: { limit: limit, pageToken: pageToken || '' },
      })
      hasRun = true
      pageToken = pageResp.nextPageToken || null
      yield pageResp
    } catch (err: any) {
      // backoff and retry 3 times on rate limit
      if (err?.response?.status === 429 && requestInterval < initialRequestInterval * 8) {
        requestInterval = 2 * requestInterval
      } else {
        throw err
      }
    }
  }
}

export const useLeadsProgress = () => {
  const [progress, setProgress] = useState(0)
  const [total, setTotal] = useState(0)
  return {
    progress,
    total,
    setProgress,
    setTotal,
  }
}

const fetchLeads =
  ({
    setProgress,
    setTotal,
    total,
  }: {
    setProgress: (progress: number) => void
    setTotal: (total: number) => void
    total: number
  }) =>
  async (opts: Opts): Promise<Lead[]> => {
    let leads: Lead[] = []
    for await (const leadPage of list(opts)) {
      if (!total && leadPage.count) {
        setTotal(leadPage.count)
      }
      leads = [...leads, ...leadPage.data]
      setProgress(leads.length)
    }
    return leads
  }

export const useLeads = (
  opts: Opts
): UseQueryResult<Lead[]> & {
  progress: number
  total: number
} => {
  const { progress, total, setProgress, setTotal } = useLeadsProgress()
  const result = useQuery({
    queryKey: ['client', opts.clientId, 'lead'],
    retry: false,
    queryFn: () =>
      fetchLeads({
        setProgress,
        setTotal,
        total,
      })(opts),
  })
  return { ...result, progress, total }
}
