/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Anchor, Button, Container, createStyles, Flex, Group } from '@mantine/core'

import { Error403 } from '../components/Error403'
import { Error404 } from '../components/Error404'
import { Error500 } from '../components/Error500'

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },
}))

const ErrorsLayout: React.FC = ({ children }) => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const redirectToDashboard = () => {
    navigate('/')
  }

  return (
    <Container className={classes.root}>
      {children}
      <Group position='center' mb={'xl'}>
        <Button onClick={redirectToDashboard} size={'lg'} radius={'md'}>
          Go to homepage
        </Button>
      </Group>

      <Flex align='center' justify='center' mt={100}>
        <Anchor
          href='https://ospace.nextgen.group/'
          target='_blank'
          rel='noreferrer'
          fw={'bold'}
          m={10}
          color='dimmed'
          fz={'lg'}
        >
          About Us
        </Anchor>
        <Anchor
          href='https://nextgen.group/contact'
          target='_blank'
          rel='noreferrer'
          fw={'bold'}
          m={10}
          color='dimmed'
          fz={'lg'}
        >
          Contact Us
        </Anchor>
      </Flex>
    </Container>
  )
}

const ErrorsPage: React.FC = () => {
  return (
    <Routes>
      <Route path='/error/403' element={<ErrorsLayout children={<Error403 />} />} />
      <Route path='/error/404' element={<ErrorsLayout children={<Error404 />} />} />
      <Route path='/error/500' element={<ErrorsLayout children={<Error500 />} />} />
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export { ErrorsPage }
