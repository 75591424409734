import { useNavigate, useParams } from 'react-router-dom'
import { Space } from '@mantine/core'
import { useCheckRole, useUserRole } from '@ospace/auth'
import { isClientsDistributionPipelineAvailableToUserRole, userTypes } from '@ospace/core-auth'
import { useOpportunities } from '@ospace/distribution-pipeline/hooks'
import { DashboardLayout, SelectedPage } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'
import { DashboardLoader } from '@ospace/shared/components/DashboardLoader'
import { useDashboard } from '@ospace/shared/hooks/useDashboard'

import { useDataDogEmailTracker } from '../../datadog'
import { OpportunitiesTable } from '../components/OpportunitiesTable'
import { PipelineChart } from '../components/PipelineChart'
import { PipelineRatio } from '../components/PipelineRatio'
import { ProbabilityChart } from '../components/ProbabilityChart'
export const DistributionPipelinePage = ({ clientId }: { clientId: number }) => {
  const { duration, client, setDuration, user, status } = useDashboard(clientId)
  const userRole = useUserRole()
  const navigate = useNavigate()

  const {
    isLoading,
    data: opportunities,
    progress,
    total,
  } = useOpportunities({ clientId, enabled: !!client?.distributionPipelineVendorId })
  useDataDogEmailTracker()
  if (status === 'loading' || !client) {
    return (
      <DashboardLayout
        status='loading'
        client={client}
        user={user}
        role={userRole}
        pageId='pipeline'
        pageTitle='Pipeline'
      />
    )
  }
  const dataProps = {
    currency: client.currency || 'USD',
    duration,
    opportunities: opportunities || [],
    showNotes: client?.showNotes || false,
  }

  if (!isClientsDistributionPipelineAvailableToUserRole({ userRole, client })) {
    navigate('/error/403')
  }

  return (
    <DashboardLayout
      client={client}
      status='ready'
      duration={duration}
      setDuration={setDuration}
      user={user}
      role={userRole}
      pageId='pipeline'
      pageTitle='Pipeline'
    >
      <>
        <SelectedPage>Pipeline</SelectedPage>
        {isLoading ? (
          <DashboardLoader progress={progress} total={total} />
        ) : (
          <>
            <PipelineChart {...dataProps} />
            <Space h='md' />
            <ProbabilityChart {...dataProps} />
            <Space h='md' />
            <PipelineRatio {...dataProps} />
            <Space h='md' />
            <OpportunitiesTable {...dataProps} />
          </>
        )}
      </>
    </DashboardLayout>
  )
}

const Controller = () => {
  useCheckRole([
    userTypes.Admin,
    userTypes.ClientManager,
    userTypes.ClientViewer,
    userTypes.ClientUser,
    userTypes.InternalViewer,
    userTypes.CampaignManager,
  ])

  const params = useParams()
  const clientId = Number(params?.clientId)
  return <DistributionPipelinePage clientId={clientId} />
}

export const DistributionPipelinePageHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)

export default DistributionPipelinePageHandler
