import { CurrencyText } from '@ospace/shared/components/CurrencyText'
import { IconChartBar } from '@tabler/icons-react'

import { LineGraphCard } from '../LineGraphCard'

export const TopCard = ({ total, currency }: { total: string; currency: string }) => {
  return (
    <LineGraphCard
      backgroundColor={'#0CA678'}
      iconBackgroundColor={'#0A9068'}
      icon={IconChartBar}
      heading={'SALES'}
      title='&nbsp;'
      left={{ label: '', value: '' }}
      right={{
        label: 'Total value',
        value: <CurrencyText value={total} currency={currency} currencyColor='white' />,
      }}
    />
  )
}
