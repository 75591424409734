import * as Yup from 'yup'

export const DURATION_OPTIONS = ['Last week', 'Month', 'Quarter', 'FY'] as const

export const dateRangeSchema = Yup.object({
  start: Yup.string().required(),
  end: Yup.string().required(),
}).nullable()
export type DateRange = Yup.InferType<typeof dateRangeSchema>

export const staticDurationSchema = Yup.string().nullable().oneOf(DURATION_OPTIONS)
export type StaticDuration = Yup.InferType<typeof staticDurationSchema>

// Yup does not support tagged unions, this is as close as you can get.
export const durationSelectionSchema = Yup.object({
  staticDuration: staticDurationSchema,
  dateRange: dateRangeSchema,
  type: Yup.string().oneOf(['static', 'dateRange']),
})
export type DurationSelection = Yup.InferType<typeof durationSelectionSchema>

export const initDurationSelection: DurationSelection = {
  staticDuration: 'FY',
  type: 'static',
  dateRange: null,
}
