import { Duration } from '@ospace/common'
import { Lead } from '@ospace/schemas'

import { LineChart } from './LineChart'
import { plotGraph } from './plotGraph'

export const plotLeads = (
  leads: Lead[],
  discoveryDealCount: number,
  duration: Duration,
  color?: string
) => {
  const { count, chartData } = plotGraph(leads, 'leadDate', duration)

  return {
    title: 'NEW LEADS',
    left: {
      label: 'Leads',
      value: count,
    },
    right: {
      label: 'Ratio',
      value: `${discoveryDealCount}:${count}`,
    },
    tooltip:
      'The chart displays companies converted to leads during the selected time period. The ratio is relationship between companies discovered in the selected time period and companies that have been converted to leads in the selected time period.',
    chart: LineChart,
    color: color || '#C9D14D',
    ...chartData,
  }
}
