import React from 'react'
import { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { KTSVG } from '@ospace/shared'

import { useNotificationContext } from '../contexts/notification'

const CustomToggle = React.forwardRef((props: any, ref) => {
  const { children, onClick } = props
  return (
    <a
      href='/'
      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary hover showing'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </a>
  )
})

const SimpleLink = (props: any) => {
  const { item, index } = props
  return (
    <li className='p-3 dropdown-item' key={index}>
      <Link
        to={item.link}
        className='text-dark fw-bold text-capitalize menu-link'
        aria-disabled={true}
      >
        {item.name}
      </Link>
    </li>
  )
}

const ActionLink = (props: any) => {
  const { item, index, toggleShow } = props
  const [modal, toggleModal] = useState(false)

  const { setNotification } = useNotificationContext()
  const mutation = useMutation(
    () => {
      return item.action.call(null, item.params.id)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: item.successMessage,
          type: 'success',
        })
        item.callback()
        toggleShow(false)
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.message,
          type: 'danger',
        })
      },
    }
  )

  const onClickHanlder = () => {
    toggleModal(true)
  }

  const handleClose = () => {
    toggleModal(false)
  }

  const handleContinue = () => {
    toggleModal(false)
    mutation.mutate()
  }

  return (
    <>
      {item.alertMessage && (
        <Modal show={modal} onHide={handleClose} centered={true}>
          <Modal.Body>{item.alertMessage}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button size='sm' variant='primary' onClick={handleContinue}>
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <li className='p-3 dropdown-item' key={index}>
        <Link
          to={'#'}
          onClick={onClickHanlder}
          className='text-dark fw-bold text-capitalize menu-link'
        >
          {mutation.isLoading && (
            <span className='spinner-border spinner-border-sm align-middle mx-2' />
          )}
          {item.name}
        </Link>
      </li>
    </>
  )
}

const CDropdown = (props: any) => {
  const { items } = props
  const [show, toggleShow] = useState(false)

  const dropdownToggle = () => {
    toggleShow(!show)
  }

  return (
    <Dropdown show={show}>
      <Dropdown.Toggle
        id='dropdown-basic'
        className='p-5'
        as={CustomToggle}
        onClick={dropdownToggle}
      >
        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='px-1 w-200px'>
        {items.map((item: any, index: number) =>
          item.link ? (
            <SimpleLink item={item} index={index} key={index} />
          ) : (
            <ActionLink item={item} index={index} key={index} toggleShow={toggleShow} />
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default CDropdown
