import { Container } from '@mantine/core'
import { Duration } from '@ospace/common'
import { Lead } from '@ospace/schemas'
import { BarChart, plotChartByMonth } from '@ospace/shared/components/BarChart'

import {
  plotCompaniesBartChart,
  plotLeadsBartChart,
  plotMeetingsBartChart,
} from './LeadsWidgets/filterRule'

type Props = {
  leads: Lead[]
  currency: string
  duration: Duration
}

const plotChart = (leads: Lead[], duration: Duration) => {
  const plotY = (segment: string) => ({
    companies: plotCompaniesBartChart(leads, new Date(segment), duration),
    leads: plotLeadsBartChart(leads, new Date(segment), duration),
    meetings: plotMeetingsBartChart(leads, new Date(segment), duration),
  })

  return plotChartByMonth(duration)(plotY)
}
const barChartColours = ({ id }: { id: string | number }) => {
  switch (id) {
    case 'companies':
      return '#FFA53F'
    case 'leads':
      return '#C9D14D'
    case 'meetings':
      return '#65C869'
    default:
      return '#ff0000'
  }
}

export const LeadsBarChart = ({ leads, currency, duration }: Props) => {
  return (
    <BarChart
      title='MONTHLY RESULTS'
      barChartTooltip='The chart displays the companies that have been discovered, turned into a lead, or turned into an opportunity during the selected time. A company that was discovered and become a lead or booked meeting in the same sub-period may be counted more than once.'
      keys={['companies', 'leads', 'meetings']}
      colors={barChartColours}
      data={plotChart(leads, duration)}
      indexBy='month'
      tooltip={({ value, color }) => (
        <Container c={color} bg='#222222' p={12}>
          <strong>{value}</strong>
        </Container>
      )}
      axisLeftFormat={(value) => `${value}`}
      label={(item) => item?.value || 0}
      legendItemWidth={100}
    />
  )
}
