import store from 'store'

export const LocalStorage = {
  set: (key: string, value: string | object) => {
    store.set(key, value)
  },
  get: (key: string) => {
    return store.get(key)
  },
  has: (key: string) => {
    return store.get(key) ? true : false
  },
  delete: (key: string) => {
    return store.remove(key)
  },
  deleteAll: (key: string) => {
    return store.clearAll()
  },
}
