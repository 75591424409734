import { Group, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared/components/MoreInfo'

export const TableHeader = () => {
  return (
    <Group position='left'>
      <Text c='dimmed' tt='uppercase' fw={700} fz='md'>
        All Sales
      </Text>
      <MoreInfo info='A detailed list of sales transactions for the selected time period' />
    </Group>
  )
}
