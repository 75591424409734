import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthContext } from '@ospace/auth'
import { toAbsoluteUrl } from '@ospace/shared'

export function PrivateRoutes() {
  const { session, loading } = useAuthContext()
  const location = useLocation()

  if (loading) {
    return <FallbackView />
  }

  return session ? (
    <Outlet />
  ) : (
    <Navigate to='/auth/login' state={{ from: `${location.pathname}${location.search}` }} replace />
  )
}

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logos/oSpace.svg')} alt='Ospace Loading logo' />
      <span>Loading ...</span>
    </div>
  )
}
