type BreadcrumbsProps = {
  folderPath: string
  setFolderPath: (folderPath: string) => void
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const folders = props.folderPath === '/' ? [] : props.folderPath.split('/').slice(1, -1)
  const foldersLen = folders.length
  return (
    <nav aria-label='breadcrumb'>
      <h3 className='card-title align-items-start flex-column'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            {foldersLen === 0 ? (
              <span className='fw-bolder fs-3'>Files</span>
            ) : (
              <button
                type='button'
                className='btn btn-link'
                style={{ padding: 0 }}
                onClick={() => props.setFolderPath('/')}
              >
                <span className='fw-bolder fs-3'>Files</span>
              </button>
            )}
          </li>
          {folders.map((folder, idx) =>
            idx === foldersLen - 1 ? (
              <li key={`${idx}:${folder}`} className='breadcrumb-item active' aria-current='page'>
                <span className='fw-bolder fs-3' style={{ color: '#000000' }}>
                  {folder}
                </span>
              </li>
            ) : (
              <li key={`${idx}:${folder}`} className='breadcrumb-item'>
                <button
                  type='button'
                  className='btn btn-link'
                  style={{ padding: 0 }}
                  onClick={() => {
                    props.setFolderPath(`/${folders.slice(0, idx + 1).join('/')}/`)
                  }}
                >
                  <span className='fw-bolder fs-3'>{folder}</span>
                </button>
              </li>
            )
          )}
        </ol>
      </h3>
      <p>Drag 'n' drop some files here</p>
    </nav>
  )
}
