import React, { useState } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'

import { Company } from '../schemas/Company'

type CompanyBoxProps = {
  company: Company
  aside?: boolean
  collpase?: boolean
}

const CompanyDetail = ({ company, colspan }: { company: Company; colspan: number }) => {
  return (
    <Row>
      <Col md={colspan}>
        {[
          {
            label: 'Name',
            value: company.name,
          },
          {
            label: 'Website',
            value: company.website,
          },
          {
            label: 'Street Address',
            value: company.streetAddress,
          },
          {
            label: 'Street Address 2',
            value: company.streetAddressTwo,
          },
          {
            label: 'City',
            value: company.city,
          },
          {
            label: 'State',
            value: company.state,
          },
          {
            label: 'Country',
            value: company.country,
          },
        ].map((item, index) =>
          item.value ? (
            <div key={index} className='d-flex mb-3 me-15'>
              <span className='flex-grow-2 text-muted min-w-150px'>{item.label}</span>
              <span className='flex-grow-1 fw-bold'>{item.value}</span>
            </div>
          ) : (
            <></>
          )
        )}
      </Col>
      <Col md={colspan}>
        {[
          {
            label: 'Phone',
            value: company.phone,
          },
          {
            label: 'LinkedIn',
            value: company.linkedin,
          },
          {
            label: 'Employee Range',
            value: company.employeeRange,
          },
          {
            label: 'Revenue Range',
            value: company.revenueRange,
          },
          {
            label: 'Industry',
            value: company.industry,
          },
        ].map((item, index) =>
          item.value ? (
            <div key={index} className='d-flex mb-3 me-10'>
              <span className='flex-grow-2 text-muted min-w-150px'>{item.label}</span>
              <span className='flex-grow-1 fw-bold'>{item.value}</span>
            </div>
          ) : (
            <></>
          )
        )}
      </Col>
    </Row>
  )
}

export const CompanyBox: React.FC<CompanyBoxProps> = (props: CompanyBoxProps) => {
  const { company, aside = false, collpase = true } = props
  const [open, setOpen] = useState(true)
  const colspan = aside ? 6 : 12
  return (
    <div className={collpase ? '' : 'card p-10'}>
      {collpase ? (
        <h3
          onClick={() => setOpen(!open)}
          aria-controls='company-collapse'
          className='card-title align-items-start flex-column w-100 text-hover-primary mt-2 mb-8'
        >
          {open ? (
            <i className='bi bi-chevron-up text-hover-primary mx-2 fw-boldest'></i>
          ) : (
            <i className='bi bi-chevron-down text-hover-primary mx-2 fw-boldest'></i>
          )}
          {company.name}
        </h3>
      ) : (
        <h3 className='card-title align-items-start flex-column w-100 text-hover-primary mt-2 mb-8'>
          {company.name}
        </h3>
      )}
      {collpase ? (
        <Collapse in={open}>
          <div id='company-collapse' className='p-5'>
            <CompanyDetail company={company} colspan={colspan} />
          </div>
        </Collapse>
      ) : (
        <CompanyDetail company={company} colspan={colspan} />
      )}
    </div>
  )
}
