import React, { FC } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'

import { CampaignHeader } from '../../components/partials/CampaignHeader'
import { useCampaign } from '../../hooks'

// TODO: this is not a page, it is a container that gets campaignId from the route for some reason.
//   - rename to CampaignContainer
//   - take campaignId as a prop
export const CampaignPage: FC = ({ children }) => {
  const params: any = useParams()
  const role = useUserRole()
  const campaignId = Number(params?.id)
  const { status, data: campaign, error, isFetching } = useCampaign(campaignId)

  const profileBreadCrumbs: Array<any> = [
    {
      title: 'Home',
      path: '/dashboard',
      isActive: false,
    },
    role !== userTypes.CampaignUser && {
      title: 'Client',
      path: `/client/view/${campaign?.client?.id}`,
      isActive: false,
    },
  ].filter(Boolean)

  return (
    <>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : status === 'error' ? (
        <span>Error: {(error as any).message}</span>
      ) : (
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Campaign Overview</PageTitle>
          <CampaignHeader campaign={campaign} />
          {children}
        </>
      )}
    </>
  )
}
