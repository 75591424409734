import { FC } from 'react'
import { KTSVG } from '@ospace/shared'

export type StatBoxProps = {
  title: string
  value: number | string
}

export const StatBox: FC<StatBoxProps> = (props: StatBoxProps) => {
  const { title, value } = props
  return (
    <div className='border border-gray-300 rounded col-2 m-3 p-2 '>
      <div className='d-flex align-items-center align-center'>
        <div className='fs-4 fw-bolder'>{value}</div>
        <KTSVG
          path='/media/icons/duotune/arrows/arr066.svg'
          className='svg-icon-5 svg-icon-success ms-1'
        />
      </div>
      <div className='fs-7 text-muted fw-bold'>{title}</div>
    </div>
  )
}
