import { useNavigate, useParams } from 'react-router-dom'
import { Space } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { dateFilter, groupByOrderNumber } from '@ospace/common'
import { isClientsSalesDashboardAvailableToUserRole } from '@ospace/core-auth'
import { useOpportunities } from '@ospace/distribution-pipeline'
import { SelectedPage } from '@ospace/layout'
import { DashboardLayout } from '@ospace/layout'
import { SalesInvoice } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'
import { DashboardLoader } from '@ospace/shared/components/DashboardLoader'
import { useDashboard } from '@ospace/shared/hooks/useDashboard'

import { useDataDogEmailTracker } from '../../datadog'
import { SalesGraph } from '../components/SalesGraph/SalesGraph'
import { SalesTable } from '../components/SalesTable/SalesTable'
import { SalesWidgets } from '../components/SalesWidgets'
import TopTenTable from '../components/TopTenTable'
import { useSalesDashBoard } from '../hooks/useSalesDashBoard'

export const SalesDashBoardPage = ({ clientId }: { clientId: number }) => {
  const { duration, client, setDuration, user, status } = useDashboard(clientId)
  const userRole = useUserRole()
  const navigate = useNavigate()
  useDataDogEmailTracker()
  const salesDashBoardResponse = useSalesDashBoard(
    clientId,
    client?.salesDashboardVendorId || '',
    client?.JIWARegion || 'au'
  )
  const {
    isLoading,
    data: opportunities,
    progress,
    total,
  } = useOpportunities({ clientId, enabled: !!client?.distributionPipelineVendorId })

  const sales = salesDashBoardResponse.data || []

  if (status === 'loading' || !client) {
    return (
      <DashboardLayout
        status='loading'
        client={client}
        user={user}
        role={userRole}
        pageId='sales'
        pageTitle='Sales'
      />
    )
  }

  const filteredSales = sales.filter(({ invoiceDate }) =>
    dateFilter({ date: invoiceDate || '', duration, format: 'YYYY-MM-DD' })
  ) as SalesInvoice[]

  const dataProps = {
    currency: client?.currency || 'USD',
    duration,
    sales: groupByOrderNumber(filteredSales),
    opportunities: opportunities || [],
  }

  if (!isClientsSalesDashboardAvailableToUserRole({ userRole, client })) {
    navigate('/error/403')
  }

  return (
    <DashboardLayout
      client={client}
      status='ready'
      duration={duration}
      setDuration={setDuration}
      user={user}
      role={userRole}
      pageId='sales'
      pageTitle='Sales'
    >
      <>
        <SelectedPage>Sales</SelectedPage>
        {salesDashBoardResponse.isLoading || isLoading ? (
          <>
            <DashboardLoader
              progress={progress + salesDashBoardResponse.progress}
              total={total + salesDashBoardResponse.total}
            />
          </>
        ) : (
          <>
            <SalesWidgets {...dataProps} />
            <Space h='md' />
            <TopTenTable {...dataProps} />
            <Space h='md' />
            <SalesGraph {...dataProps} />
            <Space h='md' />
            <SalesTable {...dataProps} />
          </>
        )}
      </>
    </DashboardLayout>
  )
}

const Controller = () => {
  const params = useParams()
  const clientId = Number(params?.clientId)
  return <SalesDashBoardPage clientId={clientId} />
}

export const SalesDashBoardPageHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)

export default SalesDashBoardPageHandler
