import { Client } from '@ospace/schemas'

import { userTypes } from './constants'

// canUserViewSalesDashboard checks if the user role passed can view the
// distribution pipeline for the client
type Opts = {
  userRole: userTypes
  client: Client
}

export const isClientsSalesDashboardAvailableToUserRole = (opts: Opts) => {
  // vendorId must be set for the pipeline to be available
  if (!opts.client?.salesDashboardVendorId) return false

  // if the vendorId is set, internal viewers can see the pipeline
  if (opts.userRole === userTypes.Admin) return true
  if (opts.userRole === userTypes.ClientManager) return true
  if (opts.userRole === userTypes.InternalViewer) return true
  if (opts.userRole === userTypes.CampaignManager) return true

  // showSalesDashboardToExternalUser must be set for external users to see the pipeline
  if (!opts.client.showSalesDashboardToExternalUser) return false

  // only ClientUser external users can see the pipeline
  if (opts.userRole === userTypes.ClientUser || opts.userRole === userTypes.ClientViewer)
    return true
  return false
}
