import { useMutation, useQueryClient } from 'react-query'
import { UserService } from '@ospace/auth'
import { EditUserProfileRequest } from '@ospace/schemas'
import { Storage, useNotificationContext } from '@ospace/shared'

export const useUserProfileMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (user: EditUserProfileRequest & { avatarFile: File }) => {
      if (user.avatarFile) {
        await Storage().put(`users/${user.identityId}/${user.avatarFile.name}`, user.avatarFile)
      }
      return await UserService.editProfile(
        user?.identityId as string,
        {
          ...user,
          avatar: user.avatarFile ? `users/${user.identityId}/${user.avatarFile.name}` : null,
        } as any
      )
    },
    {
      onSuccess: (data: any) => {
        setNotification({
          active: true,
          message: 'You have successfully updated user profile.',
          type: 'success',
        })
        queryClient.invalidateQueries(['user', data?.identityId])
        queryClient.refetchQueries(['users'])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )
}
