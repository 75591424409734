import React from 'react'

import { REGION_OPTIONS } from './RegionSelect'

export const RegionTag = ({ regionCode }: { regionCode: string }) => {
  const region = REGION_OPTIONS.find((r) => r.value === regionCode)

  if (!region) {
    return <div>${regionCode}</div>
  }
  return <div>{region.label}</div>
}
