import React from 'react'
import Select from 'react-select'
import { FieldProps } from 'formik'

interface Option {
  label: string
  value: string
}

interface CustomSelectProps extends FieldProps {
  options: any
  isMulti?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  className?: string
  placeholder?: string
  onInputChange: Function
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  isDisabled = false,
  isClearable = false,
  onInputChange,
}: CustomSelectProps) => {
  const onChange = (option: Option[] | Option | null) => {
    let val: string[] | string | null = null
    if (Array.isArray(option)) {
      val = option.map((item: Option) => item.value)
    } else if (option !== null) {
      val = option.value
    }

    form.setFieldValue(field.name, val)
    onInputChange && onInputChange(option)
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: Option) => field.value.indexOf(option.value) >= 0)
        : options.find((option: Option) => option.value === field.value)
    } else {
      return isMulti ? [] : ('' as any)
    }
  }

  return (
    <Select
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  )
}

export default CustomSelect
