import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, FileInput, Grid, Group, Image, Switch, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'

type UserProfileEditFormProps = {
  isSubmitting: boolean
  initialValues: any
  onSubmit: any
  validationSchema?: any
}

export const UserProfileEditForm: React.FC<any> = (props: UserProfileEditFormProps) => {
  const { onSubmit, initialValues, validationSchema, isSubmitting } = props

  const navigate = useNavigate()
  const form = useForm({
    initialValues,
    validate: yupResolver(validationSchema),
  })

  const [preview, setPreview] = useState<any>()
  const handleFileChange = (files: any) => {
    form.setFieldValue('avatarFile', files)

    // Display image preview
    if (files) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
      }
      reader.readAsDataURL(files)
    } else {
      setPreview(null)
    }
  }

  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid>
        <Col span={6} p={'xl'}>
          <TextInput
            withAsterisk
            data-testid='firstName'
            label='First Name'
            placeholder='Specify your first name'
            {...form.getInputProps('firstName')}
            mb='md'
            size='md'
          />
          <TextInput
            withAsterisk
            label='Last Name'
            placeholder='Specify your last name'
            {...form.getInputProps('lastName')}
            mb='md'
            size='md'
          />
          <FileInput
            placeholder='Click to select avatar'
            onChange={handleFileChange}
            label='Drop your files here or click to select avatar'
            mb='md'
            size='md'
          />

          {preview && <Image src={preview} width={200} alt='Preview' mt={'md'} />}
          {form.values.signedAvatarUrl && !preview ? (
            <Image src={form.values.signedAvatarUrl} width={200} alt='Preview' mt={'md'} />
          ) : null}
        </Col>
        <Col span={6} p={'xl'}>
          <TextInput
            label='Job Title'
            placeholder='Specify your job title'
            {...form.getInputProps('jobTitle')}
            mb='md'
            size='md'
            data-testid='jobTitle'
          />

          <TextInput
            label='Contact Number'
            placeholder='Specify your contact number'
            {...form.getInputProps('contactNumber')}
            mb='md'
            size='md'
          />

          <Switch
            checked={form.values.enableProgramNotifications}
            onChange={() =>
              form.setFieldValue(
                'enableProgramNotifications',
                !form.values.enableProgramNotifications
              )
            }
            label='Enable program updates'
            mt={'md'}
            size='md'
          />

          <Switch
            checked={form.values.enableDashboardNotifications}
            onChange={() =>
              form.setFieldValue(
                'enableDashboardNotifications',
                !form.values.enableDashboardNotifications
              )
            }
            label='Enable Lead/Pipeline/Sales updates'
            mt={'md'}
            size='md'
          />
        </Col>
      </Grid>
      <Group position='right' m='md'>
        <Button type='submit' loading={isSubmitting} size='md'>
          Submit
        </Button>
        <Button size='md' color='gray' onClick={handleCancel}>
          Cancel
        </Button>
      </Group>
    </form>
  )
}
