import { FilterSettings, Opportunity } from '@ospace/schemas'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const findUniqueValues = (array: any[]): { [key: string]: any[] } => {
  const uniqueValues: { [key: string]: any[] } = {}

  for (const obj of array) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]

        if (!uniqueValues[key]) {
          uniqueValues[key] = []
        }

        if (!uniqueValues[key].includes(value)) {
          uniqueValues[key].push(value)
        }
      }
    }
  }

  return uniqueValues
}

const parseServiceNowDate = (date: string | null | undefined) => dayjs(date, 'DD-MM-YYYY')

export const filterByCloseDateRange = (
  objects: Opportunity[],
  startDate: string | undefined | null,
  endDate: string | undefined | null
): Opportunity[] => {
  return objects.filter((obj: Opportunity) => {
    // if filtering on date and there is no closeDate, then exclude
    if ((startDate || endDate) && !obj.closeDate) {
      return false
    }

    if (startDate && parseServiceNowDate(obj.closeDate) <= dayjs(startDate)) {
      return false
    }

    return !(endDate && parseServiceNowDate(obj.closeDate) >= dayjs(endDate))
  })
}

const percentageRegex = /^(\d+)%/

export const sortProbabilities = (a: string | undefined | null, b: string | undefined | null) => {
  const percentageA = parseInt(a?.match(percentageRegex)?.[1] || '0', 10)
  const percentageB = parseInt(b?.match(percentageRegex)?.[1] || '0', 10)
  if (isNaN(percentageA) && isNaN(percentageB)) return 0
  if (isNaN(percentageA)) return 1
  if (isNaN(percentageB)) return -1

  if (percentageA === percentageB) return 0
  if (percentageA < percentageB) return -1
  return 1
}

export const filterByKey = (
  objects: Opportunity[],
  key: keyof Opportunity,
  filter: string[]
): Opportunity[] => {
  if (!filter || filter.length === 0) return objects

  return objects.filter((obj: Opportunity) => {
    const hay = obj[key]
    if (hay) return filter.includes(hay as string)

    return true
  })
}

export const filterList: { title: string; name: keyof FilterSettings }[] = [
  {
    title: 'Opportunity Status',
    name: 'oppStatus',
  },
  {
    title: 'Opportunity Type',
    name: 'oppType',
  },
  {
    title: 'Partner',
    name: 'partnerName',
  },
  {
    title: 'End user',
    name: 'endUserName',
  },
  {
    title: 'Probability',
    name: 'probability',
  },
]
