import { useState } from 'react'
import { Paper } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Lead } from '@ospace/schemas'
import { Campaign } from '@ospace/schemas'
import { ColDef, DashboardDetailedModal, NewTable } from '@ospace/shared'

import { TableHeader } from './TableHeader'
import { transformModalData } from './transformModalData'

type LeadsTableProps = {
  leads: Lead[]
  currency: string
  campaigns: Campaign[]
}

export const LeadsTable = ({ leads, currency, campaigns }: LeadsTableProps) => {
  const leadsWithCampaignName = leads?.map((lead: Lead) => {
    return {
      ...lead,
      campaignName: campaigns.find((camp) => camp.id === lead.campaignId)?.name,
    }
  })

  const colDef: ColDef[] = [
    {
      key: 'companyName',
      label: 'Company Name',
    },
    {
      key: 'companyDomain',
      label: 'Website',
    },
    {
      key: 'companyCity',
      label: 'City',
    },
    {
      key: 'companyNumberofEmployees',
      label: 'Employees',
    },
    {
      key: 'companyIndustry',
      label: 'Industy',
    },
    {
      key: 'companyAnnualRevenue',
      label: 'Revenue',
      dataType: 'currency',
      currency: currency,
    },
    {
      key: 'campaignName',
      label: 'Program',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ]

  const [opened, { open, close }] = useDisclosure(false)
  const [modalData, setModalData] = useState<Lead & { campaignName: string }>()

  return (
    <Paper withBorder p='xl' radius='md'>
      <TableHeader />
      <NewTable
        colDef={colDef}
        tableData={leadsWithCampaignName}
        tableProps={{
          itemsPerPage: 15,
          rowKey: 'id',
        }}
        modal={{
          component: (
            <DashboardDetailedModal
              opened={opened}
              onClose={close}
              centered
              data={transformModalData(modalData)}
              colDef={colDef}
            />
          ),
          setModalData,
          open,
        }}
      />
    </Paper>
  )
}
