import * as Yup from 'yup'

export const salesInvoiceSchema = Yup.object({
  vendorId: Yup.string().required(),
  invoiceNumber: Yup.string().required(),
  vendorName: Yup.string().required(),
  partnerName: Yup.string().required(),
  endUserName: Yup.string().required(),
  invoiceDate: Yup.string().required(),
  buyPriceLocal: Yup.number().required(),
  buyPriceFx: Yup.number().required(),
  oppNumber: Yup.string().nullable(),
  creditNote: Yup.string().required(),
  orderNumber: Yup.string().required(),
})

export type SalesInvoice = Yup.InferType<typeof salesInvoiceSchema>

export const listSalesInvoiceRequestSchema = Yup.object({
  vendorId: Yup.string().required(),
  page: Yup.number().nullable(),
  limit: Yup.number().min(1).required(),
  totalRecordCount: Yup.number().optional(),
  JIWARegion: Yup.string().required(),
})
export type ListSalesInvoiceRequest = Yup.InferType<typeof listSalesInvoiceRequestSchema>
export const listSalesInvoiceResponseSchema = Yup.object({
  salesInvoices: Yup.array().of(salesInvoiceSchema).required(),
  totalRecordCount: Yup.number().min(0).nullable(), // only set when there is no offsetOppNumber.
  limit: Yup.number().min(1).required(),
  nextPage: Yup.number().nullable(), // pagination token, is the last oppNumber in response. Null if there is no next page.
})
export type ListSalesInvoiceResponse = Yup.InferType<typeof listSalesInvoiceResponseSchema>
