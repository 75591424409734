import { Container } from '@mantine/core'
import { abbreviateNumber, Duration } from '@ospace/common'
import { SalesInvoice } from '@ospace/schemas'
import { BarChart, plotChartByMonth } from '@ospace/shared/components/BarChart'
import dayjs from 'dayjs'

type Props = {
  sales: SalesInvoice[]
  currency: string
  duration: Duration
}

const plotChart = (duration: Duration, sales: SalesInvoice[]) => {
  const plottingRule = (segmentDate: Date) => {
    const start = dayjs(segmentDate).startOf('month')
    const end = dayjs(segmentDate).endOf('month')
    return sales
      .filter((sale) => dayjs(new Date(sale.invoiceDate || '')).isBetween(start, end, 'date', '[]'))
      .reduce((sum, sale) => sum + sale.buyPriceFx, 0)
  }
  const plotY = (segment: string) => ({
    sales: plottingRule(new Date(segment)),
  })

  return plotChartByMonth(duration)(plotY)
}
export const SalesGraph = ({ sales, currency, duration }: Props) => {
  return (
    <BarChart
      title='Monthly sales'
      barChartTooltip='The value of sales transactions per month'
      keys={['sales']}
      colors={({ id }) => {
        switch (id) {
          case 'sales':
            return '#2DCAB6'
          default:
            return '#ff0000'
        }
      }}
      tooltip={({ value, color }) => (
        <Container c={color} bg='#222222' p={12}>
          <strong>{abbreviateNumber(value)}</strong>
        </Container>
      )}
      data={plotChart(duration, sales)}
      indexBy='month'
      axisLeftFormat={(value) => abbreviateNumber(value, true)}
      label={(item) => abbreviateNumber(item?.value || 0, true)}
      legendItemWidth={50}
    />
  )
}
