import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createFolderReqSchema } from '@ospace/schemas/file-manager'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { useCreateFolderMutation } from '../hooks'

import { Button } from './ui'

export type CreateFolderFormModalButtonProps = {
  campaignId: number
  folderPath: string
  disabled?: boolean
}

export const CreateFolderFormModalButton = (props: CreateFolderFormModalButtonProps) => {
  const [isShown, setIsShown] = useState(false)
  const mutation = useCreateFolderMutation({
    campaignId: props.campaignId,
  })
  return (
    <>
      <Button
        onClick={() => setIsShown(true)}
        size='sm'
        variant='secondary'
        disabled={props.disabled}
        submitting={mutation.isLoading}
      >
        Create Folder
      </Button>

      <Modal show={isShown} onHide={() => setIsShown(false)} centered>
        <Formik
          validationSchema={createFolderReqSchema}
          initialValues={{
            campaignId: props.campaignId,
            baseFolderPath: props.folderPath,
            folderName: '',
          }}
          onSubmit={async (req, actions) => {
            setIsShown(false)
            actions.resetForm()
            await mutation.mutateAsync(req)
          }}
        >
          <Form className='form' noValidate id='kt_modal_create_folder_form'>
            <Modal.Body>
              <div className='w-100'>
                <div className='fv-row mb-10'>
                  <label
                    className='d-flex align-items-center fs-5 fw-bold mb-2'
                    htmlFor='file-manager-create-folder-folder-name'
                  >
                    <span className='required'>Folder</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your new folder name'
                    ></i>
                  </label>
                  <Field
                    id='file-manager-create-folder-folder-name'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='folderName'
                    placeholder=''
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='folderName' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button size='sm' variant='secondary' onClick={() => setIsShown(false)}>
                Cancel
              </Button>
              <Button type='submit' size='sm' variant='primary' disabled={mutation.isLoading}>
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  )
}
