import { useState } from 'react'
import { Paper } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Opportunity, SalesInvoice } from '@ospace/schemas'
import { ColDef, DashboardDetailedModal, NewTable } from '@ospace/shared'

import { indexOpportunities } from '../../utils'

import { TableHeader } from './TableHeader'
import { transformModalData } from './transformModalData'

type SalesTableProps = {
  sales: SalesInvoice[]
  opportunities: Opportunity[]
  currency: string
}

export const SalesTable = ({ sales, opportunities, currency }: SalesTableProps) => {
  const uniqueOpportunities = indexOpportunities(opportunities)

  const filteredSalesInvoicesWithOppName = sales.map((sale) => {
    return {
      ...sale,
      oppName: uniqueOpportunities[sale?.oppNumber || '']?.oppName,
    }
  })

  const colDef: ColDef[] = [
    {
      key: 'oppNumber',
      label: 'Opportunity#',
    },
    {
      key: 'invoiceNumber',
      label: 'Invoice#',
    },
    {
      key: 'endUserName',
      label: 'End User',
    },
    {
      key: 'partnerName',
      label: 'Partner',
    },
    {
      key: 'buyPriceFx',
      label: 'Value',
      dataType: 'currency',
      currency: currency,
    },
    {
      key: 'invoiceDate',
      label: 'Invoice Date',
      dataType: 'date',
      dateFormat: 'YYYY-MM-DD',
    },
    {
      key: 'programName',
      label: 'Program Name',
    },
    {
      key: 'oppName',
      label: 'Opportunity Name',
    },
    {
      key: 'vendorName',
      label: 'Vendor Name',
    },
  ]

  const [opened, { open, close }] = useDisclosure(false)
  const [modalData, setModalData] = useState<SalesInvoice & { oppName: string }>()

  return (
    <Paper withBorder p='xl' radius='md'>
      <TableHeader />
      <NewTable
        colDef={colDef}
        tableData={filteredSalesInvoicesWithOppName}
        tableProps={{
          itemsPerPage: 15,
          rowKey: 'invoiceNumber',
        }}
        modal={{
          component: (
            <DashboardDetailedModal
              opened={opened}
              onClose={close}
              centered
              data={transformModalData(modalData, currency)}
              colDef={colDef}
            />
          ),
          setModalData,
          open,
        }}
      />
    </Paper>
  )
}
