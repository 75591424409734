import { Opportunity } from '@ospace/schemas'

export type IndexedOpportunities = { [oppNumber: string]: Opportunity }

// indexOpportunities transforms an array of Opportunities into a map of
// Opportunities. Any duplicates oppNumber will overwrite.
// Consider memoizing the result if inside a component
export const indexOpportunities = (opportunities: Opportunity[]): IndexedOpportunities => {
  const result: IndexedOpportunities = {}
  opportunities.forEach((opportunity) => {
    result[opportunity.oppNumber.toUpperCase()] = opportunity
  })

  return result
}
