import { Grid } from '@mantine/core'
import { Opportunity, SalesInvoice } from '@ospace/schemas'

import { TopTenTable } from './TopTenTable'
type Props = {
  sales: SalesInvoice[]
  opportunities: Opportunity[]
  currency: string
}

export const getTopBy = (limit: number) => (sales: SalesInvoice[], key: keyof SalesInvoice) => {
  const salesByEndUser = sales.reduce(
    (acc, sale) => {
      const saleElement = sale[key]
      return saleElement
        ? {
            ...acc,
            [saleElement]: {
              key: saleElement.toString(),
              value: (acc[saleElement]?.value || 0) + sale.buyPriceFx,
              quantity: (acc[saleElement]?.quantity || 0) + 1,
              oppNumber: sale.oppNumber?.toString() || saleElement.toString(),
            },
          }
        : acc
    },
    {} as {
      [key: string]: {
        key: string
        quantity: number
        value: number
        oppNumber: string
      }
    }
  )
  return Object.entries(salesByEndUser)
    .sort(([, a], [, b]) => b.value - a.value)
    .slice(0, limit)
    .map(([_, value]) => value)
}
const getTopTenBy = getTopBy(10)

export default function Index({ sales, currency }: Props) {
  return (
    <Grid>
      <Grid.Col md={6}>
        <TopTenTable
          title='top users'
          tooltip='The top end users by Sales values'
          data={getTopTenBy(sales, 'endUserName')}
          colDef={[
            {
              key: 'key',
              label: 'End User',
            },
            {
              key: 'value',
              label: 'Value',
              dataType: 'currency',
              currency,
            },
          ]}
        />
      </Grid.Col>
      <Grid.Col md={6}>
        <TopTenTable
          title='top partners'
          tooltip='The top partners by sales values'
          data={getTopTenBy(sales, 'partnerName')}
          colDef={[
            {
              key: 'key',
              label: 'Partner Name',
            },
            {
              key: 'quantity',
              label: 'Quantity',
              dataType: 'number',
            },
            {
              key: 'value',
              label: 'Value',
              dataType: 'currency',
              currency,
            },
          ]}
        />
      </Grid.Col>
    </Grid>
  )
}
