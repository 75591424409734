import { useQuery } from 'react-query'

import { NoteService } from '../services'

export const useNotes = (campaignId: number) => {
  return useQuery(['notes', { campaignId }], () => NoteService.getNotesForCampaign(campaignId))
}

export const usePotentialOpportunities = (campaignId: number) => {
  return useQuery(['potential-opportunities', { campaignId }], () =>
    NoteService.getPotentialOpForCampaign(campaignId)
  )
}

export const useAccountInsights = (campaignId: number) => {
  return useQuery(['account-insights', { campaignId }], () =>
    NoteService.getAccountInsightForCampaign(campaignId)
  )
}

export const useNotesForPerson = (campaignId: number, personId: number) => {
  return useQuery(['notes-person', `${campaignId}-${personId}`], () =>
    NoteService.getNotesForCampaignAndPerson(campaignId, personId)
  )
}

export const useNotesForCompany = (campaignId: number, companyId: number) => {
  return useQuery(['notes-company', `${campaignId}-${companyId}`], () =>
    NoteService.getNotesForCampaignAndCompany(campaignId, companyId)
  )
}
