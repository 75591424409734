import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Table } from '@ospace/shared'
import { ColumnDef } from '@tanstack/react-table'

import { Contact } from '../schemas/Contact'

type ContactsTableProps = {
  contacts: Contact[]
}

const MinimalContactsTable: FC<any> = ({ contacts }: ContactsTableProps, ref) => {
  const { search, pathname } = useLocation()

  const getContactUrl = (pathName: string) => {
    const path = pathName.split('/')
    return `/${path[1]}/${path[2]}/contact`
  }
  const companyDomain = new URLSearchParams(search).get('account') || ''

  const columns: ColumnDef<Contact>[] = [
    {
      accessorFn: (row) => row.firstName,
      id: 'name',
      header: 'Full Name',
      cell: (info: any) => {
        const contact: Contact = info.row.original
        return (
          <Link
            to={`${getContactUrl(pathname)}/${contact.id}`}
            className='text-hover-priamry cursor-pointer'
          >
            {`${contact.firstName} ${contact.lastName}`}
          </Link>
        )
      },
    },
    { accessorKey: 'jobTitle', header: 'Job Title' },
    { accessorKey: 'emailAddress', header: 'Email Address' },
    { accessorKey: 'mobilePhone', header: 'Mobile Phone' },
  ]

  return (
    <div className={`card`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table columns={columns} data={contacts} filter={companyDomain} hideSearch={true} />
        </div>
      </div>
    </div>
  )
}

export { MinimalContactsTable }
