import { ListGroup, Spinner } from 'react-bootstrap'

import { useDealStatus } from '../../../hooks'

export const DealStatus = ({ campaignId }: { campaignId: number }) => {
  const { isFetching, data: dealStatus } = useDealStatus(campaignId)

  const calculateStatusCount = (dealStatus: any, category: string) => {
    return dealStatus
      .filter((item: any) => item.status.includes(category))
      .reduce((total: number, item: any) => total + Number(item.count), 0)
  }

  let accountStatus

  if (!isFetching) {
    const retriesCount = calculateStatusCount(dealStatus, 'Retry')
    const callBackCount = calculateStatusCount(dealStatus, 'Call Back')
    const notCalledCount = calculateStatusCount(dealStatus, 'Not Called')
    const leadCount = calculateStatusCount(dealStatus, 'Lead Approved')
    const htnCount = calculateStatusCount(dealStatus, 'High Touch Nurture')
    const ltnCount = calculateStatusCount(dealStatus, 'Low Touch Nurture')
    const excludedCount = calculateStatusCount(dealStatus, 'Excluded')

    const yieldToDate = (leadCount + htnCount) / (leadCount + htnCount + ltnCount + excludedCount)
    const adjustedForExclusions = (leadCount + htnCount) / (leadCount + htnCount + ltnCount)

    const yieldToDateDisplay = `${
      isNaN(yieldToDate) || !isFinite(yieldToDate) ? 0 : yieldToDate.toFixed(2)
    }%`
    const adjustedForExclusionsDisplay = `${
      isNaN(adjustedForExclusions) || !isFinite(adjustedForExclusions)
        ? 0
        : adjustedForExclusions.toFixed(2)
    }%`
    accountStatus = [
      {
        status: 'Retries',
        count: retriesCount,
      },
      {
        status: 'Call backs',
        count: callBackCount,
      },
      {
        status: 'Not called',
        count: notCalledCount,
      },
      {
        status: 'Opportunities Discovered (Leads)',
        count: leadCount,
      },
      {
        status: 'High Touch Nurtures (HTN)',
        count: htnCount,
      },
      {
        status: 'Low Touch Nurtures (LTN)',
        count: ltnCount,
      },
      {
        status: 'Yield to Date',
        count: yieldToDateDisplay,
      },
      {
        status: 'Adjusted for Exclusions',
        count: adjustedForExclusionsDisplay,
      },
    ]
  }

  return (
    <>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <div className='card'>
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Account Status</span>
            </h3>
          </div>
          <div className=' card-body p-5'>
            <ListGroup variant='flush'>
              {accountStatus?.map((stat: any) => (
                <ListGroup.Item
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                  key={stat.status}
                >
                  <h3 className='card-title fw-bolder fs-5 mb-3 d-block'>{stat.status}</h3>
                  <span className='text-dark fs-5 fw-bolder me-2 float-end'>{stat.count}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
      )}
    </>
  )
}
