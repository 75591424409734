import { useCallback, useState } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'

import { useCreateFolderMutation, useFileManagerFolder, useUploadFilesMutation } from '../hooks'

import { Breadcrumbs } from './Breadcrumbs'
import { CreateFolderFormModalButton } from './CreateFolderFormModalButton'
import { SortOption, Table } from './Table'
import { Button } from './ui'
import { UploadFilesButton } from './UploadFilesButton'

type FileManagerProps = {
  campaignId: number
  isCampaignManager: boolean
}

export const FileManager = (props: FileManagerProps) => {
  const [folderPath, setFolderPath] = useState('/')
  const [dropFolder, setDropFolder] = useState('')
  const [sortOption, setSortOption] = useState<SortOption>('alpha-asc')
  const resp = useFileManagerFolder({ campaignId: props.campaignId, folderPath })
  const uploadFilesMutation = useUploadFilesMutation({ campaignId: props.campaignId, folderPath })
  const createFolderMutation = useCreateFolderMutation({
    campaignId: props.campaignId,
  })
  const onDrop = useCallback(
    (files) => {
      files.forEach((file: { path: string; name: string }) => {
        // create new paths for each file
        const paths = file.path.split('/')
        paths.shift()
        paths
          .filter((p) => p !== file.name)
          .forEach((path, i) => {
            const baseFolderPath = `${paths.slice(0, i).join('/')}/`
            createFolderMutation.mutateAsync({
              campaignId: props.campaignId,
              baseFolderPath: `${dropFolder || folderPath}${baseFolderPath}`.replace(/\/\//g, '/'),
              folderName: path,
            })
          })
      })
      uploadFilesMutation.mutate({ files, dropFolder })
    },
    [uploadFilesMutation, dropFolder, folderPath, createFolderMutation, props.campaignId]
  )
  const { getRootProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true })
  // TODO
  if (resp.status === 'error') {
    return <div>ERROR</div>
  }

  return (
    <div className='card' data-testid={`file-manager-card:${resp.status}`} {...getRootProps()}>
      <div className='card-header border-0 pt-5'>
        <Breadcrumbs folderPath={folderPath} setFolderPath={setFolderPath} />
        <div className='card-toolbar'>
          <SortButton option={sortOption} setOption={setSortOption} />
          <UploadFilesButton
            onChange={(files) => {
              uploadFilesMutation.mutate({ files, dropFolder })
            }}
            disabled={
              resp.status !== 'success' ||
              uploadFilesMutation.isLoading ||
              resp.data.uploadFileForm === null
            }
            submitting={uploadFilesMutation.isLoading}
          />
          <CreateFolderFormModalButton
            campaignId={props.campaignId}
            folderPath={folderPath}
            disabled={resp.status !== 'success' || resp.data.uploadFileForm === null}
          />
        </div>
      </div>
      <div className='card-body'>
        {resp.status === 'success' ? (
          <Table
            setDropFolder={setDropFolder}
            getFolderResp={resp.data}
            setFolderPath={setFolderPath}
            isCampaignManager={props.isCampaignManager}
            sortOption={sortOption}
          />
        ) : (
          <div className='d-flex align-items-center justify-content-center'>
            <Spinner animation='grow' />
          </div>
        )}
      </div>
    </div>
  )
}

type SortButtonProps = {
  option: SortOption
  setOption: (option: SortOption) => void
}
const SortButton = (props: SortButtonProps) => {
  return (
    <Dropdown title='Sort'>
      <Dropdown.Toggle as={Button} variant='secondary' size='sm'>
        <i
          className={clsx(
            'bi',
            props.option === 'date-desc' && 'bi-sort-numeric-down-alt',
            props.option === 'alpha-asc' && 'bi-sort-alpha-down'
          )}
        />
        {props.option === 'date-desc' && 'Date'}
        {props.option === 'alpha-asc' && 'Name'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            props.setOption('alpha-asc')
          }}
        >
          Sort by name
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            props.setOption('date-desc')
          }}
        >
          Sort by date
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
