import { useState } from 'react'
import { Badge, Center, Grid, Loader, Paper, Stack, Text, Title } from '@mantine/core'
import { ColDef, NewTable } from '@ospace/shared'
import dayjs from 'dayjs'

const status = {
  'a Lead – Requested': 'HTN',
  'a Lead – Review': 'Meeting Booked',
  'a Lead Approved': 'Meeting Complete',
}
const colDef: ColDef[] = [
  {
    key: 'status',
    label: 'Status',
    element: (row) => (
      <Badge color='gray' variant='filled'>
        {status[row.status as keyof typeof status]}
      </Badge>
    ),
    hideHeader: true,
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'contact',
    label: 'Contact',
  },
  {
    key: 'role',
    label: 'Role',
  },
  {
    key: 'date',
    label: 'Date',
    element: (row) => dayjs(row.date).format('DD/MM/YYYY'),
  },
  {
    key: 'note',
    label: 'Note',
    width: '400px',
    element: (row) => {
      const html = row.noteBody

      //extract text from html string

      const text = html.replace(/<[^>]+>/g, '')

      return (
        <Text truncate='end' lineClamp={4} style={{ whiteSpace: 'normal' }}>
          {text}
        </Text>
      )
    },
  },
  {
    key: 'newOpportunity',
    label: 'New Opportunity',
    element: (row) => (
      <>
        {row.newOpportunity ? (
          <Badge color='red' variant='filled'>
            New Opportunity
          </Badge>
        ) : row.newActivity ? (
          <Badge color='green' variant='filled'>
            New Activity
          </Badge>
        ) : null}
      </>
    ),
  },
]

const OpportunitiesTable = ({ deals }: { deals: any }) => {
  if (!deals.data) {
    return <Loader />
  }

  const data = deals?.data?.map((deal: any) => {
    return {
      status: deal.dealData?.properties.ospace_status,
      company: deal.companyData?.properties?.name || '-',
      contact:
        deal.dealData?.properties.contact_name ||
        `${deal.contactData?.properties?.firstname} ${deal.contactData?.properties?.lastname}` ||
        '-',
      role: deal.contactData?.properties?.jobtitle || '-',
      date: deal.createdAt,
      newActivity: true, //Randonly set true or false
      newOpportunity: true,
      noteBody: deal.noteBody || '-',
    }
  })

  const Filter = (data: any, setData: any) => {
    const [selected, setSelected] = useState('Total Opportunities')

    const onClickHandler = (label: string) => {
      setSelected(label)
      setData(
        label !== 'Total Opportunities'
          ? data?.filter(
              (deal: any) =>
                deal.status ===
                (label === 'High Touch Nurture'
                  ? 'a Lead – Requested'
                  : label === 'Meetings Booked'
                    ? 'a Lead – Review'
                    : 'a Lead Approved')
            )
          : data
      )
    }
    return (
      <Center m={'md'}>
        <Grid>
          {[
            {
              label: 'Total Opportunities',
              value: data?.length,
            },
            {
              label: 'Meetings Booked',
              value: data?.filter((deal: any) => deal.status === 'a Lead – Review').length,
            },
            {
              label: 'High Touch Nurture',
              value: data?.filter((deal: any) => deal.status === 'a Lead – Requested').length,
            },
            {
              label: 'New Opportunities',
              value: '3',
            },
            {
              label: 'New Acitivites',
              value: '2',
            },
          ].map(({ label, value }) => (
            <Stack
              style={{
                cursor: 'pointer',
                borderRight: label !== 'New Acitivites' ? '1px solid gray' : '',
                backgroundColor: selected === label ? '#e8eef3' : '',
              }}
              spacing={'xs'}
              px={'lg'}
              my={'lg'}
              onClick={() => onClickHandler(label)}
            >
              <Text c='dimmed' fw={500} fz='14px' tt={'uppercase'} mt={10}>
                {label}
              </Text>
              <Center fw={700} fz='24px' c='gray.7'>
                {value}
              </Center>
            </Stack>
          ))}
        </Grid>
      </Center>
    )
  }

  return data?.length > 0 ? (
    <Paper withBorder p='xl' radius='md'>
      <NewTable
        tableData={data}
        colDef={colDef}
        hideHeader={true}
        filter={Filter}
        title='Opportunities'
        tableProps={{
          itemsPerPage: 6,
          verticalSpacing: 'lg',
          horizontalSpacing: 0,
        }}
      />
    </Paper>
  ) : (
    <Title>No Opportunities</Title>
  )
}

export default OpportunitiesTable
