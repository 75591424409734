import { useEffect } from 'react'
import { Client } from '@ospace/schemas'

import { useClientData } from '../contexts/Client'

export const ClientSwitcher = ({ client }: { client?: Client }) => {
  const { setClient } = useClientData()
  useEffect(() => {
    if (client) {
      setClient(client)
    }
  }, [client, setClient])

  return <></>
}
