import React from 'react'

import { ErrorLogging } from '../services/error-logging'

const logging = ErrorLogging()

type Props = {
  children: React.ReactNode
}
export class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(err: Error, errorInfo: React.ErrorInfo) {
    logging.logErrorBoundaryError({ error: err, errorInfo: errorInfo, type: 'ErrorBoundaryError' })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    }
    return this.props.children
  }
}

const ErrorPage = () => (
  <>
    <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>System Error</h1>

    <div className='fw-bold fs-3 text-gray-400 mb-15'>
      Something went wrong! <br /> Please try again later.
    </div>
  </>
)
