import React from 'react'
import { userTypes } from '@ospace/core-auth'
import { Campaign, Client } from '@ospace/schemas'
import { Metrics } from '@ospace/shared'

type Props = {
  client: Client
  userRole: userTypes
  campaigns: Campaign[]
}
export const ClientHeader = (props: Props) => {
  return (
    <ClientHeaderContent
      client={props.client}
      campaigns={props.campaigns}
      userRole={props.userRole}
    />
  )
}

export const ClientHeaderContent: React.FC<any> = (props: {
  campaigns: Campaign[]
  client: Client
  userRole: userTypes
}) => {
  const { campaigns } = props
  let client = props.client

  const companiesDiscovered =
    campaigns
      .map((campaign: any) =>
        campaign.objective?.results.reduce((a: any, c: any) => a + Number(c.companiesDiscovered), 0)
      )
      .reduce((a: number, c: number) => a + c, 0) || null

  const meetingsBooked =
    campaigns
      .map((campaign: any) =>
        campaign.objective?.results.reduce((a: any, c: any) => a + Number(c.meetingBooked), 0)
      )
      .reduce((a: number, c: number) => a + c, 0) || null
  //Extra class is need to make header look good when there are no companiesDiscovered or meetingsBooked
  const headerClass = companiesDiscovered || meetingsBooked ? '' : 'h-100'

  return (
    <div className='card'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div>
              <img
                src={client?.signedLogoUrl || undefined}
                style={{ width: '150px', paddingTop: '15px' }}
                alt=''
              />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div
              className={`d-flex ${headerClass} justify-content-between align-items-center flex-wrap mb-2`}
            >
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <h2 className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {client?.name}
                  </h2>
                </div>

                {companiesDiscovered && (
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                      Campaign wide summary
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  {companiesDiscovered && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{companiesDiscovered}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>
                        {Metrics.companiesDiscovered}
                      </div>
                    </div>
                  )}

                  {meetingsBooked && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{meetingsBooked}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{Metrics.meetingBooked}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
