import { Group, Text } from '@mantine/core'
import { LineProps, ResponsiveLine } from '@nivo/line'
interface ChartProps extends LineProps {
  chartLabels?: {
    leftLabel: string
    rightLabel: string
  }
  data: {
    id: string
    color: string
    data: { x: string; y: number }[]
  }[]
}
export const LineChart = ({ data, chartLabels, ...lineProps }: ChartProps) => (
  <div
    style={{
      height: 100,
    }}
  >
    <ResponsiveLine
      data={data}
      colors={({ color }) => color}
      curve={'basis'}
      margin={{ top: 20, bottom: 4 }}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      pointSize={0}
      lineWidth={3}
      isInteractive={false}
      useMesh={false}
      animate={false}
      {...lineProps}
    />
    {chartLabels && (
      <Group position='apart' mt={-11}>
        <Text c='dimmed' fw={400} fz='sm' mt={10}>
          {chartLabels.leftLabel}
        </Text>
        <Text c='dimmed' fw={400} fz='sm' mt={10}>
          {chartLabels.rightLabel}
        </Text>
      </Group>
    )}
  </div>
)
