import { config } from '@ospace/shared'
import { Amplify } from 'aws-amplify'

// setupAmplify is called in `useAuthCheck`. It can be useful to call this in tests.
export const setupAmplify = () => {
  const redirectSignIn = [`${window.location.origin}/auth/token`]
  const redirectSignOut = [`${window.location.origin}/logout`]

  Amplify.configure({
    Auth: {
      Cognito: {
        allowGuestAccess: false,
        userPoolId: config.cognito.USER_POOL_ID as string,
        identityPoolId: config.cognito.IDENTITY_POOL_ID as string,
        userPoolClientId: config.cognito.APP_CLIENT_ID as string,
        loginWith: {
          oauth: {
            domain: config.cognito.DOMAIN as string,
            redirectSignIn,
            redirectSignOut,
            responseType: 'code',
            scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            providers: [
              {
                custom: 'COGNITO',
              },
            ],
          },
        },
      },
    },
    API: {
      REST: {
        client: {
          endpoint: config.apiGateway.URL as any,
          region: 'ap-southeast-2' as any,
        },
        public: {
          endpoint: config.apiGateway.URL as any,
          region: 'ap-southeast-2' as any,
        },
      },
    },
  })
}
