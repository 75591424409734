import { DatesRangeValue } from '@mantine/dates'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(quarterOfYear)

type StaticDuration = 'Last week' | 'Month' | 'Quarter' | 'FY'
export const DEFAULT_FY_END_MONTH = '6'
export type DurationSelection = StaticDuration | DatesRangeValue
export const DEFAULT_DURATION = 'FY'
export interface Duration {
  selection: DurationSelection
  dates: {
    start: string
    end: string
  }
  segments: string[]
  segmentsDuration: {
    start: string
    end: string
  }
}

export const getQuarterOfYear = (endMonth: number) => {
  // Validate input
  if (endMonth < 1 || endMonth > 12) {
    throw new Error('Invalid month. Please provide a month between 1 and 12.')
  }

  // Adjust the end month to make it the end of the financial year
  endMonth = (endMonth + 9) % 12 || 12

  // Calculate the quarters
  const quarters = []
  for (let i = 0; i < 4; i++) {
    const startMonth = (endMonth - 2 + i * 3) % 12 || 12
    const quarter: number[] = [startMonth, startMonth + 1, startMonth + 2]
    quarters.push(quarter.map((month) => month % 12 || 12))
  }
  return quarters.reduce(
    (result, quarter) => {
      const range = {
        start: dayjs(new Date().setMonth(quarter[0] - 1))
          .startOf('month')
          .toDate()
          .toDateString(),
        end: dayjs(new Date().setMonth(quarter[2] - 1))
          .endOf('month')
          .toDate()
          .toDateString(),
      }
      return {
        ...result,
        [quarter[0]]: range,
        [quarter[1]]: range,
        [quarter[2]]: range,
      }
    },
    {} as {
      [key: number]: {
        start: string
        end: string
      }
    }
  )
}

// FYEndMonth uses a format where January is 1 and December is 12
// daysjs uses a format where January is 0 and December is 11
export function getFinancialYear(FYEndMonth = +DEFAULT_FY_END_MONTH) {
  const today = dayjs()
  const endMonth = FYEndMonth - 1

  const startMonth = endMonth === 11 ? 0 : endMonth + 1

  const startYear = endMonth === 11 || today.month() > endMonth ? today.year() : today.year() - 1
  const endYear = endMonth === 11 ? startYear : startYear + 1

  const startDay = 1
  const endDay = dayjs().year(endYear).month(endMonth).daysInMonth()

  return {
    start: dayjs().year(startYear).month(startMonth).date(startDay).toDate().toDateString(),
    end: dayjs().year(endYear).month(endMonth).date(endDay).toDate().toDateString(),
  }
}

export const calculateSegments = ({
  start,
  numberOfMonth,
}: {
  start: Date
  numberOfMonth: number
}) => {
  const segments = Array.from({ length: numberOfMonth }, (_, index) =>
    dayjs(start).add(index, 'month').toDate().toDateString()
  )
  const segmentsDuration = {
    start: dayjs(segments[0]).startOf('month').toDate().toDateString(),
    end: dayjs(segments[segments.length - 1])
      .endOf('month')
      .toDate()
      .toDateString(),
  }

  return {
    segments,
    segmentsDuration,
  }
}

export const calculateDuration = (
  selection: DurationSelection,
  FYEndMonth = +DEFAULT_FY_END_MONTH
) => {
  const today = dayjs(new Date())
  const quarters = getQuarterOfYear(FYEndMonth)

  switch (selection) {
    case 'Last week': {
      const start = today.subtract(7, 'day').toDate().toDateString()
      const end = today.toDate().toDateString()
      return {
        selection,
        dates: {
          start,
          end,
        },
        segments: [start],
        segmentsDuration: {
          start,
          end,
        },
      }
    }
    case 'Month': {
      return {
        selection,
        dates: {
          start: today.startOf('month').toDate().toDateString(),
          end: today.endOf('month').toDate().toDateString(),
        },
        ...calculateSegments({
          start: today.toDate(),
          numberOfMonth: 1,
        }),
      }
    }
    case 'Quarter':
      const quarter = quarters[today.month() + 1]
      return {
        selection,
        dates: quarter,
        ...calculateSegments({
          start: dayjs(quarter.start).toDate(),
          numberOfMonth: 3,
        }),
      }
    case 'FY':
      const dates = getFinancialYear(FYEndMonth)
      return {
        selection,
        dates,
        ...calculateSegments({
          start: new Date(dates.start),
          numberOfMonth: 12,
        }),
      }
    default:
      if (Array.isArray(selection)) {
        const dates = {
          start: dayjs(selection[0]).toDate().toDateString(),
          end: dayjs(selection[1]).toDate().toDateString(),
        }
        // Calculate the number of months between the start and end date
        const numberOfMonth =
          Math.abs(dayjs(selection[0]).month() - dayjs(selection[1]).month()) + 1
        const segments = Array.from({ length: numberOfMonth }, (_, index) =>
          dayjs(dates.start).add(index, 'month').toDate().toDateString()
        )
        return {
          selection,
          dates,
          segments,
          segmentsDuration: dates,
        }
      }

      return {
        selection,
        dates: {
          start: dayjs().startOf('month').toDate().toDateString(),
          end: dayjs().endOf('month').toDate().toDateString(),
        },
        segments: [dayjs().toDate().toDateString()],
        segmentsDuration: {
          start: dayjs().startOf('month').toDate().toDateString(),
          end: dayjs().endOf('month').toDate().toDateString(),
        },
      }
  }
}
