import { Duration } from '@ospace/common'
import { Lead } from '@ospace/schemas'

import { LineChart } from './LineChart'
import { plotGraph } from './plotGraph'

export const plotMeetingBooked = (leads: Lead[], leadsDealCount: number, duration: Duration) => {
  const { count, chartData } = plotGraph(leads, 'meetingBookedDate', duration)

  return {
    title: 'MEETINGS BOOKED',
    left: {
      label: 'Meetings',
      value: count,
    },
    right: {
      label: 'Ratio',
      value: `${leadsDealCount}:${count}`,
    },
    tooltip:
      'The chart displays leads converted to meetings during the selected time period. The ratio is relationship between leads created in the selected time period and meetings booked in the selected time period.',
    chart: LineChart,
    color: '#65C869',
    ...chartData,
  }
}
