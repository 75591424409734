export type InitialState = {
  session: object | undefined
  loading: boolean
  errorMessage: Error | ''
}

export const initialState: InitialState = {
  session: undefined,
  loading: true,
  errorMessage: '',
}

export enum AuthActions {
  FETCH_USER_DATA_INIT = 'FETCH_USER_DATA_INIT',
  FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE',
  RESET_USER_DATA = 'RESET_USER_DATA',
}

export interface IAuthAction {
  type: AuthActions
  payload?: any
  error?: any
}

export const AuthReducer = (initialState: InitialState, action: IAuthAction) => {
  switch (action.type) {
    case AuthActions.FETCH_USER_DATA_INIT:
      return {
        ...initialState,
        loading: true,
      }
    case AuthActions.FETCH_USER_DATA_SUCCESS:
      return {
        ...initialState,
        session: action.payload.session,
        loading: false,
      }
    case AuthActions.RESET_USER_DATA:
      return {
        ...initialState,
        session: undefined,
      }

    case AuthActions.FETCH_USER_DATA_FAILURE:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
