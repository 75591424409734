import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import { Contact } from '../schemas/Contact'

const DuplicatePopover = () => (
  <Popover>
    <Popover.Body>
      <p className='text-warning'>
        This contact already exists for this Campaign and will be skipped.
      </p>
    </Popover.Body>
  </Popover>
)

const FieldValidationPopover = (msg: any) => (
  <Popover>
    <Popover.Body>
      <p className='text-danger'>{msg}</p>
    </Popover.Body>
  </Popover>
)

const headerRow = [
  'Last Name',
  'Job Title',
  'Job Function',
  'Management Level',
  'Direct Phone Number',
  'Email Address',
  'Email Domain',
  'Mobile Phone',
  'City',
  'State',
  'Country',
  'Linkedin',
  'Company Name',
  'Website',
  'Revenue Range',
  'Employee Range',
  'Primary Industry',
  'Secondary Industry',
  'Parent Industry',
  'Company address',
  'Company address 2',
  'Company Phone',
  'Company City',
  'Company State',
  'Company Postcode',
  'Company Country',
  'Company Linkedin',
]

const ContactTable: React.FC<any> = (props: { contacts: Contact[]; title: string }) => {
  const { contacts, title } = props

  const hasContactError = (contact: any) => {
    let hasError = false
    if (contact.validationErrors.length > 0) {
      hasError = true
    }
    return hasError
  }

  const hasContactWarnings = (contact: any) => {
    let hasWarnings = false
    if (contact.warnings.length > 0) {
      hasWarnings = true
    }
    return hasWarnings
  }

  const hasContactDuplicates = (contact: any) => {
    if (contact.hasDuplicate > 0) {
      return true
    }
    return false
  }

  const renderPopover = (contact: any) => {
    if (contact.validationErrors.length > 0) {
      const validationMessages = contact.validationErrors.map((error: any) => {
        return error?.constraints
          ? `${Object.values(error?.constraints).toString()}\n`
          : `${error.errors.toString()}\n`
      })
      return FieldValidationPopover(validationMessages)
    } else if (contact.hasDuplicate) {
      return DuplicatePopover()
    } else if (contact.warnings.length > 0) {
      const mesesages = contact.warnings.map(
        (error: any) => `${Object.values(error?.constraints).toString()}\n`
      )
      return FieldValidationPopover(mesesages)
    } else {
      return <></>
    }
  }

  return (
    <>
      <div className='card-header pt-8'>
        <h2>{title}</h2>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
            style={{ color: '#eee' }}
          >
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-200px'>First Name</th>
                {headerRow.map((header) => (
                  <th key={header} className='min-w-200px'>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {contacts?.map((contact: any) => {
                const textColor =
                  hasContactError(contact) || hasContactWarnings(contact)
                    ? 'text-danger'
                    : hasContactDuplicates(contact)
                      ? 'text-warning'
                      : 'text-dark'

                return (
                  <OverlayTrigger
                    key={`${contact.emailAddress}-${contact.firstName}-${contact.lastName}-${contact.companyName}-${contact.linkedin}`}
                    placement='auto'
                    overlay={renderPopover(contact)}
                  >
                    <tr>
                      <td className='ps-4 min-w-200px'>
                        <span className={textColor}>{contact.firstName}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.lastName}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.jobTitle}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.jobFunction}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.managementLevel}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.phoneNumber}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.emailAddress}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.emailDomain}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.mobilePhone}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.city}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.state}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.country}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.linkedin}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyName}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.website}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.revenueRange}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.employeeRange}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.primaryIndustry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.secondaryIndustry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.parentIndustry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyStreet}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyStreetTwo}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.phone}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyCity}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyState}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.postcode}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyCountry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyLinkedin}</span>
                      </td>
                    </tr>
                  </OverlayTrigger>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export { ContactTable }
