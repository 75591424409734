import { Campaign, CampaignStatus } from '@ospace/schemas'
import dayjs from 'dayjs'

// formatDate formats a datestring in a standard human readable format for the
// campaign pages. It handles undefine and null datestrings by returning an
// empty string
export const formatDate = (date: string | undefined | null) =>
  date ? dayjs(date).format('DD MMM,  YYYY') : ''

type SortableCampaignPartial = Pick<Campaign, 'status' | 'startDate'>

// Sort function for Array.prototype.sort that takes 2 campaigns and sorts by status (ACTIVE, PAUSED, ENDED), then startDate descending.
export const defaultCampaignSort = (
  campaignA: SortableCampaignPartial,
  campaignB: SortableCampaignPartial
): number => {
  return `${calcStatusSortPriority(campaignA.status)}:${campaignA.startDate}` >
    `${calcStatusSortPriority(campaignB.status)}:${campaignB.startDate}`
    ? -1
    : 1
}

const calcStatusSortPriority = (status: CampaignStatus): number => {
  switch (status) {
    case CampaignStatus.IN_PROGRESS:
      return 3
    case CampaignStatus.PAUSED:
      return 2
    case CampaignStatus.ENDED:
      return 1
  }
}
