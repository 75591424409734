import { Duration, totalCreatedPipeline, totalRevenue } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)

export const pipelineChartPlotter = ({
  opportunities,
  duration,
}: {
  duration: Duration
  opportunities: Opportunity[]
}) => [
  {
    title: 'pipeline to close',
    infoText: 'This value is calculated by summing all deals closing in the selected period.',
    ...totalRevenue({ opportunities, duration }),
  },
  {
    title: 'created pipeline',
    infoText: 'This value is calculated to by summing all deals started in the selected period.',
    ...totalCreatedPipeline({ opportunities, duration }),
  },
]
