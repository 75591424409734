import * as Yup from 'yup'

export const snowyDateNamespacePropsSchema = Yup.object({
  year: Yup.string().required(),
  month: Yup.string().required(),
  day: Yup.string().required(),
})
export type SnowyDateNamespaceProps = Yup.InferType<typeof snowyDateNamespacePropsSchema>

export const snowyServiceMetadataSchema = snowyDateNamespacePropsSchema.concat(
  Yup.object({
    serviceName: Yup.string().required(),
    // timestamp when the dump began
    timestamp: Yup.number().required(),
    // the SnowyDateNamespace props for the date when the dump was last *succesfully* completed
    // note that there is a special `snowy.json` metadata file for when the whole dump was last completed (regardless of success)
  })
)
export type SnowyServiceMetadata = Yup.InferType<typeof snowyServiceMetadataSchema>

export const snowyObjectNamespacePropsSchema = snowyDateNamespacePropsSchema.concat(
  Yup.object({
    serviceName: Yup.string().required(),
    objectName: Yup.string().required(),
  })
)
export type SnowyObjectNamespaceProps = Yup.InferType<typeof snowyObjectNamespacePropsSchema>

// SnowyFileKeyProps are all the properties necessary to build a key in s3 for a raw dump file for snowy.
export const snowyFileKeyPropsSchema = snowyObjectNamespacePropsSchema.concat(
  Yup.object({
    fileName: Yup.string().required(),
  })
)
export type SnowyFileKeyProps = Yup.InferType<typeof snowyFileKeyPropsSchema>

// utility methods for building the products of the schemas above. These
// strings are namespaces in the raw snowy bucket.

export const buildSnowyDateNamespace = (opts: SnowyDateNamespaceProps) =>
  `${opts.year}/${opts.month}/${opts.day}/`

export const buildSnowyObjectNamespace = (opts: SnowyObjectNamespaceProps) =>
  `${opts.serviceName}/${opts.objectName}/${buildSnowyDateNamespace(opts)}`

export const buildSnowyFileKey = (opts: SnowyFileKeyProps) =>
  `${buildSnowyObjectNamespace(opts)}${opts.fileName}`
