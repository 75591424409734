import { Toast } from 'react-bootstrap'

import { useNotificationContext } from '../contexts/notification'

export const Notification = () => {
  const { notification, setNotification } = useNotificationContext()
  return (
    <div className='position-fixed top-0 end-0 p-3' style={{ zIndex: 100 }}>
      <Toast
        show={notification?.active || false}
        autohide={true}
        className='d-inline-block m-1'
        bg={notification?.type}
        onClose={() => setNotification(null)}
      >
        <Toast.Header closeButton>
          <strong className='me-auto'></strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{notification?.message}</Toast.Body>
      </Toast>
    </div>
  )
}
