import * as React from 'react'
import { Campaign } from '@ospace/schemas'

import { defaultCampaignSort } from '../utils'

import { CampaignCard } from './partials/CampaignCard'

export const Campaigns = ({ campaigns }: { campaigns: Campaign[] }) => {
  return (
    <>
      <div className='row g-6 g-xl-9'>
        {campaigns.sort(defaultCampaignSort).map((campaign: Campaign) => (
          <CampaignCard campaign={campaign} key={`campaign-${campaign.id}`} />
        ))}
        {!campaigns.length && <h3 className='text-center my-10'>No campaigns yet</h3>}
      </div>
    </>
  )
}
