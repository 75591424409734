import { useState } from 'react'
import { Paper } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { dateFilter, Duration, opportunityFilter } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import { ColDef, DashboardDetailedModal, NewTable } from '@ospace/shared'

import { NewTableHeader } from './NewTableHeader'
import { Notes } from './Notes'
import { transformModalData } from './transformModalData'

type OpportunitiesTableProps = {
  opportunities: Opportunity[]
  duration: Duration
  currency: string
  showNotes: boolean
}

export const OpportunitiesTable = ({
  opportunities,
  duration,
  currency,
  showNotes,
}: OpportunitiesTableProps) => {
  const filteredOpportunities = opportunities.filter(
    (opportunity) =>
      opportunityFilter(opportunity) &&
      dateFilter({ date: opportunity.closeDate || '', duration, format: 'DD-MM-YYYY' })
  )

  const colDef: ColDef[] = [
    {
      key: 'oppName',
      label: 'Opportunity Name',
    },
    {
      key: 'endUserName',
      label: 'End User',
    },
    {
      key: 'partnerName',
      label: 'Partner',
    },
    {
      key: 'calculatedBuyPrice',
      label: 'Value',
      dataType: 'currency',
      currency: currency,
    },
    {
      key: 'closeDate',
      label: 'Close Date',
      dataType: 'date',
      dateFormat: 'DD-MM-YYYY',
    },
    {
      key: 'programName',
      label: 'Program Name',
    },
  ]

  const [opened, { open, close }] = useDisclosure(false)
  const [modalData, setModalData] = useState<Opportunity>()

  return (
    <Paper withBorder p='xl' radius='md'>
      <NewTableHeader />
      <NewTable
        colDef={colDef}
        tableData={filteredOpportunities}
        tableProps={{
          itemsPerPage: 15,
          rowKey: 'oppNumber',
        }}
        modal={{
          component: (
            <>
              <DashboardDetailedModal
                opened={opened}
                onClose={close}
                centered
                data={transformModalData(modalData, currency)}
                colDef={colDef}
              >
                <Notes notes={modalData?.notes} showNotes={showNotes} />
              </DashboardDetailedModal>
            </>
          ),
          setModalData,
          open,
        }}
      />
    </Paper>
  )
}
