import './Toggle.css'

interface IProps {
  checked: boolean
  onChange: (checked: boolean) => void
  onClick: () => void
}

export const Toggle = ({ checked, onChange, onClick }: IProps) => {
  return (
    <>
      <div>
        <input
          className='toggle-check-input'
          type='checkbox'
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          onClick={onClick}
        />
      </div>
    </>
  )
}
