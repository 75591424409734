import { useEffect, useState } from 'react'
import { Button, Flex, rem } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { Duration, DurationSelection } from '@ospace/common'
import { DURATION_OPTIONS } from '@ospace/schemas'

interface Props {
  duration: Duration
  setDuration: (selection: DurationSelection) => void
}

export const DurationPicker = ({ duration, setDuration }: Props) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null])

  useEffect(() => {
    if (typeof duration.selection === 'string') {
      setValue([
        new Date(duration.dates.start).toString() === 'Invalid Date'
          ? null
          : new Date(duration.dates.start),
        new Date(duration.dates.end).toString() === 'Invalid Date'
          ? null
          : new Date(duration.dates.end),
      ])
    } else if (Array.isArray(duration?.selection)) {
      setValue(duration.selection)
    }
  }, [duration])

  return (
    <Flex mih={50} bg='white' gap='md' justify='flex-start' align='center' direction='row'>
      {DURATION_OPTIONS.map((item) => (
        <Button
          key={item}
          variant={item === duration?.selection ? 'light' : 'subtle'}
          styles={(theme) => ({
            root: {
              color: theme.colors.gray[7],
              lineHeight: 1,
              padding: `${rem(8)} ${rem(12)}`,
              borderRadius: theme.radius.sm,
              textDecoration: 'none',
              fontSize: theme.fontSizes.sm,
              fontWeight: item === duration?.selection ? 700 : 500,
              backgroundColor: item === duration?.selection ? theme.colors.gray[0] : 'white',
              '&:hover': {
                backgroundColor: theme.colors.gray[1],
              },
            },

            leftIcon: {
              marginRight: theme.spacing.md,
            },
          })}
          onClick={() => {
            setDuration(item)
          }}
        >
          {item}
        </Button>
      ))}
      <DatePickerInput
        valueFormat='DD MMM YYYY'
        type='range'
        aria-label='duration date range picker'
        placeholder='Select date range'
        value={value}
        onChange={(value) => {
          setValue(value)
          // commit the change only if both dates are selected
          if (Array.isArray(value) && value[0] && value[1]) {
            setDuration(value)
          }
        }}
        miw={200}
        numberOfColumns={2}
        styles={(theme) => ({
          input: {
            '&:hover': {
              backgroundColor: theme.colors.gray[1],
            },
            ...(Array.isArray(duration?.selection) && {
              color: theme.colors.gray[7],
              fontWeight: 600,
              border: 0,
              backgroundColor: theme.colors.gray[0],
            }),
          },
        })}
      />
    </Flex>
  )
}
