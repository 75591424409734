import { Duration, plotGraphData } from '@ospace/common'
import { Lead } from '@ospace/schemas'

import { filterWithRules } from './filterRule'

export const plotGraphFilter = (
  leads: Lead[],
  stage: 'discoveryDate' | 'leadDate' | 'meetingBookedDate' | 'opportunityDate',
  duration: Duration
) => {
  return leads
    .filter((lead) => lead[stage] && filterWithRules(lead, stage, duration))
    .sort((a, b) => {
      return new Date(a[stage] || '').getTime() - new Date(b[stage] || '').getTime()
    })
}
export const plotGraph = (
  leads: Lead[],
  stage: 'discoveryDate' | 'leadDate' | 'meetingBookedDate' | 'opportunityDate',
  duration: Duration
) => {
  const graphData = plotGraphFilter(leads, stage, duration).reduce(
    (result, lead) => {
      if (!lead[stage]) return result
      const date = lead[stage]?.split('T')[0]
      if (!date) return result
      return {
        ...result,
        [date]: result[date] ? result[date] + 1 : 1,
      }
    },
    {} as { [key: string]: number }
  )

  return plotGraphData(graphData, duration)
}
