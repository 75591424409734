import { Group, Paper, Text } from '@mantine/core'
import { ColDef, NewTable } from '@ospace/shared'
import { MoreInfo } from '@ospace/shared/components/MoreInfo'

type Props = {
  colDef: ColDef[]
  title: string
  tooltip: string
  data: any
}

export const TopTenTable = ({ colDef, title, tooltip, data }: Props) => (
  <Paper withBorder p='sm' radius='md'>
    <Group left={25} pos='relative'>
      <Text c='dimmed' tt='uppercase' fw={700} fz='md'>
        {title}
      </Text>
      <MoreInfo info={tooltip} />
    </Group>
    <NewTable
      colDef={colDef}
      tableData={data}
      tableProps={{
        itemsPerPage: 10,
        rowKey: 'oppNumber',
      }}
      hideSearch
      hidePagination
    />
  </Paper>
)
