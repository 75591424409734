import React from 'react'
import { Card } from '@mantine/core'

export const BaseCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <Card
      style={{
        flexGrow: 1,
      }}
      py='xl'
      withBorder
      shadow='sm'
      px='lg'
      radius='md'
    >
      {children}
    </Card>
  )
}
