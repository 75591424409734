import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  calculateDuration,
  DEFAULT_DURATION,
  DEFAULT_FY_END_MONTH,
  Duration,
  DurationSelection,
} from '@ospace/common'
import { DurationSelection as PersistedDurationSelection } from '@ospace/schemas'
import { API } from '@ospace/shared'

export const useDuration = (FYEndMonth: undefined | string = DEFAULT_FY_END_MONTH) => {
  const [duration, setDuration] = useState<Duration>(
    calculateDuration(DEFAULT_DURATION, +FYEndMonth)
  )

  useEffect(() => {
    setDuration(calculateDuration(duration.selection, +FYEndMonth))
  }, [FYEndMonth, duration.selection])

  return {
    duration,
    setDuration: (selection: DurationSelection) => {
      setDuration(calculateDuration(selection, +FYEndMonth))
    },
  }
}

export type UsePersistedDurationResp =
  | { status: 'loading' }
  | { status: 'ready'; duration: Duration; setDuration: (selection: DurationSelection) => void }
export const usePersistedDuration = (opts: {
  FYEndMonth: undefined | string
}): UsePersistedDurationResp => {
  const { duration, setDuration } = useDuration(opts.FYEndMonth || DEFAULT_FY_END_MONTH)
  const [status, setStatus] = useState('loading')
  const queryKey = ['user', 'duration']
  const queryClient = useQueryClient()
  const url = `/clients/duration-selection`

  const mutation = useMutation(async (selection: DurationSelection) => {
    const serializedSelection = serializeDurationSelection(selection)
    await API.put('client', url, {
      body: serializedSelection,
    })
    queryClient.setQueryData(queryKey, serializedSelection) // optimistic update
  })

  const resp = useQuery({
    queryKey,
    retry: false,
    queryFn: async () => {
      return await API.get('client', url, {})
    },
    onError: (err: any) => {
      console.error(err)
    },
  })

  useEffect(() => {
    if (status !== 'ready' && resp.status === 'success') {
      setDuration(parseDurationSelection(resp.data))
      setStatus('ready')
    }
    if (status !== 'ready' && resp.status === 'error') {
      // on error, we want to proceed with default settings
      setStatus('ready')
    }
    if (status !== 'loading' && (resp.status === 'loading' || resp.status === 'idle')) {
      // this will happen if you change clientId
      setStatus('loading')
    }
  }, [status, setDuration, resp.data, resp.status])

  if (status === 'loading') return { status }

  return {
    status: 'ready',
    duration: duration as Duration,
    setDuration: (selection: DurationSelection) => {
      setDuration(selection)
      mutation.mutate(selection)
    },
  }
}

const serializeDurationSelection = (selection: DurationSelection): PersistedDurationSelection => {
  if (typeof selection === 'string') {
    return {
      type: 'static',
      staticDuration: selection,
      dateRange: null,
    }
  }
  if (Array.isArray(selection) && selection[0] && selection[1]) {
    return {
      type: 'dateRange',
      staticDuration: null,
      dateRange: {
        start: selection[0].toISOString(),
        end: selection[1].toISOString(),
      },
    }
  }

  // default should not be reached, but return the default selection just in case
  return {
    type: 'static',
    staticDuration: DEFAULT_DURATION,
    dateRange: null,
  }
}

const parseDurationSelection = (
  persistedSelection: PersistedDurationSelection
): DurationSelection => {
  if (persistedSelection.type === 'static' && persistedSelection.staticDuration) {
    return persistedSelection.staticDuration as DurationSelection
  }
  if (persistedSelection.type === 'dateRange' && persistedSelection.dateRange) {
    return [
      new Date(persistedSelection.dateRange.start),
      new Date(persistedSelection.dateRange.end),
    ]
  }
  return DEFAULT_DURATION
}
