import { Button, Card, createStyles, Flex, Image, Text } from '@mantine/core'

type DeactivatedCardProps = {
  title: string
  description?: string
}

export const DeactivatedCard = ({ title, description }: DeactivatedCardProps) => {
  const useStyles = createStyles(() => ({
    card: {
      backgroundColor: '#DEE1E5',
      flex: 1,
      textAlign: 'center',
    },
    title: {
      fontSize: '28px',
      fontWeight: 700,
      color: '#464E5F',
      textAlign: 'center',
    },
    button: {
      backgroundColor: '#464E5F',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: '#464e5fb5',
      },
    },
  }))
  const { classes } = useStyles()

  return (
    <Card
      className={classes.card}
      p={{ base: 'xs', sm: 'md', lg: 'xl' }}
      radius='md'
      withBorder
      shadow='sm'
    >
      <Text className={classes.title}>{title}</Text>
      {description && (
        <Text mt='sm' size='md' mih='80px' px={'xl'}>
          {description}
        </Text>
      )}
      <Flex justify='center' mt={'md'}>
        <Button
          className={classes.button}
          radius='md'
          component='a'
          href='https://ospace.nextgen.group/strategy-meeting'
        >
          Talk to our specialists
        </Button>
      </Flex>
      <Flex
        justify='center'
        m={{ base: 'xs', sm: 'md', lg: 'md' }}
        p={{ base: 'xs', sm: 'md', lg: 'xl' }}
      >
        <Image
          src={'/media/svg/deactivated.svg'}
          m={{ base: 'xs', sm: 'md', lg: 'xl' }}
          p={{ base: 'xs', sm: 'md', lg: 'xl' }}
          miw={250}
        />
      </Flex>
    </Card>
  )
}

export const LeadsDeactivatedCard = () => {
  const title = `Unlock Growth: Let oSpace Platform Drive Your Lead Generation Success!`
  const description = `Dive into efficient leads generation with our Leads dashboard! Questions or assistance
  needed? Reach out—we're here for you!`
  return <DeactivatedCard title={title} description={description} />
}

export const SalesDeactivatedCard = () => {
  const title = `Contact Our Specialists 
  to Maximize oSpace's 
  Full Capacity!`
  return <DeactivatedCard title={title} />
}
