import { dateFilter, Duration } from '@ospace/common'
import { Lead } from '@ospace/schemas'

import { LineChart } from './LineChart'
import { plotGraph } from './plotGraph'

export const plotDiscovery = (leads: Lead[], duration: Duration) => {
  const discovery = leads.filter(
    (lead) =>
      lead.discoveryDate &&
      dateFilter({ date: lead.discoveryDate || '', duration, format: 'YYYY-MM-DDTHH:mm:ss' })
  )
  const contactsCount = discovery.reduce((acc, lead) => {
    if (!lead?.contactsCount || !lead.discoveryDate) return acc

    return acc + lead.contactsCount || 0
  }, 0)

  const { chartData } = plotGraph(leads, 'discoveryDate', duration)
  const companiesDiscovered = discovery.reduce((acc, lead) => {
    return {
      ...acc,
      [lead.companyName]: 1,
    }
  }, {})

  return {
    title: 'DISCOVERY',
    left: {
      label: 'Contacts',
      value: contactsCount,
    },
    right: {
      label: 'Companies discovered',
      value: Object.keys(companiesDiscovered).length,
    },
    tooltip: 'The chart displays companies discovered during the selected time period.',
    chart: LineChart,
    color: '#FFA53F',
    ...chartData,
  }
}
