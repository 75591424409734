import * as React from 'react'
import { KTSVG } from '@ospace/shared'
import { useNotificationContext } from '@ospace/shared'

export const CopyToClipboardBtn = (props: { text: string; message?: string }) => {
  const { setNotification } = useNotificationContext()
  return (
    <button
      className='btn btn-link btn-sm'
      onClick={() =>
        navigator.clipboard.writeText(props.text).then(() => {
          setNotification({
            active: true,
            type: 'success',
            message: props.message || 'Program ID copied to the clipboard.',
          })
        })
      }
    >
      <KTSVG
        style={{ position: 'relative', bottom: '0.125em' }}
        path='/media/icons/duotune/general/gen054.svg'
      />
    </button>
  )
}
