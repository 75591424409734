import { Container, Paper } from '@mantine/core'
import { ResponsiveBar } from '@nivo/bar'
import { Duration } from '@ospace/common'
import { abbreviateNumber } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import { GraphHeader } from '@ospace/shared/components/GraphHeader'

import { probabilityHelper, probabilityKeys } from './probabilityHelper'

interface Props {
  duration: Duration
  opportunities: Opportunity[]
  currency: string
}

export const ProbabilityChart = ({ duration, opportunities, currency }: Props) => {
  const data = probabilityHelper({
    segments: duration.segments,
    opportunities,
  })

  return (
    <Paper withBorder w='100%' h='500px' bg='white' maw='unset' c='dimmed' radius='md'>
      <GraphHeader
        title={`monthly pipeline by probability`}
        legend={'probability'}
        tooltip={"'Chart displays the projected pipeline revenue over time split by probaility.'"}
      />
      <ResponsiveBar
        data={data}
        indexBy='closeDateMonth'
        keys={probabilityKeys}
        margin={{ top: 60, right: 50, bottom: 80, left: 50 }} // this is the margin of the chart itself, not the container
        padding={0.7}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id }) => {
          switch (id) {
            case '20%':
              return '#FFA53F'
            case '40%':
              return '#C9D14D'
            case '60%':
              return '#65C869'
            case '80%':
              return '#39C275'
            case '100%':
              return '#2DCAB6'
            default:
              return '#ff0000'
          }
        }}
        tooltip={({ id, value, color }) => (
          <Container c={color} bg='#222222' p={12}>
            <strong>
              {id}: {abbreviateNumber(value)}
            </strong>
          </Container>
        )}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 15,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: (value) => abbreviateNumber(value, true),
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        enableLabel={false}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -50,
            itemsSpacing: 2,
            itemWidth: 50,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 15,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role='application'
        barAriaLabel={({ id }) => {
          return `${id} probability`
        }}
      />
    </Paper>
  )
}
