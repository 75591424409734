import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { fetchAuthSession } from 'aws-amplify/auth'

import config from '../../config'

export const Storage = () => {
  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fetchAuthSession().then((session) => session.credentials) as any,
  })

  const put = async (key: string, data: any, options?: any) => {
    const command = new PutObjectCommand({
      Bucket: options?.bucket || (config.s3.BUCKET as string),
      Key: key,
      Body: data,
    })

    try {
      return await client.send(command)
    } catch (err) {
      console.error('Detailed error: ', JSON.stringify(err, null, 2))
    }
  }

  return {
    put,
  }
}
