import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { useCampaign } from '@ospace/campaign'
import { useClient } from '@ospace/client'
import { userTypes } from '@ospace/core-auth'
import { ClientPageLayout, FullPageLayout, PageTitle } from '@ospace/layout'
import { useUser } from '@ospace/user'

// LegacyFullPageLayoutContainer is for wrapping legacy pages without any
// reference to a client or campaign. It is usually used for Admin pages, in
// which case a role of role={userTypes.Admin} should be passed.
// If `role` is blank, it is assumed to be `userTypes.ClientUser`, which will
// hide any admin links.
export const LegacyFullPageLayoutContainer = (props: {
  children: React.ReactElement
  role?: userTypes
}) => {
  const user = useUser()

  return user.status === 'success' ? (
    <FullPageLayout status='ready' user={user.data} role={props.role || userTypes.ClientUser}>
      {props.children}
    </FullPageLayout>
  ) : (
    <FullPageLayout status='loading' user={user.data} role={userTypes.Admin} />
  )
}

// LegacyCampaignLayoutWrapper is for wrapping legacy pages inside a
// campaign route. The route must have the campaign id in the url with
// parameter id. The wrapper ensures that the correct client is shown in the
// header for the specified campaign
export const LegacyCampaignLayoutContainer = (props: { children: React.ReactElement }) => {
  const params = useParams()
  const campaignId = Number(params.id)
  const user = useUser()
  const role = useUserRole()
  const campaignResp = useCampaign(campaignId)
  if (campaignResp.status === 'success' && user.status === 'success') {
    return (
      <ClientPageLayout
        status='ready'
        user={user.data}
        role={role}
        client={campaignResp.data.client}
        pageId='programs'
      >
        {props.children}
      </ClientPageLayout>
    )
  }
  return <ClientPageLayout status='loading' user={user.data} role={role} pageId='programs' />
}

// LegacyClientLayoutWrapper is for wrapping legacy pages inside a
// client route. The route must have the client id in the url with
// parameter id. The wrapper ensures that the correct client is shown in the
// header for the specified campaign
export const LegacyClientLayoutContainer = (props: { children: React.ReactElement }) => {
  const params = useParams()
  const clientId = Number(params.id)
  const user = useUser()
  const role = useUserRole()
  const clientResp = useClient(clientId)

  if (clientResp.status === 'success' && user.status === 'success') {
    return (
      <ClientPageLayout
        status='ready'
        user={user.data}
        role={role}
        client={clientResp.data}
        subHeader={<PageTitle />}
        pageId='programs'
      >
        {props.children}
      </ClientPageLayout>
    )
  }

  return <ClientPageLayout status='loading' user={user.data} role={role} pageId='programs' />
}
