import { currencyFormatter } from '@ospace/common'
import { SalesInvoice } from '@ospace/schemas'
import {
  DashboardDetailedModalData,
  DEFAULT_MODAL_STEPS,
} from '@ospace/shared/components/DashboardDetailedModal'

const calculateActiveStep = (data: (SalesInvoice & { oppName: string }) | undefined) => {
  // originated from an oSpace Program
  if (data?.oppNumber) {
    return {
      activeStep: 3,
      steps: DEFAULT_MODAL_STEPS,
    } // 3 is the opportunity step
  }

  return {
    activeStep: 1,
    steps: ['Opportunity', 'Sale'],
  }
}

export const transformModalData = (
  data: (SalesInvoice & { oppName: string }) | undefined,
  currency: string
) => {
  const revenue = data?.buyPriceFx || 0

  return {
    steps: calculateActiveStep(data),
    title: data?.vendorName || '',
    revenue,
    leftColumn: [
      {
        label: 'Sales Invoice Name',
        value: data?.invoiceNumber,
      },
      {
        label: 'End User',
        value: data?.endUserName,
      },
      {
        label: 'Partner',
        value: data?.partnerName,
      },
      {
        label: 'Opportunity Number',
        value: data?.oppNumber,
      },
    ],
    rightColumn: [
      {
        label: 'Value',
        value: `${currency} ${currencyFormatter().format(+(data?.buyPriceFx || 0))}`,
      },
      {
        label: 'Invoice Date',
        value: data?.invoiceDate,
      },
      {
        label: 'Program Name',
        value: data?.vendorName,
      },
      {
        label: 'Vendor Contact',
        value: data?.vendorName,
      },
    ],
  } as DashboardDetailedModalData
}
