import { Box } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { useStats } from '@ospace/home/hooks'
import { FullPageLayout } from '@ospace/layout'
import { useUser } from '@ospace/user'

import { ClientsList } from '../components/ClientsList'
import { StatsList } from '../components/StatList'

export const ClientsPage = () => {
  // TODO (ddc) pull out to controller

  const user = useUser()
  const role = useUserRole()

  const isDashboardUser =
    role === userTypes.Admin ||
    role === userTypes.ClientManager ||
    role === userTypes.InternalViewer
  const stats = useStats(isDashboardUser)

  if (user.status === 'success' && (!isDashboardUser || stats.status === 'success')) {
    return (
      <FullPageLayout status='ready' user={user.data} role={role} title='All Clients'>
        <Box>
          {stats.data && <StatsList stats={stats.data} />}
          <ClientsList role={role} title='All Clients' />
        </Box>
      </FullPageLayout>
    )
  }
  if (user.status === 'error' || stats.status === 'error') {
    return (
      <FullPageLayout
        status='error'
        user={user.data}
        role={role}
        title='All Clients'
        message='Error'
      />
    )
  }
  return <FullPageLayout status='loading' user={user.data} role={role} title='All Clients' />
}
