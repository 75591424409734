import { Group, Text } from '@mantine/core'
import { MoreInfo } from '@ospace/shared/components/MoreInfo'

export const NewTableHeader = () => {
  return (
    <Group position='left'>
      <Text c='dimmed' tt='uppercase' fw={700} fz='md'>
        All Pipeline Deals
      </Text>
      <MoreInfo info='This table is a detailed breakdown of all deals.' />
    </Group>
  )
}
