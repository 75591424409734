import { createRoot } from 'react-dom/client'
import { App } from '@ospace/entry'
import { initDataDog } from '@ospace/shared/services/datadog'
import { Chart, registerables } from 'chart.js'

import '@ospace/metronic/assets/sass/style.scss'
import '@ospace/metronic/assets/sass/style.react.scss'

initDataDog()

const container = document.getElementById('root')

Chart.register(...registerables)

const root = createRoot(container!)
root.render(<App />)
