import { opportunityFilter } from '@ospace/common'
import { Opportunity } from '@ospace/schemas'
import dayjs from 'dayjs'

export const probabilityKeys = ['20%', '40%', '60%', '80%', '100%']
export const probabilityHelper = ({
  segments,
  opportunities,
}: {
  segments: string[]
  opportunities: Opportunity[]
}) => {
  const data = segments.map((segment) => {
    // convert numeric month to string
    const monthString = new Date(segment).toLocaleString('default', {
      month: 'short',
      ...(segments.length > 4 && { year: '2-digit' }),
    })

    return {
      closeDateMonth: monthString,
      ...probabilityKeys.reduce((summary, key) => {
        return {
          ...summary,
          [key]: opportunities
            .filter(
              (opportunity) =>
                opportunityFilter(opportunity) &&
                dayjs(opportunity.closeDate, 'DD-MM-YYYY').month() ===
                  new Date(segment).getMonth() &&
                dayjs(opportunity.closeDate, 'DD-MM-YYYY').year() ===
                  new Date(segment).getFullYear()
            )
            .filter(({ probability }) => probability.includes(key))
            .reduce(
              (sum, opportunity) => sum + Math.round(+(opportunity?.calculatedBuyPrice || 0)),
              0
            ),
        }
      }, {}),
    }
  })
  const MAX_SEGMENTS = 12
  if (data.length <= MAX_SEGMENTS) {
    return data
  }

  // filter out non-zero segments
  const firstNonZeroSegment = data.findIndex((segment) =>
    Object.values(segment).some((value) => Number.isInteger(value) && +value > 0)
  )

  const lastNonZeroSegment =
    data.length -
    data
      .slice(firstNonZeroSegment, data.length)
      .reverse()
      .findIndex((segment) =>
        Object.values(segment).some((value) => Number.isInteger(value) && +value > 0)
      ) -
    1
  const segmentLength = lastNonZeroSegment - firstNonZeroSegment + 1

  if (segmentLength === MAX_SEGMENTS) {
    return data.slice(firstNonZeroSegment, lastNonZeroSegment + 1)
  }
  const leadingSegments = Math.floor((MAX_SEGMENTS - segmentLength) / 2)
  const endingSegments = Math.ceil((MAX_SEGMENTS - segmentLength) / 2)

  return data.slice(firstNonZeroSegment - leadingSegments, lastNonZeroSegment + endingSegments + 1)
}
