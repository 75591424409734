import { Lead } from '@ospace/schemas'
import {
  DashboardDetailedModalData,
  DEFAULT_MODAL_STEPS,
} from '@ospace/shared/components/DashboardDetailedModal'

const calculateActiveStep = (lead: Lead | undefined) => {
  if (!lead) return 1
  let activeStep = 1
  if (lead.discoveryDate) {
    activeStep = 1
  }
  if (lead.leadDate) {
    activeStep = 2
  }
  if (lead.meetingBookedDate) {
    activeStep = 3
  }
  if (lead.opportunityDate) {
    activeStep = 4
  }
  return {
    activeStep,
    steps: DEFAULT_MODAL_STEPS,
  }
}

export const transformModalData = (lead: (Lead & { campaignName: string }) | undefined) => {
  return {
    steps: calculateActiveStep(lead),
    title: lead?.companyName || '',
    revenue: lead?.companyAnnualRevenue || 0,
    leftColumn: [
      {
        label: 'Website',
        value: lead?.companyDomain || '',
      },
      {
        label: 'Contact',
        value: lead?.companyDomain || '',
      },
      {
        label: 'Industry',
        value: lead?.companyIndustry || '',
      },
      {
        label: 'Program Name',
        value: lead?.campaignName || '',
      },
    ],
    rightColumn: [
      {
        label: 'Status',
        value: lead?.status || '',
      },
      {
        label: 'Employees',
        value: lead?.companyNumberofEmployees || '',
      },
      {
        label: 'City',
        value: lead?.companyCity || '',
      },
    ],
  } as DashboardDetailedModalData
}
