import * as Yup from 'yup'

export const locationSchema = Yup.object({
  id: Yup.number().positive().required(),
  countryCode: Yup.string().required(),
  stateCode: Yup.string().nullable(),
  country: Yup.object({
    code: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  state: Yup.object({
    code: Yup.string().required(),
    countryCode: Yup.string().required(),
    name: Yup.string().required(),
  }).nullable(),
})
export type Location = Yup.InferType<typeof locationSchema>

export const upsertLocationRequestSchema = locationSchema.pick(['countryCode', 'stateCode']).concat(
  Yup.object({
    id: Yup.number().nullable(), // include ID for update
  })
)
export type UpsertLocationRequest = Yup.InferType<typeof upsertLocationRequestSchema>

export const icpSchema = Yup.object({
  id: Yup.number().positive(),
  name: Yup.string().required(),
  industryIds: Yup.array().of(Yup.number().positive()).required(),
  revenueRangeIds: Yup.array().of(Yup.number().positive()).required(),
  employeeRangeIds: Yup.array().of(Yup.number().positive()).required(),
  locations: Yup.array().of(locationSchema).required(),
})
export type ICP = Yup.InferType<typeof icpSchema>

export const upsertICPRequestSchema = icpSchema
  .pick(['name', 'industryIds', 'revenueRangeIds', 'employeeRangeIds'])
  .concat(
    Yup.object({
      id: Yup.number().positive().nullable(), // include ID for update
      locations: Yup.array().of(upsertLocationRequestSchema).required(),
    })
  )
export type UpsertICPRequest = Yup.InferType<typeof upsertICPRequestSchema>
