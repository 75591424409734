import * as React from 'react'
import { Link } from 'react-router-dom'
import { isClientsDistributionPipelineAvailableToUserRole, userTypes } from '@ospace/core-auth'
import { Client } from '@ospace/schemas'
import clsx from 'clsx'

type ClientNavToolbarProps = {
  userRole: userTypes
  client: Client
  children?: React.ReactElement
}

type ClientNavProps = {
  userRole: userTypes
  client: Client
  selectedTab: 'campaigns' | 'distribution-pipeline'
  children?: React.ReactElement
}

const ClientNavToolbar = (props: ClientNavToolbarProps) => {
  return <div className='card-toolbar'>{props?.children}</div>
}

export const ClientNav = (props: ClientNavProps) => {
  return (
    <div className='card mt-2 mb-2'>
      <div className='card-header'>
        <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
          <li className='nav-item' key='campaigns-tab'>
            <Link
              className={clsx('nav-link', { active: props.selectedTab === 'campaigns' })}
              to={`/client/view/${props.client.id}`}
            >
              Campaigns
            </Link>
          </li>
          {isClientsDistributionPipelineAvailableToUserRole({
            client: props.client,
            userRole: props.userRole,
          }) ? (
            <li className='nav-item' key='distribution-pipeline-tab'>
              <Link
                to={`/client/${props.client.id}/distribution-pipeline`}
                className={clsx('nav-link', {
                  active: props.selectedTab === 'distribution-pipeline',
                })}
              >
                Sales Pipeline
              </Link>
            </li>
          ) : null}
        </ul>

        <ClientNavToolbar {...props} />
      </div>
    </div>
  )
}
