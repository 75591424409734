import { dateFilter, Duration } from '@ospace/common'
import { Lead } from '@ospace/schemas'
import dayjs from 'dayjs'

export const leaderFilterRule = (lead: Lead) => !!(lead.leadDate && !lead.meetingBookedDate)

export const meetingFilterRule = (lead: Lead) => !!lead.meetingBookedDate

export const companiesFilterRule = (lead: Lead) =>
  !!(lead.discoveryDate && !lead.leadDate && !lead.meetingBookedDate)

export const applyFilterRule = (
  stage: 'discoveryDate' | 'leadDate' | 'meetingBookedDate' | 'opportunityDate'
) => {
  switch (stage) {
    case 'discoveryDate':
      return companiesFilterRule
    case 'leadDate':
      return leaderFilterRule
    case 'meetingBookedDate':
      return meetingFilterRule
    default:
      return () => true
  }
}

export const filterWithRules = (
  lead: Lead,
  stage: 'discoveryDate' | 'leadDate' | 'meetingBookedDate' | 'opportunityDate',
  duration: Duration
) => {
  return (
    applyFilterRule(stage)(lead) &&
    dateFilter({ date: lead[stage] || '', duration, format: 'YYYY-MM-DDTHH:mm:ss' })
  )
}
export const applyPlottingRule =
  (leads: Lead[], segmentDate: Date, duration: Duration) =>
  (stage: 'discoveryDate' | 'leadDate' | 'meetingBookedDate') => {
    const start = dayjs(segmentDate).startOf('month')
    const end = dayjs(segmentDate).endOf('month')
    return leads.filter(
      (lead) =>
        filterWithRules(lead, stage, duration) &&
        dayjs(new Date(lead[stage] || '')).isBetween(start, end, 'date', '[]')
    )
  }
export const plotCompaniesBartChart = (leads: Lead[], segmentDate: Date, duration: Duration) =>
  applyPlottingRule(leads, segmentDate, duration)('discoveryDate').length

export const plotLeadsBartChart = (leads: Lead[], segmentDate: Date, duration: Duration) => {
  return applyPlottingRule(leads, segmentDate, duration)('leadDate').length
}
export const plotMeetingsBartChart = (leads: Lead[], segmentDate: Date, duration: Duration) =>
  applyPlottingRule(leads, segmentDate, duration)('meetingBookedDate').length
