import * as Yup from 'yup'

export const leadSchema = Yup.object({
  id: Yup.string().required(),
  campaignId: Yup.number().nullable(),
  status: Yup.string().required(),
  createdAt: Yup.string().required(),
  updatedAt: Yup.string().required(),
  companyAnnualRevenue: Yup.string(),
  companyCity: Yup.string(),
  companyCountry: Yup.string(),
  companyDomain: Yup.string(),
  companyIndustry: Yup.string().required(),
  companyName: Yup.string().required(),
  companyNumberofEmployees: Yup.string(),
  companyState: Yup.string(),
  companyZip: Yup.string(),
  discoveryDate: Yup.string().nullable(),
  leadDate: Yup.string().nullable(),
  meetingBookedDate: Yup.string().nullable(),
  opportunityDate: Yup.string().nullable(),
  contactsCount: Yup.number(),
})

export type Lead = Yup.InferType<typeof leadSchema>
