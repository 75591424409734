import { FC } from 'react'
import { CampaignStatus } from '@ospace/schemas'
import { Input, Select } from '@ospace/shared'

const Step1: FC<any> = ({ type, values }) => {
  return (
    <div className='w-100'>
      <div className='fv-row mb-10'>
        <Input
          label='Campaign Name'
          fieldName='name'
          required
          tooltip='Specify your unique campaign name'
          type='text'
        />
      </div>

      <div className='fv-row mb-10'>
        <Input
          label='Description'
          required
          tooltip='Specify your unique campaign description'
          fieldName='description'
          type='text'
        />
      </div>

      <div className='row mb-10'>
        <div className='col-md-6 fv-row'>
          <Input
            label='Start date'
            fieldName='startDate'
            tooltip='Specify start date of the campaign'
            required
            type='date'
          />
        </div>
        <div className='col-md-6 fv-row'>
          <Input
            label='End date'
            fieldName='endDate'
            tooltip='Specify end date of the campaign'
            type='date'
          />
        </div>
      </div>

      {type === 'edit' && (
        <div className='fv-row mb-10'>
          <Select label='Status' tooltip='Specify your campaign status' fieldName='status'>
            <option
              value={CampaignStatus.IN_PROGRESS}
              defaultChecked={values.status === CampaignStatus.IN_PROGRESS}
            >
              {CampaignStatus.IN_PROGRESS}
            </option>
            <option
              value={CampaignStatus.PAUSED}
              defaultChecked={values.status === CampaignStatus.PAUSED}
            >
              {CampaignStatus.PAUSED}
            </option>
            <option
              value={CampaignStatus.ENDED}
              defaultChecked={values.status === CampaignStatus.ENDED}
            >
              {CampaignStatus.ENDED}
            </option>
          </Select>
        </div>
      )}
    </div>
  )
}

export { Step1 }
