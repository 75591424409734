import * as React from 'react'
import { CreateCampaignRequest } from '@ospace/schemas'
import { CSelect as CustomSelect, useAllDomainValues } from '@ospace/shared'
import { Input } from '@ospace/shared'
import { ErrorMessage, Field, FieldArray } from 'formik'

type DomainValues = {
  id: number
  name: string
  countryCode: string
  code: string
}

type StepICPProps = {
  values: CreateCampaignRequest
}
export const StepICP = (props: StepICPProps) => {
  const domainValuesResp = useAllDomainValues()

  // TODO: handle this properly
  if (domainValuesResp.status === 'error') {
    return <div>ERROR {domainValuesResp.error}</div>
  }

  return (
    <div className='w-100'>
      <FieldArray name='icps'>
        {({ remove, push }) => (
          <>
            <div className='d-flex flex-wrap flex-stack mb-10'>
              <h3 className='mb-0'>ICP</h3>
              <button
                type='button'
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  push({
                    name: '',
                    industryIds: [],
                    revenueRangeIds: [],
                    employeeRangeIds: [],
                    locations: [],
                  })
                }}
              >
                Add
              </button>
            </div>
            {props.values.icps?.map((icp, index) => (
              <div key={index}>
                <hr />
                <div className='d-flex flex-wrap flex-stack'>
                  <div>
                    <Input
                      label='Name'
                      fieldName={`icps.${index}.name`}
                      tooltip='Specify your ICP name'
                      required
                    />
                  </div>
                  <button
                    className='btn btn-sm btn-secondary align-self-start'
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    Remove
                  </button>
                </div>

                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span>Industry</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify the Industry'
                  ></i>
                </label>
                {domainValuesResp.status === 'loading' || domainValuesResp.status === 'idle' ? (
                  <Field
                    name={`icps.${index}.industryIds`}
                    component={CustomSelect}
                    isMulti={true}
                    options={[]}
                    disabled
                  />
                ) : (
                  <Field
                    name={`icps.${index}.industryIds`}
                    component={CustomSelect}
                    isMulti={true}
                    isClearable
                    options={domainValuesResp.data.industries.map((d: DomainValues) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`icps.${index}.industryIds`} />
                </div>

                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span>Revenue Range</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify the Revenue Range'
                  ></i>
                </label>
                {domainValuesResp.status === 'loading' || domainValuesResp.status === 'idle' ? (
                  <Field
                    name={`icps.${index}.revenueRangeIds`}
                    component={CustomSelect}
                    isMulti={true}
                    options={[]}
                    disabled
                  />
                ) : (
                  <Field
                    name={`icps.${index}.revenueRangeIds`}
                    component={CustomSelect}
                    isMulti={true}
                    isClearable
                    options={domainValuesResp.data.revenueRanges.map((d: DomainValues) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`icps.${index}.reveueRangeId`} />
                </div>

                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span>Employee Range</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify the EmployeeRange'
                  ></i>
                </label>
                {domainValuesResp.status === 'loading' || domainValuesResp.status === 'idle' ? (
                  <Field
                    name={`icps.${index}.employeeRangeIds`}
                    component={CustomSelect}
                    isMulti={true}
                    options={[]}
                    disabled
                  />
                ) : (
                  <Field
                    name={`icps.${index}.employeeRangeIds`}
                    component={CustomSelect}
                    isMulti={true}
                    isClearable
                    options={domainValuesResp.data.employeeRanges.map((d: DomainValues) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                )}
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`icps.${index}.employeeRangeId`} />
                </div>

                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span>Locations</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify the Locations'
                  ></i>
                </label>
                <FieldArray name={`icps.${index}.locations`}>
                  {(locationArray) => (
                    <div>
                      {icp.locations.map((location, locationIndex) => (
                        <div
                          key={`${locationIndex}-${location.countryCode || ''}-${
                            location.stateCode || ''
                          }`}
                          className='border-bottom pb-2 mb-2'
                        >
                          <Field
                            name={`icps.${index}.locations.${locationIndex}.countryCode`}
                            component={CustomSelect}
                            isMulti={false}
                            isDisabled={!domainValuesResp.isSuccess || !!location.stateCode}
                            isClearable
                            options={
                              domainValuesResp.isSuccess
                                ? domainValuesResp.data.countries.map((d: DomainValues) => ({
                                    label: d.name,
                                    value: d.code,
                                  }))
                                : []
                            }
                          />
                          <Field
                            name={`icps.${index}.locations.${locationIndex}.stateCode`}
                            component={CustomSelect}
                            isMulti={false}
                            isClearable
                            isDisabled={!domainValuesResp.isSuccess || !location.countryCode}
                            options={
                              location.countryCode && domainValuesResp.isSuccess
                                ? domainValuesResp.data.states
                                    .filter(
                                      (d: DomainValues) => d.countryCode === location.countryCode
                                    )
                                    .map((d: DomainValues) => ({
                                      label: d.name,
                                      value: d.code,
                                    }))
                                : []
                            }
                          />
                          <button
                            className='btn btn-sm btn-secondary align-self-start'
                            type='button'
                            onClick={() => {
                              locationArray.remove(locationIndex)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        className='btn btn-sm btn-secondary'
                        type='button'
                        onClick={() => {
                          locationArray.push({ countryCode: null, stateCode: null })
                        }}
                      >
                        Add Location
                      </button>
                    </div>
                  )}
                </FieldArray>
              </div>
            ))}
          </>
        )}
      </FieldArray>
    </div>
  )
}
