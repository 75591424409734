import { API } from '@ospace/shared'

type DomainValue = { id: number; name: string }
type Country = { code: string; name: string }
type State = { code: string; countryCode: string; name: string }

export type AllDomainValues = {
  industries: DomainValue[]
  employeeRanges: DomainValue[]
  revenueRanges: DomainValue[]
  countries: Country[]
  states: State[]
}

const listAllDomainValues = async () => {
  return API.get('client', '/domain-values', {})
}

export const DomainValuesService = {
  listAllDomainValues,
}
